import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class StockClass extends React.Component {
    // render() {
    //     return <CommonTable title="Stock Class" url="/api/StockClass" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Stock Class"
                    apiURL="/api/StockClass"
                    listingURL="/api/StockClass/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingStockClassListing"}
                    className={"maintenance-listing-datagrid"}
                />
            </div>
        </Container>
    }
}

export default StockClass;