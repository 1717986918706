import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { loading, closeLoading } from "../../utils/common";
import TermsOfUse from '../popup-form/TermsOfUse';
import PrivacyPolicy from '../popup-form/PrivacyPolicy';

const UpcomingReviewPaymentForm = ({ visible, onClose, activeTab, onUpdate }) => {

    const [popupWidth, setPopupWidth] = useState("65%");
    const [paymentDetails, setPaymentDetails] = useState(null); // State for API data
    const isUpgrade = activeTab === 'upgrade';
    const [termsDialogVisible, setTermsVisible] = useState(false);
    const [privacyDialogVisible, setPrivacyVisible] = useState(false);

    const handleTermsClose = () => {
        setTermsVisible(false);
    };

    const handlePrivacyClose = () => {
        setPrivacyVisible(false);
    };

    const handlePrivacyClick = () => {
        setPrivacyVisible(true);
    };

    const handleTermsClick = () => {
        setTermsVisible(true);
    };

    useEffect(() => {
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else {
                setPopupWidth("65%");
            }
        };

        updatePopupWidth();
        window.addEventListener('resize', updatePopupWidth);

        return () => {
            window.removeEventListener('resize', updatePopupWidth);
        };
    }, []);

    useEffect(() => {
        if (visible) {
            loading("Loading Subscription Plan...");
            const fetchPaymentDetails = async () => {
                try {
                    const endpoint = '/api/Payment/GetUpcomingPaymentDetail';

                    baseapi.httpget(endpoint)
                        .then(response => {
                            if (response.data.status) {
                                // Update the state with the response data
                                setPaymentDetails(response.data.model);
                            } else {
                                notify(response.data.message, 'error', 2000);
                            }
                        })
                        .catch(error => {
                            console.error('Error processing payment details:', error.message);
                        })
                        .finally(() => closeLoading());
                } catch (error) {
                    console.error('Error fetching payment details:', error.message);
                    closeLoading();
                }
            };

            fetchPaymentDetails();
        }
    }, [visible, isUpgrade]);

    const handleSubmit = () => {
        const paymentEndpoint = isUpgrade 
            ? '/api/Payment/HandleUpgradePayment' 
            : '/api/Payment/HandleUpcomingPayment';
    
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to proceed with the payment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, proceed!',
            cancelButtonText: 'No, cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                loading("Processing Payment...");
                baseapi.httppost(paymentEndpoint)
                    .then(response => {
                        if (response.data.status) {
                            Swal.fire('Success!', response.data.message, 'success');
                            onClose(); // Close the popup on success
                            if (onUpdate) {
                                onUpdate(); // Update any parent components if needed
                            }
                        } else {
                            notify(response.data.message, 'error', 3000);
                        }
                    })
                    .catch(error => {
                        console.error('Payment submission error:', error.message);
                        Swal.fire('Error!', 'There was an error processing the payment. Please try again later.', 'error');
                    })
                    .finally(() => closeLoading());
            }
        });
    };
    const handleOnClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                width={popupWidth}
                height={"auto"}
                title='Review Payment Details'
            >
                {paymentDetails ? (
                    <div className="review-payment-container">
                        <h3 className="reviewpayment-title">Please review details below before proceeding to payment.</h3>

                        <div className="reviewpayment-companyname">
                            <span>Company Name</span>
                            <strong>{paymentDetails.CompanyName}</strong>
                        </div>

                        <div className="reviewpayment-section">
                            <div className="reviewpayment-details">
                                <div className="reviewpayment-plan-box">
                                    <h4>{paymentDetails.PlanName}</h4>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Duration</div>
                                        <div className="summary-value">{paymentDetails.Duration}</div>
                                    </div>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Subscription</div>
                                        <div className="summary-value">
                                            {new Date(paymentDetails.SubscriptionStartDate).toLocaleDateString('en-GB')} - {new Date(paymentDetails.SubscriptionEndDate).toLocaleDateString('en-GB')}
                                        </div>
                                    </div>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Included User(s)</div>
                                        <div className="summary-value">{paymentDetails.IncludedLicensedUser}</div>
                                    </div>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Fee:</div>
                                        <div className="summary-value">
                                            RM {utils.numberToCurrency(paymentDetails.PlanPriceTotal)}<br />
                                            <span className="reviewpayment-additionaltext">RM {utils.numberToCurrency(paymentDetails.PlanAmount)} x {paymentDetails.Duration} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="reviewpayment-summary">
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Additional User(s)</div>
                                    <div className="summary-value">{paymentDetails.AddOnUser}</div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Additional Charge for User(s)</div>
                                    <div className="summary-value">
                                        RM {utils.numberToCurrency(paymentDetails.LicensedPriceTotal)}<br />
                                        <span className="reviewpayment-additionaltext">{paymentDetails.AddOnUser} seat x {paymentDetails.Duration} x RM {utils.numberToCurrency(paymentDetails.LicensedUserPrice)}</span>
                                    </div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Sub Total</div>
                                    <div className="summary-value">
                                        RM {utils.numberToCurrency(paymentDetails.Subtotal)}
                                    </div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">SST (8%)</div>
                                    <div className="summary-value">
                                        RM {utils.numberToCurrency(paymentDetails.SSTPrice)}
                                    </div>
                                </div>
                               
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Total incl. SST</div>
                                    <div className="summary-value reviewpayment-total">
                                        <strong>RM {utils.numberToCurrency(paymentDetails.TotalPrice)}</strong>
                                        {!isUpgrade && paymentDetails.DiscountPrice > 0 && (
                                            <>
                                                <br />
                                                <span className="reviewpayment-additionaltext">
                                                    Save RM {utils.numberToCurrency(paymentDetails.DiscountPrice)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="reviewpayment-terms">
                            *By signing up, you agree to our <a href="#" onClick={handleTermsClick}>Terms of Use</a> and acknowledge you've read our <a href="#" onClick={handlePrivacyClick}>Privacy Policy</a>.
                        </p>

                        <div className="reviewpayment-buttons">
                            <button className="reviewpayment-cancel"  onClick={handleOnClose}>Cancel</button>
                            <button className="reviewpayment-submit" onClick={handleSubmit}>Make Payment</button>
                        </div>
                    </div>
                ) : (
                    <p>Loading payment details...</p>
                )}
            </Popup>

            <TermsOfUse
                visible={termsDialogVisible}
                onClose={handleTermsClose}
                popupWidth={popupWidth.current}
            />
            <PrivacyPolicy
                visible={privacyDialogVisible}
                onClose={handlePrivacyClose}
                popupWidth={popupWidth.current}
            />
        </>
    );
};

export default UpcomingReviewPaymentForm;
