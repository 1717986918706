import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class Areas extends React.Component {
    // render() {
    //     return <CommonTable title="Area" url="/api/Areas" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Area"
                    apiURL="/api/Areas"
                    listingURL="/api/Areas/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingAreaListing"}
                    className={"maintenance-listing-datagrid"}
                />
            </div>
        </Container>
    }
}

export default Areas;