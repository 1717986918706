import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextBox } from "devextreme-react/text-box";
import Validator, { RequiredRule, EmailRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import './ResetPasswordForm.scss';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import ResetPasswordOTP from './ResetPasswordOTP';
import ResetPasswordSetNew from './ResetPasswordSetNew';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { useResponsivePopupWidth } from '../../utils/windowUtils';

const ResetPasswordForm = ({ visible, onClose }) => {
  const emailValidatorRef = useRef(null);
  const [email, setEmail] = useState('');
  const [tacCode, setTacCode] = useState('');
  const [showResetPasswordSend, setShowResetPasswordSend] = useState(false);
  const [showResetPasswordOTP, setShowResetPasswordOTP] = useState(false);
  const [showResetPasswordSetNew, setShowResetPasswordSetNew] = useState(false);
  const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(false);
  const [countdown, setCountdown] = useState(0); // Countdown state
  const [loading, setLoading] = useState(false);
  const popupWidth = useResponsivePopupWidth();

  const handleSubmit = useCallback(async (event) => {
    if (event) {
      event.preventDefault(); // Prevent default form submission
    }


    const emailValidation = emailValidatorRef.current.instance.validate();
    if (!emailValidation.isValid) {
      return;
    }

    try {
      setLoading(true);

      const response = await baseapi.httppost('/oauth/ForgotPassword', { email });
      setLoading(false);
      console.log(response, "response")
      if (response.data.status) {

        setShowResetPasswordOTP(true);
      } else {
        notify(response.data.message, 'error', 5000);
      }
    } catch (error) {
      notify('Email is not registered.', 'error', 2000);
    }
  }, [email]);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.value);
  }, []);


  const handleClose = useCallback(() => {
    setShowResetPasswordSend(false);
    setShowResetPasswordOTP(false);
    setShowResetPasswordSetNew(false);
    setShowResetPasswordSuccess(false);
    onClose();
  }, [onClose]);
  
  const handleOtpSubmit = useCallback((tacCode) => {
    setTacCode(tacCode); // Update tacCode state
    setShowResetPasswordSetNew(true);
  }, []);
  return (
    <>
      <Popup
        width={popupWidth.current}
        height={'auto'}
        visible={visible}
        onHiding={handleClose}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={false}
      >
        <form onSubmit={handleSubmit}> {/* Form element with onSubmit handler */}
          <div className="auth-popup-content">
            <p className="auth-popup-title">Forgot Password</p>
            <p className="auth-popup-subtitle">Enter the email address associated with your account and we’ll send you a link to reset your password.</p>
            <TextBox
              value={email}
              onValueChanged={handleEmailChange}
              className="auth-pop-up-input"
              stylingMode="outlined"
              placeholder="Email"
              labelMode="outside"
            >
              <Validator ref={emailValidatorRef}>
                <RequiredRule message="Email is required" />
                <EmailRule message="Email is invalid" />
              </Validator>
            </TextBox>
            <div className="auth-buttons">
              {!loading && countdown === 0 && <button type="submit" className="auth-button">Continue</button>}
              {!loading && countdown > 0 && <p className="auth-popup-subtitle"  style={{ marginTop: '19px' }}>You can resend the OTP after <strong style={{ color: '#6F6F6F', fontSize: '1em' }}>{countdown}</strong> second(s)</p>}
              {loading && (
                <div className="loading-indicator-container">
                  <div className="loader"></div>
                  <br />
                </div>
              )}
              <a href="#" onClick={handleClose} className="auth-link">&lt;&lt; Back to Login</a>
            </div>
          </div>
        </form>
      </Popup>


      <ResetPasswordOTP
        visible={showResetPasswordOTP}
        onClose={handleClose}
        onSendAgain={handleSubmit}
        email={email}
        onOtpSubmit={handleOtpSubmit}
        loading={loading}
        setCountdown={setCountdown}
      />
      <ResetPasswordSetNew
        visible={showResetPasswordSetNew}
        onClose={handleClose}
        email={email}
        onSuccess={() => setShowResetPasswordSuccess(true)}
        tacCode={tacCode}

      />
      <ResetPasswordSuccess
        visible={showResetPasswordSuccess}
        onClose={handleClose}
      />
    </>
  );
};

export default ResetPasswordForm;
