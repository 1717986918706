import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import PurchaseReceivedForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import Transfer from '../transfer/Transfer';
import DropDownButton from 'devextreme-react/drop-down-button';

export default function PurchaseReceivedListing() {

    const apiURL = `/api/PurchaseReceived`;
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const [currentEditID, setCurrentEditID] = useState(null);
    const [transformTitle, setTransformTitle] = useState(null);
    const [transferData, setTransferData] = useState(null);
    const transformRef = useRef(null);

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

    const onItemClick = (e) => {
        const { action } = e.itemData;
        setTransformTitle(action);
        utils.transformOpen(transformRef);
    };
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="purchase-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/PurchaseReceived/list"
            sortColumn={"DocumentDate"}
            sortOrder={"desc"}
            listingTitle={"Purchase Received"}
            storageName={"PurchaseReceivedListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
            customizedAddButton={
                <DropDownButton
                    className="customized-listing-add-btn purchase-listing-btn"
                    splitButton={true}
                    useSelectMode={false}
                    icon='add'
                    text="Add"
                    items={[
                        { id: 1, action: 'Transfer from Request' },
                        { id: 2, action: 'Transfer from Quotation' },
                        { id: 3, action: 'Transfer from Order' }
                    ]}
                    displayExpr="action"
                    keyExpr="id"
                    width={"100px"}
                    dropDownOptions={
                        {width: "180px"}
                    }
                    onItemClick={onItemClick}
                    onButtonClick={() => {
                        setCurrentEditID("new");
                    }}
              />
            }
        >
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="DocumentNo" />
            <Column dataField="SupplierCode" />
            <Column dataField="SupplierName" />
            <Column dataField="ForexID" caption={"Forex"} >
                <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

        <PurchaseReceivedForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            transferData={transferData}
            clearTransfer={setTransferData}
            title={"Purchase Received"}
            apiURL={apiURL}
        />
        
        <Transfer 
            ref={transformRef}
            title={transformTitle}
            apiPath={{
                "Transfer from Request": {
                    FullDocumentListing: "api/PurchaseTransfer/RequestFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/RequestPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/RequestFullTransfer",
                    PartialDocumentTransfer:  "api/PurchaseTransfer/RequestPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/RequestFilter"
                },
                "Transfer from Quotation": {
                    FullDocumentListing: "api/PurchaseTransfer/QuotationFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/QuotationPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/QuotationFullTransfer",
                    PartialDocumentTransfer:  "api/PurchaseTransfer/QuotationPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/QuotationFilter"
                },
                "Transfer from Order": {
                    FullDocumentListing: "api/PurchaseTransfer/OrderFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/OrderPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/OrderFullTransfer",
                    PartialDocumentTransfer:  "api/PurchaseTransfer/OrderPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/OrderFilter"
                }
            }}
            transferData={transferFormData}
        />
    </Container>;
}