import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import PurchaseReturnForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import Transfer from '../transfer/Transfer';
import DropDownButton from "devextreme-react/drop-down-button";

export default function PurchaseReturnListing() {

    const apiURL = `/api/PurchaseReturn`;
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const [currentEditID, setCurrentEditID] = useState(null);
    const [transformTitle, setTransformTitle] = useState(null);
	const [transferData, setTransferData] = useState(null);
	const transformRef = useRef(null);

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

    const onItemClick = (e) => {
		const { action } = e.itemData;
		setTransformTitle(action);
		utils.transformOpen(transformRef);
	};
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="purchase-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/PurchaseReturn/list"
            sortColumn={"DocumentDate"}
            sortOrder={"desc"}
            listingTitle={"Purchase Return"}
            storageName={"PurchaseReturnListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
            customizedAddButton={
                <DropDownButton
                    className="customized-listing-add-btn purchase-listing-btn"
                    splitButton={true}
                    useSelectMode={false}
                    icon='add'
                    text='Add'
                    items={[
                        { id: 1, action: "Transfer from Purchase Invoice" },
                        { id: 2, action: "Transfer from Cash Purchase" }
                    ]}
                    displayExpr='action'
                    keyExpr='id'
                    width={"100px"}
                    dropDownOptions={{ width: "215px" }}
                    onItemClick={onItemClick}
                    onButtonClick={() => {
                        setCurrentEditID("new");
                    }}
                />
            }
        >
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="DocumentNo" />
            <Column dataField="SupplierCode" />
            <Column dataField="SupplierName" />
            <Column dataField="ForexID" caption={"Forex"} >
                <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

        <PurchaseReturnForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            title={"Purchase Return"}
            apiURL={apiURL}
            transferData={transferData}
            clearTransfer={setTransferData}
        />
        
        <Transfer 
            ref={transformRef} 
            title={transformTitle} 
            apiPath={{
                "Transfer from Purchase Invoice": {
                    FullDocumentListing: "api/PurchaseTransfer/InvoiceFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/InvoicePartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/InvoiceFullTransfer",
                    PartialDocumentTransfer:  "api/PurchaseTransfer/InvoicePartialTransfer",
                    FilterPath: "api/PurchaseTransfer/InvoiceFilter"
                },
                "Transfer from Cash Purchase": {
                    FullDocumentListing: "api/PurchaseTransfer/CashPurchaseFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/CashPurchasePartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/CashPurchaseFullTransfer",
                    PartialDocumentTransfer:  "api/PurchaseTransfer/CashPurchasePartialTransfer",
                    FilterPath: "api/PurchaseTransfer/CashPurchaseFilter"
                },
            }}
            transferData={transferFormData}
        />
    </Container>;
}