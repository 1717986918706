import React, { useEffect, useState } from 'react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import ARCreditNoteForm from './form';
import Listing from '../../../components/listing/newListing';
import EInvoiceMain from '../../e-invoice/EInvoiceMain';
export default function ARCreditNoteListing() {
    const [currentDetailID, setCurrentDetailID] = useState(null);
    const apiURL = `/api/ARCreditNote`;
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const [currentEditID, setCurrentEditID] = useState(null);
    function handleDetail(e) {
        setCurrentDetailID(e.row.data.DocumentNo);
    }
    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            });
        });
    }, []);

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
        setCurrentDetailID(id);
    }

    const closePopup = (id) => {
        setCurrentDetailID(id);
    }
    return <Container>
        <div id='normal-listing'>
            <Listing
                className="ar-listing-datagrid"
                apiURL={apiURL}
                detailshow={true}
                listingURL="/api/ARCreditNote/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"AR Credit Note"}
                storageName={"ARCreditNoteListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
                handleDetail={handleDetail}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexID" caption={"Forex"} >
                    <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
                </Column>
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        <ARCreditNoteForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            title={"A/R Credit Note"}
            apiURL={apiURL}
        />

        <EInvoiceMain
            formID={currentDetailID}
            closePopup={closePopup}
            apiURL={apiURL}
            invoiceType="02"
            title={"E-Invoice Main"}
        />
    </Container>;
}