import React from 'react';
import { Column } from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';

export default function UserLog() {
    const apiURL = `/api/UserLog`;

    const formatDateTime = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const year = d.getFullYear();
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    return (
        <Container>
            <div id='normal-listing'>
                <Listing
                    className="user-log-listing-datagrid"
                    apiURL={apiURL}
                    listingURL="/api/UserLog/list"
                    sortColumn={"CreatedDate"}
                    listingTitle={"User Log Activity"}
                    storageName={"UserLogListingColumnWidth"}
                    dateFilter={true}
                    addButtonHide = {true}
                    editButtonHide = {true}

                >
                   
                    <Column
                        dataField="No"
                        width="200px"
                        caption="No"
                        cellRender={(cellData) => {
                            const rowIndex = cellData.rowIndex + 1; 
                            return <span>{rowIndex}</span>;
                        }}
                    />
                    <Column dataField="Action" width="200px" />
                    <Column
                        dataField="CreatedDate"
                        caption="Date"
                        width="300px"
                        dataType="date"
                        cellRender={(cellData) => (
                            <span>{formatDateTime(cellData.value)}</span>
                        )}
                    />
                    <Column dataField="Username" width="300px" />
                    <Column dataField="RoleName" caption={"Role"} width="200px" />
                </Listing>
            </div>
        </Container>
    );
}
