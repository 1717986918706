import PopupForm from "../../../components/popup-form/PopupFormV2";
import { useEffect, useState, useRef, useCallback } from "react";
import { TextBox, DateBox, NumberBox, CheckBox } from "devextreme-react";
import baseapi from "../../../api/baseapi";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import utils, { loading, closeLoading, showMessage } from "../../../utils/common";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { glJournalVoucher } from "../../../utils/lookupstore";
import AsyncDateBox from "../../../components/async-date-box/AsyncDateBox";

export default function JournalEntryForm(props) {
	const [formValue, setFormValue] = useState({});
	const tempForm = useRef({});
	const formID = useRef(null);
	const formRef = useRef(null);
	const formRead = useRef(null);
	const childrenGridRef = useRef(null);
	const journalTypeLookupRef = useRef(null);
	const forexLookupRef = useRef(null);

	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const projectLookupRef = useRef(null);
	const [reportDocumentNo, setReportDocumentNo] = useState(null);
	const [defaultValue, setDefaultValue] = useState({});

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["DocumentNo"])) {
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	const clearForm = () => {
		props.closePopup(null);
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	function valueOnChange(e, receivedDataField = null) {
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "LocalRate") {
					tempForm.current = copied;
					setFormValue(tempForm.current);
					updateCalculation({});
				} else if (dataField === "ForexID") {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;

							tempForm.current = copied;
							setFormValue(tempForm.current);
							updateCalculation({});
						})
						.catch((error) => {
							console.log(error);
						});
				} else if (dataField === "Description") {
					tempForm.current = copied;
					setFormValue(tempForm.current);
					updateCalculation({ descriptionChanged: true });
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const onInitNew = (Line = null) => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			ChartAccountID: null,
			Description: null,
			GLDescription: null,
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			SalesmanID: "58a0MyTSZ6E%3d",
			SalesmanCode: "CO",
			AreaID: utils.popupFormGetDefaultValues(formRef)["AreaID"],
			AreaCode: utils.popupFormGetDefaultValues(formRef)["AreaCode"],
			ForexDebit: 0,
			ForexCredit: 0,
			LocalDebit: 0,
			LocalCredit: 0,
			LocalDebitTax: 0,
			LocalCreditTax: 0
		};
	};

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Journal Entry...");
		else loading("Loading Existing Journal Entry...");

		baseapi.httpget("/api/GLJournal/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if (!utils.isNullOrEmpty(data.defaultValues)) {
				setDefaultValue(data.defaultValues);
			}

			if (formID.current === "new") {
				tempForm.current = data.model.Parent;
				tempForm.current["ForexID"] = "b_xPwSzI5Y0%3d";
				tempForm.current["OutOfBalance"] = 0;
				tempForm.current["TotalTaxable"] = 0;
				tempForm.current["TotalTaxDebit"] = 0;
				tempForm.current["TotalTaxCredit"] = 0;
				utils.childrenGridAddRow(childrenGridRef);
				setFormValue(tempForm.current);
			} else {
				// console.log("Form", data)
				setFormValue(data.model.Parent);
				tempForm.current = data.model.Parent;
				updateCalculation({ children: data.model.Children });
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;
		currentRecords[recordIndex][columnName] = e.value;

		if (columnName === "ChartAccountID") {
			currentRecords[recordIndex]["GLDescription"] = selectedItem?.name;
			currentRecords[recordIndex]["ChartAccountCode"] = selectedItem?.code;
		} else if (columnName === "TaxCodeID") {
			currentRecords[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			currentRecords[recordIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "ProjectID") {
			currentRecords[recordIndex]["ProjectCode"] = selectedItem?.code;
		} else if (columnName === "SalesmanID") {
			currentRecords[recordIndex]["SalesmanCode"] = selectedItem?.code;
		} else if (columnName === "AreaID") {
			currentRecords[recordIndex]["AreaCode"] = selectedItem?.code;
		}

		// e["key"] = key;
		// e["data"] = JournalDetails[recordIndex];
		// OverallDetailsCalculation(e, JournalDetails);
	};

	const OverallDetailsCalculation = (e, children = null) => {
		try {
			const oldValue = e.oldData;
			const currentRow = Object.assign(oldValue, e.newData);
			var currentField = Object.keys(e.newData)[0];
			var DebitIncTax = 0;
			var CreditIncTax = 0;

			//If Tax Code ID Clear value
			if (utils.isNullOrEmpty(currentRow["TaxCodeID"])) {
				currentRow["TaxRate"] = 0;
			}

			if (currentField === "ForexDebit" && currentRow["ForexDebit"] > 0) {
				currentRow["ForexCredit"] = 0;
			} else if (currentField === "ForexCredit" && currentRow["ForexCredit"] > 0) {
				currentRow["ForexDebit"] = 0;
			}

			currentRow["LocalDebit"] = utils.multiply(currentRow["ForexDebit"], tempForm.current["LocalRate"]);
			currentRow["LocalCredit"] = utils.multiply(currentRow["ForexCredit"], tempForm.current["LocalRate"]);

			if (currentRow["TaxInclusive"] === true) {
				DebitIncTax = currentRow["LocalDebit"];
				CreditIncTax = currentRow["LocalCredit"];
			} else {
				DebitIncTax = utils.add(currentRow["LocalDebit"], utils.divide(utils.multiply(currentRow["LocalDebit"], currentRow["TaxRate"]), 100));
				CreditIncTax = utils.add(currentRow["LocalCredit"], utils.divide(utils.multiply(currentRow["LocalCredit"], currentRow["TaxRate"]), 100));
			}

			currentRow["Description"] = utils.isNullOrEmpty(currentRow["Description"]) || e.descriptionChanged !== undefined ? tempForm.current["Description"] : currentRow["Description"];

			currentRow["LocalDebitTax"] = utils.roundUp(DebitIncTax);
			currentRow["LocalCreditTax"] = utils.roundUp(CreditIncTax);

			//Assign the calculation to the new data
			e.newData = currentRow;
		} catch (error) {
			console.log("error from OverallDetailsCalculation", error);
		}
	};

	const updateCalculation = ({ descriptionChanged = null, children = null }) => {
		const ParentValue = { ...tempForm.current };
		const childrenCopied = children ? children : utils.childrenGridGetSource(childrenGridRef);
		const array = [];
		var DebitIncTax = 0;
		var CreditIncTax = 0;
		var TotalDebitIncTax = 0;
		var TotalCreditIncTax = 0;
		var TotalDebitTaxAmount = 0;
		var TotalCreditTaxAmount = 0;
		var TotalTaxAmount = 0;

		for (var i = 0; i < childrenCopied.length; i++) {
			const rowData = childrenCopied[i];
			rowData["LocalDebit"] = utils.multiply(rowData["ForexDebit"], tempForm.current["LocalRate"]);
			rowData["LocalCredit"] = utils.multiply(rowData["ForexCredit"], tempForm.current["LocalRate"]);

			if (rowData["TaxInclusive"] === true) {
				DebitIncTax = rowData["LocalDebit"];
				CreditIncTax = rowData["LocalCredit"];
			} else {
				DebitIncTax = utils.add(rowData["LocalDebit"], utils.divide(utils.multiply(rowData["LocalDebit"], rowData["TaxRate"]), 100));
				CreditIncTax = utils.add(rowData["LocalCredit"], utils.divide(utils.multiply(rowData["LocalCredit"], rowData["TaxRate"]), 100));
			}

			rowData["Description"] = utils.isNullOrEmpty(rowData["Description"]) || descriptionChanged ? tempForm.current["Description"] : rowData["Description"];

			rowData["LocalDebitTax"] = utils.roundUp(DebitIncTax);
			rowData["LocalCreditTax"] = utils.roundUp(CreditIncTax);

			TotalDebitIncTax = utils.add(TotalDebitIncTax, rowData["LocalDebitTax"]);
			TotalCreditIncTax = utils.add(TotalCreditIncTax, rowData["LocalCreditTax"]);

			TotalDebitTaxAmount += utils.divide(utils.multiply(rowData["LocalDebit"], rowData["TaxRate"]), 100);
			TotalCreditTaxAmount += utils.divide(utils.multiply(rowData["LocalCredit"], rowData["TaxRate"]), 100);

			if (rowData["TaxRate"] > 0) {
				TotalTaxAmount += utils.subtract(rowData["LocalDebit"], rowData["LocalCredit"]);
			}

			array.push(rowData);
		}

		ParentValue["TotalTaxDebit"] = TotalDebitTaxAmount;
		ParentValue["TotalTaxCredit"] = TotalCreditTaxAmount;
		ParentValue["TotalTaxable"] = TotalTaxAmount;
		ParentValue["OutOfBalance"] = TotalDebitIncTax - TotalCreditIncTax;

		tempForm.current = ParentValue;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, array);
	};

	const childrenUpdated = () => {
		const ParentValue = { ...tempForm.current };
		const childrenCopied = utils.childrenGridGetSource(childrenGridRef);

		var TotalDebitIncTax = 0;
		var TotalCreditIncTax = 0;
		var TotalDebitTaxAmount = 0;
		var TotalCreditTaxAmount = 0;
		var TotalTaxAmount = 0;

		for (var i = 0; i < childrenCopied.length; i++) {
			const rowData = childrenCopied[i];

			TotalDebitIncTax = utils.add(TotalDebitIncTax, rowData["LocalDebitTax"]);
			TotalCreditIncTax = utils.add(TotalCreditIncTax, rowData["LocalCreditTax"]);

			TotalDebitTaxAmount += utils.divide(utils.multiply(rowData["LocalDebit"], rowData["TaxRate"]), 100);
			TotalCreditTaxAmount += utils.divide(utils.multiply(rowData["LocalCredit"], rowData["TaxRate"]), 100);

			if (rowData["TaxRate"] > 0) {
				TotalTaxAmount += utils.subtract(rowData["LocalDebit"], rowData["LocalCredit"]);
			}
		}

		ParentValue["TotalTaxDebit"] = TotalDebitTaxAmount;
		ParentValue["TotalTaxCredit"] = TotalCreditTaxAmount;
		ParentValue["TotalTaxable"] = TotalTaxAmount;
		ParentValue["OutOfBalance"] = TotalDebitIncTax - TotalCreditIncTax;

		tempForm.current = ParentValue;
		setFormValue(tempForm.current);
		// childrenGridRef.current.instance.refresh();
	};

	const childrenRemoved = (e) => {
		const ParentValue = { ...tempForm.current };
		const childrenCopied = utils.childrenGridGetSource(childrenGridRef);
		const leftOver = [];

		var TotalDebitIncTax = 0;
		var TotalCreditIncTax = 0;
		var TotalDebitTaxAmount = 0;
		var TotalCreditTaxAmount = 0;
		var TotalTaxAmount = 0;

		for (var i = 0; i < childrenCopied.length; i++) {
			const rowData = childrenCopied[i];

			TotalDebitIncTax = utils.add(TotalDebitIncTax, rowData["LocalDebitTax"]);
			TotalCreditIncTax = utils.add(TotalCreditIncTax, rowData["LocalCreditTax"]);

			TotalDebitTaxAmount += utils.divide(utils.multiply(rowData["LocalDebit"], rowData["TaxRate"]), 100);
			TotalCreditTaxAmount += utils.divide(utils.multiply(rowData["LocalCredit"], rowData["TaxRate"]), 100);

			if (rowData["TaxRate"] > 0) {
				TotalTaxAmount += utils.subtract(rowData["LocalDebit"], rowData["LocalCredit"]);
			}

			rowData["Line"] = i + 1;
			leftOver.push(rowData);
		}

		ParentValue["TotalTaxDebit"] = TotalDebitTaxAmount;
		ParentValue["TotalTaxCredit"] = TotalCreditTaxAmount;
		ParentValue["TotalTaxable"] = TotalTaxAmount;
		ParentValue["OutOfBalance"] = TotalDebitIncTax - TotalCreditIncTax;

		tempForm.current = ParentValue;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, leftOver);
	};

	function handleSubmit(e) {
		if (!utils.isNullOrEmpty(e.validData)) {
			const validData = e.validData;
			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};

			loading(`Saving ${props.title}...`);

			baseapi.httppost(utils.extendUrlVar("/api/GLJournal/save", { id: formID.current || "", del: false }), submittedForm).then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
				if (data.docNo) setReportDocumentNo(data.docNo);
			});
		}
	}

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	const customizedValidation = () => {
		if (formValue["OutOfBalance"] !== 0) {
			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: `Total Local Credit (Tax) does not tally with Total Local Debit (Tax) (${formValue["OutOfBalance"].toFixed(2)} Out of Balance)`,
				type: "Warning",
			});

			return false;
		}
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				ReportDocumentNo={reportDocumentNo}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				headerClassName={"gl-module-theme-color gl-module-title-font-size"}
				creditChecking={false}
				title={props.title}
				apiURL={props.apiURL}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				requiredColumns={{
					Children: ["ChartAccountID"]
				}}
				filterRules={{
					Children: [
						{ name: "ChartAccountID", rules: [{ name: "Required" }] },
						{ name: "GLDescription", rules: [{ name: "Required" }] },
						{ name: "ForexDebit", rules: [{ name: "Min", value: 1 }] },
						{ name: "ForexCredit", rules: [{ name: "Min", value: 1 }] }
					]
				}}
				customizedValidation={customizedValidation}
				defaultSelectedReport={"zlNBPx1r82g%3d"}
				reportURL={glJournalVoucher.url}
				onValidating={setStartValidation}
				startValidation={startValidation}
				copyFields={{
					Parent: formValue,
					Children: utils.childrenGridGetSource(childrenGridRef)
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
				}}
				copyStorage={"GLJournalEntryCopiedData"}
				formData={formValue}
				isEditing={isEditing}
				lookupValidateRef={[journalTypeLookupRef, forexLookupRef, projectLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-form-main-content'>
					<div className='popup-form-custom-form-grid-section1'>
						<div className='popup-form-item-container2'>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Journal Type </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={journalTypeLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["JournalTypeID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "JournalTypeID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Journal Type is required!"}
											disabled={props.lockedForm === true}
											dataSourceURL={"api/Utilities/GetJournalTypes"}
											displayText={formValue["JournalTypeCode"]}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Customer Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Project </div>

									<div className='popup-group-form-input' id='customized-lookup1'>
										<CustomizedLookup
											ref={projectLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ProjectID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ProjectID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Project is required!"}
											dataSourceURL={"api/Utilities/GetProjects"}
											disabled={props.lockedForm === true}
											displayText={formValue["ProjectCode"]}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Project Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>

							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label next-doc-label'>Journal No </div>

									<div className='popup-group-form-input'>
										<div className='next-doc-display'>Next Doc : {formValue["NextDocNo"]}</div>
										<AsyncTextBox
											value={formValue["DocumentNo"]}
											elementAttr={{ dataField: "DocumentNo" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											placeholder={"<NEW>"}
											alignment='left'
											width={"auto"}
											disabled={formID.current !== "new" || props.lockedForm === true}
											asyncURL={"api/GLJournal/CheckDuplicateDocNo"}
											asyncMessage={"This Journal No is already taken!"}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Date </div>

									<div className='popup-group-form-input'>
										<AsyncDateBox
											value={formValue["DocumentDate"]}
											elementAttr={{ dataField: "DocumentDate" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											displayFormat='dd/MM/yyyy'
											dateSerializationFormat='yyyy-MM-dd'
											useMaskBehavior={true}
											readOnly={props.lockedForm === true}
											asyncURL={"api/Utilities/CheckAfterFiscalYear"}
											asyncMessage={"Date cannot set before current fiscal year"}
											asyncProperty={"inputDate"}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container2'>
							<div className='popup-form-item-container1'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Description </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Description"]}
											elementAttr={{ dataField: "Description" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={props.lockedForm === true}>
											<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
										</TextBox>
									</div>
								</div>
							</div>

							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label next-doc-label'>Forex Code </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={forexLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ForexID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ForexID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Forex Code is required!"}
											disabled={props.lockedForm === true}
											dataSourceURL={"api/Utilities/GetForexs"}
											displayText={formValue["ForexCode"]}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Description"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Local Rate </div>

									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["LocalRate"]}
											elementAttr={{ dataField: "LocalRate" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											format={ utils.getDecimalFormat(defaultValue["LocalRateFixCheck"], defaultValue["LocalRateFormat"]) }
											useMaskBehavior={true}
											readOnly={formValue["ForexID"] === defaultValue["ForexID"]}>
											<Validator>{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
										</NumberBox>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='popup-form-grid-container'>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							defaultColumnValues={onInitNew}
							className={"gl-listing-datagrid"}
							keyExpr='ID'
							showBorders={true}
							onRowUpdating={OverallDetailsCalculation}
							onRowUpdated={childrenUpdated}
							onRowRemoved={childrenRemoved}
							disabled={props.lockedForm === true}
							enabledDescription={false}
							onParentValueChanged={valueOnChange}
							storageName={"GLJournalEntryChildrenGrid"}>
							<Column dataField={"Line"} caption={"No"} editorOptions={{ readOnly: true }} />

							<Column
								visible={false}
								dataField={"SalesmanID"}
								caption={"Salesman"}
								cellRender={(e) => {
									const data = e.data;
									return data.SalesmanCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "SalesmanID")}
										dataSourceURL={"api/Utilities/GetSalesmans"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}
							/>

							<Column
								visible={false}
								dataField={"AreaID"}
								caption={"Area"}
								cellRender={(e) => {
									const data = e.data;
									return data.AreaCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "AreaID")}
										dataSourceURL={"api/Utilities/GetAreas"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}
							/>

							<Column
								dataField={"ChartAccountID"}
								caption={"Chart Account"}
								cellRender={(e) => {
									const data = e.data;
									return data.ChartAccountCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")}
										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										mergeParams={{ chartAccountType: "GL" }}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}
							/>

							<Column dataField={"GLDescription"} editorOptions={{ readOnly: true }} />
							<Column dataField={"Description"} />
							<Column
								dataField={"ProjectID"}
								caption={"Project"}
								cellRender={(e) => {
									const data = e.data;
									return data.ProjectCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
										dataSourceURL={"api/Utilities/GetProjects"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}
							/>
							<Column dataField={"ForexDebit"} caption={"Debit"} format='#,##0.00' />
							<Column dataField={"ForexCredit"} caption={"Credit"} format='#,##0.00' />
							<Column dataField={"LocalDebit"} editorOptions={{ readOnly: true }} format='#,##0.00' />
							<Column dataField={"LocalCredit"} editorOptions={{ readOnly: true }} format='#,##0.00' />
							<Column
								dataField={"TaxCodeID"}
								caption={"Tax Code"}
								cellRender={(e) => {
									const data = e.data;
									return data.TaxCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
										dataSourceURL={"api/Utilities/GetGSTRate"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>
							<Column dataField={"TaxRate"} editorOptions={{ readOnly: true }} format='#,##0.00' />
							<Column dataField={"TaxInclusive"} />
							<Column dataField={"LocalDebitTax"} caption={"Debit Incl. Tax"} editorOptions={{ readOnly: true }} format='#,##0.00' />
							<Column dataField={"LocalCreditTax"} caption={"Cedit Incl. Tax"} editorOptions={{ readOnly: true }} format='#,##0.00' />
							<Summary recalculateWhileEditing={true}>
								<TotalItem column='ForexDebit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexCredit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalDebit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalCredit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalDebitTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalCreditTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							</Summary>
						</ChildrenDatagrid>
					</div>

					<div className='popup-form-total-container'>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Total Taxable </div>

								<div className='popup-group-form-input max-width-250'>
									<NumberBox
										format='#,##0.00'
										value={formValue["TotalTaxable"]}
										elementAttr={{ dataField: "TotalTaxable" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={true}
										text={0}></NumberBox>
								</div>
							</div>

							<div className='popup-form-item-container'>
								<div className='popup-group-form-item form-item-justify-right'>
									<div className='popup-group-form-label'>Total Tax Amount </div>

									<div className='popup-group-form-input max-width-250'>
										<NumberBox
											format='#,##0.00'
											value={formValue["TotalTaxDebit"]}
											elementAttr={{ dataField: "TotalTaxDebit" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={true}
											text={0}></NumberBox>
									</div>

									<div className='popup-group-form-input max-width-250'>
										<NumberBox
											format='#,##0.00'
											value={formValue["TotalTaxCredit"]}
											elementAttr={{ dataField: "TotalTaxCredit" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={true}
											text={0}></NumberBox>
									</div>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container2'>
							<div></div>

							<div className='popup-form-item-container'>
								<div className='popup-group-form-item form-item-justify-right'>
									<div className='popup-group-form-label'>Out of Balance </div>

									<div className='popup-group-form-input max-width-250'>
										<NumberBox
											format='#,##0.00'
											value={formValue["OutOfBalance"]}
											elementAttr={{ dataField: "OutOfBalance" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={true}
											text={0}></NumberBox>
									</div>

									<div className='popup-group-form-input max-width-250'></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopupForm>
		</div>
	);
}
