import React, { useState, useEffect, useRef } from 'react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import WorkshopInvoiceForm from './form';
import Listing from '../../../components/listing/newListing';
import { chartAccountPayment, workshopRegistration } from '../../../utils/lookupstore';
import DropDownButton from 'devextreme-react/drop-down-button';
import TransferForm from "../transfer/transfer";
import utils from "../../../utils/common";
import EInvoiceMain from '../../e-invoice/EInvoiceMain';
export default function WorkshopInvoiceListing() {

    const apiURL = `/api/WKInvoice`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [inputType, setInputType] = useState(null);
    const [lockForm, setLockForm] = useState(false);
    const transformRef = useRef(null);
    // Look up list
    const [registrationList, setRegistrationList] = useState([]);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [dropDownRead, setDropDownRead] = useState(false);
    const [transferData, setTransferData] = useState(null);
    const [transfer, setTransfer] = useState(false);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const addToInvoiceID = urlParams.get("addToInvoice");
    const InvoiceID = urlParams.get("ID");
    const readInvoiceIDBefore = useRef(null);
	const [currentDetailID, setCurrentDetailID] = useState(null);

    useEffect(() => {
       
        if (addToInvoiceID && addToInvoiceID !== "") {  
            transferFormData();
        }
    }, [addToInvoiceID]);

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }
    const onItemClick = (e) => {
        const data = e.itemData;
        if (data.name === "Transfer From Job Sheet") {
            utils.transformOpen(transformRef);
        }

    }
    function handleDetail(e) {
		setCurrentDetailID(e.row.data.DocumentNo);
	}
    const transferFormData = () => {
        setCurrentEditID("new");
        if (transformRef.current) {
            transformRef.current.onClosing();
        }
        setTransfer(true);

    };

    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/WKInvoice/list"
                className={" "}
                detailshow={true}
                handleDetail={handleDetail}
                sortColumn={"DocumentNo"}
                listingTitle={"Workshop Invoice"}
                storageName={"WorkshopInvoiceListing"}
                customizedAddButton={
                    <DropDownButton
                        className="workshop-listing-page-add-btn customized-listing-add-btn"
                        splitButton={true}
                        useSelectMode={false}
                        icon='add'
                        text="Add"
                        items={[{ id: 1, name: 'Transfer From Job Sheet' }]}
                        displayExpr="name"
                        keyExpr="id"
                        width={"100px"}
                        dropDownOptions={
                            { width: "175px" }
                        }
                        onButtonClick={() => { setCurrentEditID("new"); setInputType(null); setLockForm(false); }}
                        onItemClick={onItemClick}
                    />
                }
                handleEdit={handleEdit}
            >
                <Column dataField="DocumentNo" />
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="RegNo"/>
                <Column dataField="FromJobDocumentNo" caption={"Transferred To Job No"} />
                <Column dataField="PaymentMethodName" caption="Payment Method" />
                <Column dataField="MainForexAmount" caption={"Invoice Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        <WorkshopInvoiceForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL}
            title={"Invoice Entry"}
            transfer={transfer}
            clearTransfer={setTransfer}
        />
        <TransferForm
            ref={transformRef}
            title={"Transfer From Jobsheet"}
            transferData={transferFormData}
            filterUrl={`api/Utilities/GetWKRegistrationFilter?isInvoice=${false}`}
            // filterUrl={`api/Utilities/GetWKRegistration`}
            apiUrl={"api/WKJobsheet/ItemFilter"}
            localStorage={"WorkshopJobSheetAddToInvoice"}

        />

<EInvoiceMain 
				formID={currentDetailID} 
				closePopup={setCurrentDetailID} 
				apiURL={apiURL} 
                invoiceType="01-WK"
				title={"E-Invoice Main"} 
			/>
    </Container>;
}