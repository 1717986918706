import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { loading, closeLoading } from "../../utils/common";
import './UpgradeReviewPaymentForm.scss';
import TermsOfUse from '../popup-form/TermsOfUse';
import PrivacyPolicy from '../popup-form/PrivacyPolicy';
import { ScrollView } from 'devextreme-react/scroll-view';
const UpgradeReviewPaymentForm = ({ visible, onClose, activeTab, onUpdate }) => {

    const [popupWidth, setPopupWidth] = useState("65%");
    const [paymentDetails, setPaymentDetails] = useState(null); // State for API data
    const isUpgrade = activeTab === 'upgrade';
    const [termsDialogVisible, setTermsVisible] = useState(false);
    const [privacyDialogVisible, setPrivacyVisible] = useState(false);
    const [scrollViewHeight, setScrollViewHeight] = useState('400px');
    const updateScrollViewHeight = () => {
        const height = window.innerHeight;
        // Set ScrollView height based on window height
        setScrollViewHeight(height < 800 ? '400px' : '500px'); // Adjust heights as needed
    };
    const handleTermsClose = () => {
        setTermsVisible(false);
    };

    const handlePrivacyClose = () => {
        setPrivacyVisible(false);
    };

    const handlePrivacyClick = () => {
        setPrivacyVisible(true);
    };

    const handleTermsClick = () => {
        setTermsVisible(true);
    };

    useEffect(() => {
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else {
                setPopupWidth("65%");
            }
        };

        updatePopupWidth();
        window.addEventListener('resize', updatePopupWidth);
        updateScrollViewHeight(); // Set initial height
        window.addEventListener('resize', updateScrollViewHeight); // Update height on resize

        return () => {
            window.removeEventListener('resize', updatePopupWidth);
            window.removeEventListener('resize', updateScrollViewHeight);
        };
    }, []);

    useEffect(() => {
        if (visible) {
            loading("Loading Subscription Plan...");
            const fetchPaymentDetails = async () => {
                try {
                    const endpoint = '/api/Payment/GetUpgradePaymentDetail';

                    baseapi.httpget(endpoint)
                        .then(response => {
                            if (response.data.status) {
                                // Update the state with the response data
                                setPaymentDetails(response.data.model);
                            } else {
                                notify(response.data.message, 'error', 2000);
                            }
                        })
                        .catch(error => {
                            console.error('Error processing payment details:', error.message);
                        })
                        .finally(() => closeLoading());
                } catch (error) {
                    console.error('Error fetching payment details:', error.message);
                    closeLoading();
                }
            };

            fetchPaymentDetails();
        }
    }, [visible, isUpgrade]);

    const handleSubmit = () => {
        const paymentEndpoint = isUpgrade
            ? '/api/Payment/HandleUpgradePayment'
            : '/api/Payment/HandleUpcomingPayment';

        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to proceed with the payment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, proceed!',
            cancelButtonText: 'No, cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                loading("Processing Payment...");
                baseapi.httppost(paymentEndpoint)
                    .then(response => {
                        if (response.data.status) {
                            Swal.fire('Success!', response.data.message, 'success');
                            onClose(); // Close the popup on success
                            if (onUpdate) {
                                onUpdate(); // Update any parent components if needed
                            }
                        } else {
                            notify(response.data.message, 'error', 3000);
                        }
                    })
                    .catch(error => {
                        console.error('Payment submission error:', error.message);
                        Swal.fire('Error!', 'There was an error processing the payment. Please try again later.', 'error');
                    })
                    .finally(() => closeLoading());
            }
        });
    };
    const handleOnClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                width={popupWidth}
                height={"auto"}
                title='Review Payment Details'
            >
                {paymentDetails ? (
                    <div className="review-payment-container">
                        <h3 className="reviewpayment-title">Please review details below before proceeding to payment.</h3>

                        <div className="reviewpayment-companyname">
                            <span>Company Name</span>
                            <strong>{paymentDetails.CompanyName}</strong>
                        </div>

                        <div className="reviewpayment-section">
                            <div className="upgrade-reviewpayment-details">
                                <div className="upgrade-reviewpayment-plan-box">
                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Current Plan</div>
                                        <div className="summary-value">{paymentDetails.CurrentPlanName}</div>
                                    </div>


                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Subscription</div>
                                        <div className="summary-value">
                                            {new Date(paymentDetails.SubscriptionStartDate).toLocaleDateString('en-GB')} - {new Date(paymentDetails.SubscriptionEndDate).toLocaleDateString('en-GB')}
                                        </div>
                                    </div>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Current Total User</div>
                                        <div className="summary-value">{paymentDetails.CurrentTotalUser}</div>
                                    </div>


                                </div>
                            </div>
                            <ScrollView className="upgrade-reviewpayment-summary" style={{ height: scrollViewHeight }} direction="vertical">
                            
                            <div className="upgrade-reviewpayment-summary">

                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">New Subscription Plan</div>
                                    <div className="summary-value">
                                        {paymentDetails.PlanPriceTotal > 0 ? paymentDetails.UpgradePlanName : '-'}
                                    </div>
                                </div>

                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Subscription Plan Fee</div>
                                    <div className="summary-value">
                                        {paymentDetails.PlanPriceTotal > 0 ? (
                                            <>
                                                RM {utils.numberToCurrency(paymentDetails.PlanPriceTotal)}<br />
                                                <span className="reviewpayment-additionaltext">
                                                    {paymentDetails.Duration} x RM {utils.numberToCurrency(paymentDetails.PlanAmount)}
                                                </span>
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>

                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Additional User(s)</div>
                                    <div className="summary-value">
                                        {paymentDetails.LicensedPriceTotal > 0 ? paymentDetails.AddOnUser : '-'}
                                    </div>
                                </div>

                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Additional User Fee</div>
                                    <div className="summary-value">
                                        {paymentDetails.LicensedPriceTotal > 0 ? (
                                            <>
                                                RM {utils.numberToCurrency(paymentDetails.LicensedPriceTotal)}<br />
                                                <span className="reviewpayment-additionaltext">
                                                    {paymentDetails.AddOnUser} seat x {paymentDetails.Duration} x RM {utils.numberToCurrency(paymentDetails.LicensedUserPrice)}
                                                </span>
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Revised Licensed User Rate Adjustment</div>
                                    <div className="summary-value">
                                        {paymentDetails.DifferenceLicensedUserTotalPrice > 0 ? (
                                            <>
                                                RM {utils.numberToCurrency(paymentDetails.DifferenceLicensedUserTotalPrice)}<br />
                                                <span className="reviewpayment-additionaltext">
                                                    {paymentDetails.CurrentPlanAddOnUser} seat x {paymentDetails.Duration} x RM {utils.numberToCurrency(paymentDetails.DifferenceLicensedUserPrice)}
                                                </span>
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>
                                {paymentDetails.UpcomingPaidDifferencePlanTotalPrice > 0 && (
                                    <div className="reviewpayment-item">
                                        <div className="summary-review-label">Paid Upcoming Plan Revised Plan Rate</div>
                                        <div className="summary-value">
                                            RM {utils.numberToCurrency(paymentDetails.UpcomingPaidDifferencePlanTotalPrice)}<br />
                                            <span className="reviewpayment-additionaltext">
                                            {paymentDetails.UpcomingPaidDuration} x RM {utils.numberToCurrency(paymentDetails.UpcomingPaidDifferencePlanPrice)}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                {paymentDetails.UpcomingPaidDifferenceLicensedUserTotalPrice > 0 && (
                                    <div className="reviewpayment-item">
                                        <div className="summary-review-label">Paid Upcoming Plan Revised Licensed User Rate</div>
                                        <div className="summary-value">
                                            RM {utils.numberToCurrency(paymentDetails.UpcomingPaidDifferenceLicensedUserTotalPrice)}<br />
                                            <span className="reviewpayment-additionaltext">
                                                {paymentDetails.UpcomingPaidDifferenceLicensedUser} seat x {paymentDetails.UpcomingPaidDuration} x RM {utils.numberToCurrency(paymentDetails.UpcomingPaidDifferenceLicensedUserPrice)}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                {paymentDetails.UpcomingHaveNotPaidLicensedUserTotalPrice > 0 && (

                                    <div className="reviewpayment-item">
                                        <div className="summary-review-label">Upcoming Plan Revised Licensed User Rate</div>
                                        <div className="summary-value">
                                            RM {utils.numberToCurrency(paymentDetails.UpcomingHaveNotPaidLicensedUserTotalPrice)}<br />
                                            <span className="reviewpayment-additionaltext">
                                                {paymentDetails.UpcomingHaveNotPaidLicensedUser} seat x {paymentDetails.UpcomingPaidDuration} x RM {utils.numberToCurrency(paymentDetails.LicensedUserPrice)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">New Plan Start From</div>
                                    <div className="summary-value">
                                        {new Date(paymentDetails.NewPlanStartDate).toLocaleDateString('en-GB')}
                                    </div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Sub Total</div>
                                    <div className="summary-value">
                                        RM {utils.numberToCurrency(paymentDetails.Subtotal)}
                                    </div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">SST (8%)</div>
                                    <div className="summary-value">
                                        RM {utils.numberToCurrency(paymentDetails.SSTPrice)}
                                    </div>
                                </div>


                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Total incl. SST</div>
                                    <div className="summary-value reviewpayment-total">
                                        <strong>RM {utils.numberToCurrency(paymentDetails.TotalPrice)}</strong>
                                        {!isUpgrade && paymentDetails.DiscountPrice > 0 && (
                                            <>
                                                <br />
                                                <span className="reviewpayment-additionaltext">
                                                    Save RM {utils.numberToCurrency(paymentDetails.DiscountPrice)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                               
                            </div>
                            </ScrollView>
                        </div>

                        <p className="reviewpayment-terms">
                            *By signing up, you agree to our <a href="#" onClick={handleTermsClick}>Terms of Use</a> and acknowledge you've read our <a href="#" onClick={handlePrivacyClick}>Privacy Policy</a>.
                        </p>

                        <div className="reviewpayment-buttons">
                            <button className="reviewpayment-cancel" onClick={handleOnClose}>Cancel</button>
                            <button className="reviewpayment-submit" onClick={handleSubmit}>Make Payment</button>
                        </div>
                    </div>
                ) : (
                    <p>Loading payment details...</p>
                )}
            </Popup>

            <TermsOfUse
                visible={termsDialogVisible}
                onClose={handleTermsClose}
                popupWidth={popupWidth.current}
            />
            <PrivacyPolicy
                visible={privacyDialogVisible}
                onClose={handlePrivacyClose}
                popupWidth={popupWidth.current}
            />
        </>
    );
};

export default UpgradeReviewPaymentForm;
