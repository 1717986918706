import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../api/baseapi";
import { Column } from "devextreme-react/data-grid";
import utils, { loading, closeLoading } from "../../utils/common";
import PathIndicator from "../path-indicator/PathIndicator";
import Listing from "./newListing";
import SettingForm from "../../pages/settings/SettingForm";

export default function SettingListing(props) {
	const [currentEditID, setCurrentEditID] = useState(null);

	const handleEdit = (e) => {
        const id = e.row.key;
		setCurrentEditID(id);
    }

	const renderChildren = () => {
		if(props.defaultSettingTemplate !== true){
			return props.children;
		}
		else{
			return [
				renderDefaultColumn1(),
				renderDefaultColumn2(),
				renderDefaultColumn3(),
				renderDefaultColumn4()
			];
		}
	};

	const renderDefaultColumn1 = () =>{
		if(props.defaultSettingTemplate !== false){
			return <Column 
				dataField='Code' 
				dataType='string' 
				cellTemplate={(container, options) => {
					// Render the code text
					container.textContent = options.data.Code;
					// If the Default field is true, append the word "default"
					if (options.data.IsDefault) {
						const defaultSpan = document.createElement("span");
						defaultSpan.classList.add("default-option-label");
						defaultSpan.textContent = "Default";
						container.appendChild(defaultSpan);
					}
				}}
				width={250}
			/>
		}
	};

	const renderDefaultColumn2 = () => {
		if(props.defaultSettingTemplate !== false){
			return <Column dataField='Name' dataType='string' />;
		}
	};

	const renderDefaultColumn3 = () => {
		if(props.defaultSettingTemplate !== false){
			return <Column
				dataField='Cancelled'
				caption={"Active"}
				dataType='string'
				customizeText={(v) => {
					return v.value ? "Yes" : "No";
				}}
			/>
		}
	};

	const renderDefaultColumn4 = () => {
		if(props.defaultSettingTemplate !== false){
			return <Column dataField='Modified' format='dd/MM/yyyy' dataType={"date"} />;
		}
	}

	const onValueChanged = (value) => {
		if(!utils.isNullOrEmpty(props.onValueChanged)){
			props.onValueChanged(value);
		}
	};

	useEffect(() => {
		if(!utils.isNullOrEmpty(props.formIDOnChanged)){
			props.formIDOnChanged(currentEditID);
		}
	}, [currentEditID]);

	return (
		<div>
			<Listing
				className={props.className}
				width={props.width !== undefined ? props.width : "100%"}
				height={props.height}
				apiURL={props.apiURL}
				listingURL={props.listingURL}
				listingTitle={props.listingTitle}
				sortColumn={utils.isNullOrEmpty(props.sortColumn) ? "Code" : props.sortColumn}
				storageName={utils.isNullOrEmpty(props.storageName) ? null : props.storageName}
				onAddClick={setCurrentEditID}
            	handleEdit={handleEdit}
			>
				{renderChildren()}
			</Listing>

			<SettingForm 
				formID={currentEditID}
				closePopup={setCurrentEditID}
				title={props.listingTitle}
				apiURL={props.apiURL}
				defaultSettingTemplate={props.defaultSettingTemplate}
				customizedFormRender={props.customizedFormRender}
				onValueChanged={onValueChanged}
			/>

		</div>
	);
};