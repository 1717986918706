import React, { useEffect, useState, } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import ARDebitNoteForm from './form';
import SalesDebitNoteForm from '../../Sales-Module/debit-note/form';
import Listing from '../../../components/listing/newListing';
import baseapi from '../../../api/baseapi';
import utils from '../../../utils/common';
import EInvoiceMain from '../../e-invoice/EInvoiceMain';

export default function ARDebitNoteListing() {

    const apiURL = `/api/ARDebitNote`;

    const [currentDetailID, setCurrentDetailID] = useState(null);

    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });

    function handleDetail(e) {
        setCurrentDetailID(e.row.data.DocumentNo);
    }

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e) {
        const rowData = e.row.data;
        baseapi.httpget("/api/ARDebitNote/CheckSalesCopied", { docNo: rowData.DocumentNo }).then((response) => {
            const { data } = response;
            if (!utils.isNullOrEmpty(data)) {
                if (data.isCopied) {
                    setCurrentEditID2(data.id);
                }
                else {
                    setCurrentEditID(rowData.id);
                }
            }
        });
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentDetailID(id);
    }

    return <Container>
        <div id='normal-listing'>
            <Listing
                className="ar-listing-datagrid"
                apiURL={apiURL}
                detailshow={true}
                listingURL="/api/ARDebitNote/list"
                sortColumn={"DocumentNo"}
                listingTitle={"AR Debit Note"}
                storageName={"ARDebitNoteListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
                handleDetail={handleDetail}
            >
                <Column dataField="DocumentNo" />
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexID" caption={"Forex"} >
                    <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
                </Column>
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        <ARDebitNoteForm
            formID={currentEditID}
            closePopup={closePopup}
            title={"A/R Debit Note"}
            apiURL={apiURL}
        />

        <SalesDebitNoteForm
            formID={currentEditID2}
            closePopup={closePopup}
            title={"Debit Note"}
            apiURL={`/api/SalesDebitNote`}
        />

        <EInvoiceMain
            formID={currentDetailID}
            closePopup={closePopup}
            apiURL={apiURL}
            invoiceType="03"
            title={"E-Invoice Main"}
        />
    </Container>;
}