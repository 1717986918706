import React, { useState, useEffect, useRef } from "react";
import { SelectBox, CheckBox } from "devextreme-react";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import Container from '../../../components/container';
import baseapi from '../../../api/baseapi'; // Import your API methods
import utils from "../../../utils/common";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
export default function DecimalSetting() {
	const decimalOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8];

	const settings = [
		{ label: "Quantity Decimal", key: "quantityDecimal"},
		{ label: "Sales Unit Price Decimal", key: "salesUnitPriceDecimal"},
		{ label: "Purchase Unit Cost Decimal", key: "purchaseUnitCostDecimal" },
		{ label: "Cost Decimal", key: "costDecimal" },
		{ label: "Local Rate Decimal", key: "localRateDecimal" }
	];

	const [decimalSelections, setDecimalSelections] = useState(settings.map(setting => setting.defaultValue));
	const [checkboxValues, setCheckboxValues] = useState(Array(settings.length).fill(false));
	const [isEditing, setIsEditing] = useState(false);
	const popupMessageRef = useRef(null);

	const fetchDecimalSettings = () => {
		baseapi.httpget("/api/DecimalSetting/Get")
			.then((response) => {
				const { status, data } = response.data;
				if (status && data) {
					setDecimalSelections([
						data.QuantityFormat,
						data.UnitPriceFormat,
						data.UnitCostFormat,
						data.CostDecimalFormat,
						data.LocalRateFormat,
					]);
					// Set checkbox values based on fetched data
					setCheckboxValues([
						data.QuantityFixCheck || false,
						data.UnitPriceFixCheck || false,
						data.UnitCostFixCheck || false,
						data.CostDecimalFixCheck || false,
						data.LocalRateFixCheck || false,
					]);
				} else {
					setDecimalSelections(settings.map(setting => setting.defaultValue));
				}
			})
			.catch((error) => {
				console.error("Error fetching decimal settings:", error);
			});
	};

	useEffect(() => {
		fetchDecimalSettings();
	}, []);

	const handleDecimalChange = (index, value) => {
		const newSelections = [...decimalSelections];
		newSelections[index] = value;
		setDecimalSelections(newSelections);
	};

	const handleCheckboxChange = (index, value) => {
		const newCheckboxValues = [...checkboxValues];
		newCheckboxValues[index] = value;
		setCheckboxValues(newCheckboxValues);
	};

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleSaveClick = () => {
		handleSubmit(); // Call handleSubmit on save click
	};

	const handleSubmit = () => {
		const submittedData = {
			QuantityFormat: decimalSelections[0],
			UnitPriceFormat: decimalSelections[1],
			UnitCostFormat: decimalSelections[2],
			CostDecimalFormat: decimalSelections[3],
			LocalRateFormat: decimalSelections[4],
			QuantityFixCheck: checkboxValues[0],
			UnitPriceFixCheck: checkboxValues[1],
			UnitCostFixCheck: checkboxValues[2],
			CostDecimalFixCheck: checkboxValues[3],
			LocalRateFixCheck: checkboxValues[4],
		};

		baseapi.httppost('/api/DecimalSetting/save', submittedData)
			.then((response) => {
				const { data } = response;
				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: data.status ? "Success" : "Warning",
					});
					fetchDecimalSettings();
				} else {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: data.status ? "Success" : "Warning",
					});
				}
			})
			.catch((error) => {
				console.error("Error saving decimal settings:", error);
			});
	};

	return (
		<Container>
			<div>
				<div className='listing-page-title-container'>
					<span className='datagrid-customized-title'>
						<PathIndicator />
					</span>
				</div>
				<div style={{ width: "74%", marginTop: "16px" }}>
					<div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", padding: "40px" }}>
						{settings.map((setting, index) => (
							<div className='popup-form-item-container' key={index} style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
								<div className='popup-group-form-label' style={{ width: "200px" }}>{setting.label}</div>
								<div>
									<SelectBox
										dataSource={decimalOptions}
										value={decimalSelections[index]}
										onValueChange={(value) => handleDecimalChange(index, value)}
										placeholder="Select decimal places"
										showClearButton={false}
										style={{ width: "400px" }}
										readOnly={!isEditing}
									/>
								</div>
								<div style={{ marginLeft: "20px" }}>
									<CheckBox
										value={checkboxValues[index]}
										onValueChange={(value) => handleCheckboxChange(index, value)}
										text="Show Decimal Places"
										readOnly={!isEditing}
									/>
								</div>
							</div>
						))}
					</div>
					<div style={{ marginTop: "30px", textAlign: "right", marginBottom: "300px" }}>
						<button className="setting-edit-btn" onClick={handleEditClick}>Edit</button>
						<button className="setting-ctn-btn" onClick={handleSaveClick} disabled={!isEditing}>Save</button>
					</div>
				</div>
			</div>

			<ErrorPopUpForm ref={popupMessageRef} />
		</Container>
	);
}
