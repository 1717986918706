import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import Validator, { RequiredRule, PatternRule, CompareRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import '../login-form/LoginForm.scss';
import swal from 'sweetalert2';
import baseapi from '../../api/baseapi';
import { useNavigate } from 'react-router-dom';
const ResetPassword = () => {


    const navigate = useNavigate();
    const passwordValidatorRef = useRef(null);
    const confirmPasswordValidatorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(false);
    const formData = useRef({ newPassword: '', confirmPassword: '' });
    const [passwordMode, setPasswordMode] = useState('password');
    const [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const tacCode = urlParams.get('t');

    const showErrorAlert = () => {
        swal.fire({
            title: 'Error',
            text: 'Unauthorized User or Expired Link. Please try again.',
            icon: 'error',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/login');
            }
        });
    };
   
    useEffect(() => {


        const verifyCode = async () => {
            try {

                const response = await baseapi.httppost('/oauth/VerifyCode', { email, tacCode });
                if (!response.data.status) {
                    showErrorAlert();
                }

            } catch (error) {
                console.error('Error occurred while verifying code:', error.message);
                showErrorAlert();
            }
        };

        if (!email || !tacCode) {
            showErrorAlert();
        } else {
            verifyCode();
        }
    }, [navigate]);

    const onSubmit = useCallback(async () => {

        const passwordValidation = passwordValidatorRef.current.instance.validate();
        const confirmPasswordValidation = confirmPasswordValidatorRef.current.instance.validate();

        if (!passwordValidation.isValid || !confirmPasswordValidation.isValid) {
            return;
        }
        const { newPassword, confirmPassword } = formData.current;

        try {
            setLoading(true);
            const response = await baseapi.httppost('/oauth/ResetPassword', { Password: newPassword, ConfirmPassword: confirmPassword, TacCode: tacCode, Email: email , isNewAccount: true});
            setLoading(false);

            if (response.data.status) {
                setShowResetPasswordSuccess(true);
            } else {
                showErrorAlert();
            }
        } catch (error) {
            showErrorAlert();
        }
    }, [email,tacCode]);

    const handleClose = () =>{
        navigate('/login');
    }
    const passwordButton = useMemo(
        () => ({
            icon: 'images/eye-regular.svg',
            onClick: () => {
                setPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
            },
        }),
        [setPasswordMode],
    );

    const confirmPasswordButton = useMemo(
        () => ({
            icon: 'images/eye-regular.svg',
            onClick: () => {
                setConfirmPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
            },
        }),
        [setConfirmPasswordMode],
    );

    const passwordComparison = useCallback(() => formData.current.newPassword, []);

    return (
        <>
            <img src={"/images/nav-logo.png"} alt={"Greenplus Accounting"} width={110} id='nav-logo' style={{ paddingTop: '15px' }} />
            <img className="wave" src="images/wave.svg" alt="wave" />

            <div className="reset-password-invite-container">
                <div className="auth-popup-content">
                    <div className="icon-flex">
                        <div className="icon-container">
                            <i className="fas fa-lock" aria-hidden="true"></i>
                        </div>
                    </div>
                    <p className="auth-popup-title">Setup Your Account</p>
                    <p className="auth-popup-subtitle">Must be at least 8 characters long, contain at least one number and one letter.</p>


                    <TextBox
                        className="auth-pop-up-input"
                        stylingMode="outlined"
                        placeholder='Email'
                        value={email}
                        readOnly={true}
                    >
                    </TextBox>
                    <br />
                    <TextBox
                        onValueChanged={(e) => {
                            formData.current.newPassword = e.value;
                        }}
                        className="auth-pop-up-input"
                        stylingMode="outlined"
                        placeholder='New Password'
                        mode={passwordMode}
                    >
                        <TextBoxButton
                            name="password"
                            location="after"
                            options={passwordButton}
                        />
                        <Validator ref={passwordValidatorRef}>
                            <RequiredRule message="New Password is required" />
                            <PatternRule
                                message="Must contain at least 8 characters, one number, and one letter"
                                pattern={/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/}
                            />
                        </Validator>
                    </TextBox>

                    <br />

                    <TextBox
                        onValueChanged={(e) => {
                            formData.current.confirmPassword = e.value;
                        }}
                        className="auth-pop-up-input"
                        stylingMode="outlined"
                        placeholder='Confirm New Password'
                        mode={confirmPasswordMode}
                    >
                        <TextBoxButton
                            name="password"
                            location="after"
                            options={confirmPasswordButton}
                        />
                        <Validator ref={confirmPasswordValidatorRef}>
                            <RequiredRule message="Confirm Password is required" />
                            <CompareRule
                                message="Password and Confirm Password do not match"
                                comparisonTarget={passwordComparison}
                            />
                        </Validator>
                    </TextBox>

                    <div className="auth-buttons">
                        {!loading && <button className="auth-button" onClick={onSubmit}>Confirm</button>}
                        {loading && (
                            <div className="loading-indicator-container">
                                <div class="loader"></div>
                            </div>
                        )}

                        <a href="#" onClick={handleClose} className="auth-link">&lt;&lt; Back to Login</a>
                    </div>
                </div>
            </div>

            <ResetPasswordSuccess visible={showResetPasswordSuccess} onClose={handleClose} />


        </>
    );
};

export default ResetPassword;
