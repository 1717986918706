import DataGrid, { Editing, Column, Button as ButtonColumn, Lookup, Button as Pager, Paging, Scrolling, Toolbar, Summary, TotalItem } from "devextreme-react/data-grid";
import Container from "../../components/container";
import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, useMemo } from "react";
import utils, { yesno } from "../../utils/common";
import baseapi from "../../api/baseapi";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import PathIndicator from "../../components/path-indicator/PathIndicator";
import ContraForm from "./form";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DateBox } from "devextreme-react/date-box";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";

export default function ContraListing() {
	const [apiURL, setAPIURL] = useState("");
	const [currentEditID, setCurrentEditID] = useState(null);
	const [formValue, setFormValue] = useState({});
	const [countTotal, setCountTotal] = useState(0);
	const [outstandingTotal, setOutstandingTotal] = useState(0);
	const [apTotal, setAPTotal] = useState(0);
	const [arTotal, setARTotal] = useState(0);
	const location = useLocation();
	const [isAR, setIsAR] = useState(false);
	const [offsetForm, setOffsetForm] = useState({ Parent: {}, Children: [] });
	const offsetGridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const gridRef = useRef(null);
	const query = useRef(null);
	const pageSize = useRef(25);
	const selectedPage = useRef(1);
	const fromDate = useRef(null);
	const toDate = useRef(null);
	const sortOdr = useRef(true);
	const popupMessageRef = useRef(null);
	const [focusedRowKey, setFocusedRowKey] = useState(null);
	const [enableFocusRowKey, setEnableFocusRowKey] = useState(false);
	const [formType, setFormType] = useState("");
	const [displayPopupForm, setDisplayPopupForm] = useState(false);
	const [documentNo, setDocumentNo] = useState(null);
	const [documentDate, setDocumentDate] = useState(null);
	const [openedBefore, setOpenedBefore] = useState(false);

	function sortByDate(offsetData) {
		return dayjs(offsetData.DocumentDate);
	}

	const btnBackgroundColorClass = useMemo(() => {
		return {
			"gl-listing-datagrid": "gl-listing-btn1",
			"ar-listing-datagrid": "ar-listing-btn",
			"ap-listing-datagrid": "ap-listing-btn",
			"sales-listing-datagrid": "sales-listing-btn",
			"stock-listing-datagrid": "stock-listing-btn",
			"workshop-listing-datagrid": "workshop-listing-btn"
		};
	});

	useEffect(() => {
		const locationPath = location.pathname;
		if (locationPath === "/arcontra") {
			baseapi.httpget("/api/ARContraMain/GetTotals").then((response) => {
				const { data } = response;
				setOutstandingTotal(utils.numberToCurrency(data.totalOutstanding));
				setAPTotal(utils.numberToCurrency(data.totalAP));
				setARTotal(utils.numberToCurrency(data.totalAR));
				setCountTotal(data.totalCount);
				setAPIURL("/api/ARContraMain/list");
				setIsAR(true);
			});
		} else if (locationPath === "/apcontra") {
			baseapi.httpget("/api/ARContraMain/GetTotals").then((response) => {
				const { data } = response;
				setOutstandingTotal(utils.numberToCurrency(data.totalOutstanding));
				setAPTotal(utils.numberToCurrency(data.totalAP));
				setARTotal(utils.numberToCurrency(data.totalAR));
				setCountTotal(data.totalCount);
				setAPIURL("/api/APContraMain/list");
				setIsAR(false);
			});
		}
	}, []);

	useEffect(() => {
		if (apiURL !== "") {
			baseapi.httpget(apiURL).then((res) => {
				// console.log(res);
				const { data } = res;
				setFormValue(data.items);
				setEnableFocusRowKey(true);
			});
		}
	}, [apiURL]);

	const onFocusedRowChanging = useCallback(async (e) => {
		const rowsCount = e.component.getVisibleRows().length;
		const pageCount = e.component.pageCount();
		const pageIndex = e.component.pageIndex();
		const event = e?.event;
		const key = event.key;
		if (key && e.prevRowIndex === e.newRowIndex) {
			if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
				await e.component.pageIndex(pageIndex + 1);
				e.component.option("focusedRowIndex", 0);
			} else if (e.newRowIndex === 0 && pageIndex > 0) {
				await e.component.pageIndex(pageIndex - 1);
				e.component.option("focusedRowIndex", rowsCount - 1);
			}
		}
	});

	const onFocusedRowChanged = useCallback((e) => {
		const { data } = e.row;
		if (isAR) {
			baseapi.httpget("/api/ARContraOffset/GetOffsets?id=" + data.ARID).then((response) => {
				const { data } = response;
				const copiedChildren = [...data.model.Children];

				for (var i = 0; i < copiedChildren.length; i++) {
					if (copiedChildren[i]["OffsetAmount"] === 0) {
						copiedChildren[i]["OffsetLogDate"] = "";
					} else {
						copiedChildren[i]["OffsetLogDate"] = copiedChildren[i]["OffsetLogDate"];
					}
				}

				setOffsetForm({
					Parent: null,
					Children: copiedChildren
				});
			});
		} else {
			baseapi.httpget("/api/APContraOffset/GetOffsets?id=" + data.APID).then((response) => {
				const { data } = response;
				const copiedChildren = [...data.model.Children];

				for (var i = 0; i < copiedChildren.length; i++) {
					if (copiedChildren[i]["OffsetAmount"] === 0) {
						copiedChildren[i]["OffsetLogDate"] = "";
					} else {
						copiedChildren[i]["OffsetLogDate"] = copiedChildren[i]["OffsetLogDate"];
					}
				}

				setOffsetForm({
					Parent: null,
					Children: copiedChildren
				});
			});
		}
		// console.log(e.component.option("focusedRowKey"));
		setFocusedRowKey(e.component.option("focusedRowKey"));
	});

	const refresh = (param = {}) => {
		setFormValue(
			baseapi.httpget(apiURL).then((res) => {
				// console.log(res);
				const { data } = res;
				setFormValue(data.items);
			})
		);
	};

	function handleEdit(e) {
		const { data } = e.row;
		// console.log("Data", data);
		const id = isAR ? data.ARID : data.APID;
		const type = isAR ? "Customer" : "Supplier";
		setDocumentNo(id !== null ? null : isAR ? data.APDocumentNo : data.ARDocumentNo);
		setDocumentDate(id !== null ? null : isAR ? data.APDocumentDate : data.ARDocumentDate);
		setCurrentEditID(id !== null ? id : "<NEW>");
		setFormType(type);
		setDisplayPopupForm(true);
	}

	function handleAdd(e) {
		setFormType(isAR ? "Customer" : "Supplier");
		// setDocumentNo("<NEW>");
		setOpenedBefore(false);
		setDisplayPopupForm(true);
		setCurrentEditID("<NEW>");
	}

	async function handleDelete(e) {
		var deleteMessage = "Are you sure you want to delete this account?";
		// console.log("Deleted Stuff");

		const result = await yesno(deleteMessage, "Delete Confirmation", ["Yes", "No"]);

		if (result.isConfirmed) {
			let formIDKey, apiEndpoint;
			if (isAR) {
				formIDKey = "ARID";
				apiEndpoint = "/api/ARContraMain";
			} else {
				formIDKey = "APID";
				apiEndpoint = "/api/APContraMain";
			}

			const formID = e.row.data[formIDKey];
			if (formID !== null) {
				baseapi.httpdel(apiEndpoint, { id: formID }).then((res) => {
					const { data } = res;
					if (data.status) {
						refresh();
					}
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: data.status ? "Success" : "Danger"
					});
				});
			} else {
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: isAR ? "No Customer Contra" : "No Supplier Contra",
					type: "Warning"
				});
			}
		}
	}

	function getBackgroundColorClass() {
		const className = isAR ? "ar-listing-datagrid" : "ap-listing-datagrid";
		return btnBackgroundColorClass[className];
	}

	useEffect(() => {
		if (apiURL !== "") {
			refresh();
		}
	}, [currentEditID]);

	return (
		<div>
			<Container>
				<div id='normal-listing'>
					<div className='listing-page-title-container'>
						<span className='datagrid-customized-title'>
							<PathIndicator />
						</span>
					</div>
					<div className='listing-datebox-container'>
						<TextBox
							width={"250px"}
							height={"36px"}
							ref={searchBoxRef}
							className='listing-page-search-box'
							placeholder='Search'
							value={query.current}
							valueChangeEvent='keyup'
							onValueChanged={(e) => {
								query.current = e.value;
								refresh();
								const arrayText = e.value.split(" ");
								gridRef.current.instance.searchByText(arrayText[arrayText.length - 1]); // By default search the last space word
								// console.log("On change", e)
							}}
						/>

						<div className='listing-date-from-container'>
							<DateBox
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								placeholder='From'
								type='date'
								showClearButton={true}
								onValueChanged={(e) => {
									fromDate.current = e.value;
									refresh();
									// console.log("On change", e)
								}}
							/>
						</div>

						<div className='listing-date-to-container'>
							<DateBox
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								placeholder='To'
								type='date'
								showClearButton={true}
								onValueChanged={(e) => {
									toDate.current = e.value;
									refresh();
									// console.log("On change", e)
								}}
							/>
						</div>

						<Button
							height={"38px"}
							icon='plus'
							onClick={(e) => {
								handleAdd(e, isAR ? "Customer" : "Supplier");
							}}
							text='Add'
							className={`listing-page-add-btn ${getBackgroundColorClass()}`}
						/>
					</div>
					<DataGrid
						id='customer-contra-datagrid'
						ref={gridRef}
						dataSource={formValue}
						allowColumnResizing={true}
						className={isAR ? "contralisting ar-listing-datagrid" : "contralisting ap-listing-datagrid"}
						allowColumnReordering={false}
						height={300}
						showRowLines={true}
						focusedRowEnabled={enableFocusRowKey}
                    	keyExpr={isAR ? "ARDocumentNo" : "APDocumentNo"}
						focusedRowKey={focusedRowKey}
						onFocusedRowChanging={onFocusedRowChanging}
						onFocusedRowChanged={onFocusedRowChanged}>
						{isAR && <Column dataField={"ARID"} visible={false} />}

						{!isAR && <Column dataField={"APID"} visible={false} />}

						{isAR && (
							<Column
								dataField={isAR ? "APDocumentNo" : "ARDocumentNo"}
								// caption={"C/T No."}
								visible={false}
								customizeText={(e) => {
									if (e.value === null) {
										return "-";
									} else {
										return e.value;
									}
								}}
							/>
						)}

						<Column
							dataField={"DocumentNo"}
							caption={"C/T No."}
							customizeText={(e) => {
								if (e.value === null) {
									return "-";
								} else {
									return e.value;
								}
							}}
						/>

						<Column
							dataField={"DocumentDate"}
							caption={"Date"}
							customizeText={(e) => {
								const minDate = new Date("0001-01-01");
								const date = new Date(e.value);
								if (date < minDate) {
									return "-";
								} else {
									return utils.dateFormatter(e.value);
								}
							}}
						/>

						<Column
							dataField={isAR ? "ARDocumentNo" : "APDocumentNo"}
							caption={"C/T No."}
							visible={false}
							customizeText={(e) => {
								if (e.value === null) {
									return "-";
								} else {
									return e.value;
								}
							}}
						/>

						<Column
							dataField={isAR ? "ARDocumentDate" : "APDocumentDate"}
							caption={"Date"}
							visible={false}
							customizeText={(e) => {
								const minDate = new Date("0001-01-01");
								const date = new Date(e.value);
								if (date < minDate) {
									return "-";
								} else {
									return utils.dateFormatter(e.value);
								}
							}}
						/>

						<Column
							dataField={isAR ? "ARCustomerName" : "APSupplierName"}
							caption={isAR ? "Customer" : "Supplier"}
							customizeText={(e) => {
								if (e.value === null) {
									return "-";
								} else {
									return e.value;
								}
							}}
						/>

						<Column
							dataField={isAR ? "ARForexCode" : "APForexCode"}
							caption={"Forex"}
							customizeText={(e) => {
								if (e.value === null) {
									return "-";
								} else {
									return e.value;
								}
							}}
						/>

						<Column
							dataField={isAR ? "ARMainLocalAmount" : "APMainLocalAmount"}
							caption={"Amount"}
							customizeText={(e) => {
								if (e.value === 0) {
									return "-";
								} else {
									return utils.numberToCurrency(e.value);
								}
							}}
						/>

						<Column
							dataField={isAR ? "APSupplierName" : "ARCustomerName"}
							caption={isAR ? "Supplier" : "Customer"}
							customizeText={(e) => {
								if (e.value === null) {
									return "-";
								} else {
									return e.value;
								}
							}}
						/>

						<Column
							dataField={isAR ? "APForexCode" : "ARForexCode"}
							caption={"Forex" + (isAR ? " (Supplier)" : " (Customer)")}
							customizeText={(e) => {
								if (e.value === null) {
									return "-";
								} else {
									return e.value;
								}
							}}
						/>

						<Column
							dataField={isAR ? "APMainLocalAmount" : "ARMainLocalAmount"}
							caption={"Amount" + (isAR ? " (Supplier)" : " (Customer)")}
							customizeText={(e) => {
								if (e.value === 0) {
									return "-";
								} else {
									return utils.numberToCurrency(e.value);
								}
							}}
						/>

						<Column
							dataField={isAR ? "AROutstandingAmount" : "APOutstandingAmount"}
							caption={"U/A Amount"}
							customizeText={(e) => {
								if (e.value === 0) {
									return "-";
								} else {
									return utils.numberToCurrency(e.value);
								}
							}}
						/>
						<Column type='buttons' fixed={true}>
							<ButtonColumn name='edit' onClick={(e) => handleEdit(e)} />
							<ButtonColumn name='delete' onClick={(e) => handleDelete(e)} />
						</Column>

						<Summary>
							<TotalItem
								cssClass={"contraTotalSummary"}
								column='DocumentNo'
								summaryType='count'
								customizeText={(e) => {
									// console.log(e);
									return "Total: " + countTotal + " Contra(s)";
								}}
							/>

							<TotalItem
								column='ARMainLocalAmount'
								summaryType='sum'
								customizeText={(e) => {
									return arTotal;
								}}
							/>

							<TotalItem
								column='APMainLocalAmount'
								summaryType='sum'
								customizeText={(e) => {
									return apTotal;
								}}
							/>

							{isAR && (
								<TotalItem
									column='AROutstandingAmount'
									summaryType='sum'
									customizeText={(e) => {
										return outstandingTotal;
									}}
								/>
							)}

							{!isAR && (
								<TotalItem
									column='APOutstandingAmount'
									summaryType='sum'
									customizeText={(e) => {
										return outstandingTotal;
									}}
								/>
							)}
						</Summary>
						<Editing mode='popup' useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={false} />
						<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />
						<Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
						<Paging
							defaultPageSize={25}
							onPageSizeChange={(e) => {
								pageSize.current = e;
								refresh();
							}}
							onPageIndexChange={(e) => {
								selectedPage.current = e + 1;
								refresh();
							}}
						/>
					</DataGrid>
				</div>
				<div style={{ marginTop: 10 }}>
					<h4 style={{ paddingBottom: 5, paddingTop:5}}>Offset Details</h4>
					<DataGrid
						id='customer-contra-offset-details'
						className={isAR ? "contralisting contraoffsetListing ar-listing-datagrid" : "contralisting contraoffsetListing ap-listing-datagrid"}
						ref={offsetGridRef}
						dataSource={offsetForm.Children}
						keyExpr='OffsetDocumentID'
						showBorders={false}
						showRowLines={true}
						showColumnLines={false}
						// onRowUpdating={onRowUpdating}
						// onRowUpdated={() => setOffsetOldValue()}
						columnAutoWidth={true}
						allowColumnReordering={false}
						allowColumnResizing={true}
						// sorting={"none"}
						fixedButton={false}
						height={300}
						width={"auto"}>
						<Column dataField='OffsetDocumentNo' caption='Document No.' allowEditing={false} />
						<Column dataField='DocumentDate' allowEditing={false} calculateSortValue={sortByDate} caption={"Date"} format='dd/MM/yyyy' dataType={"date"} />
						<Column dataField='MainForexAmount' caption='Total Amount' allowEditing={false} visible={true} format='#,##0.00' />
						<Column dataField='OutstandingAmount' caption='Outstanding Amount' allowEditing={false} format='#,##0.00' />
						<Column dataField='OffsetAmount' caption='Offset Amount' format='#,##0.00' />
						<Column dataField='OffsetTypeID' caption='Type' allowEditing={false} allowSorting={false} />
						<Summary>
							<TotalItem
								column='OffsetDocumentNo'
								summaryType='count'
								customizeText={(e) => {
									// console.log(e);
									return "Total: " + e.value + " Doc(s)";
								}}
							/>

							<TotalItem
								column='LocalPaymentAmount'
								summaryType='sum'
								customizeText={(e) => {
									return utils.numberToCurrency(e.value);
								}}
							/>

							<TotalItem
								column='OutstandingAmount'
								summaryType='sum'
								customizeText={(e) => {
									return utils.numberToCurrency(e.value);
								}}
							/>

							<TotalItem
								column='OffsetAmount'
								summaryType='sum'
								customizeText={(e) => {
									return utils.numberToCurrency(e.value);
								}}
							/>
						</Summary>
					</DataGrid>
				</div>

				{displayPopupForm && (
					<ContraForm
						documentNo={documentNo}
						openedBefore={openedBefore}
						onDocumentNoChanged={(e) => {
							setDocumentNo(e.value);
						}}
						onOpenedBefore={(e) => {
							setOpenedBefore(e.value);
						}}
						documentDate={documentDate}
						formType={formType}
						formID={currentEditID}
						closePopup={setCurrentEditID}
					/>
				)}
			</Container>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
