import React, { useEffect, useRef, useState } from 'react';
import Container from '../../../components/container';
import DataGrid, { Column, MasterDetail, Button as ButtonColumn, Editing, Grouping, GroupPanel } from 'devextreme-react/data-grid';
import PathIndicator from '../../../components/path-indicator/PathIndicator';
import { TextBox } from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import DocumentNumberSettingForm from './form';
import utils from '../../../utils/common';
import baseapi from '../../../api/baseapi';

export default function DocumentNumberSetting() {

    const gridRef = useRef(null);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [dataSource, setDataSource] = useState([]);

    const handleAdd = () => {
        setCurrentEditID("new");
    };

    const handleEdit = (e) => {
        const id = e.row.key;
        setCurrentEditID(id);
    };

    const loadData = () => {
        baseapi.httpget("/api/DocumentSettingV2/list").then((response) => {
			const { data } = response;
            const items = data.items;
            setDataSource(items);
		});
    }

    useEffect(() => {
        if(utils.isNullOrEmpty(currentEditID)){
            loadData();
        }
    }, [currentEditID]);

    return <Container>
        <div>
            <div className="sub-container">
                <div className='listing-page-title-container'>
                    <span className='datagrid-customized-title'>
                        <PathIndicator />
                    </span>
                </div>
                <div className='listing-datebox-container'>
                    <TextBox
                        width={"350px"}
                        height={"34px"}
                        className='listing-page-search-box'
                        placeholder='Search'
                        valueChangeEvent='keyup'
                        onValueChanged={(e) => {
                            gridRef.current.instance.searchByText(e.value);
                        }}
                    />

                    <div className="buttons-container">
                        <Button
                            height={"38px"}
                            icon='plus'
                            onClick={(e) => {
                                handleAdd();
                            }}
                            text='New'
                            className={`listing-page-add-btn maintenance-listing-btn`}
                        />
                    </div>
                </div>
            </div>

            <DataGrid
                className={"listing-page-datagrid maintenance-listing-datagrid"}
                dataSource={dataSource}
                ref={gridRef}
                keyExpr={"id"}
                id={'document-number-setting-listing-sub-container'}
            >
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={false} />
                <Column dataField={"Category"} groupIndex={0} />
                <Column dataField={"DocumentTypeCode"} groupIndex={1} caption={"Document Type"}/>
                <Column dataField={"Name"} />
                <Column dataField={"NextNumber"} />
                <Column dataField={"Code"} caption={"Prefix"} />
                <Column dataField={"Format"} />
                <Column dataField={"IsDefault"} caption={"Default"} />
                <Column dataField={"MonthlyRunningNo"} />
                <Column dataField={"Sample"} />
                <Column type='buttons' fixed={true}>
                    <ButtonColumn name='edit' onClick={handleEdit} />
                </Column>
                <Editing useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={false} />
            </DataGrid>

            <DocumentNumberSettingForm 
                formID={currentEditID}
                closePopup={setCurrentEditID}
                apiURL={`/api/DocumentSettingV2`}
                title={"Document Number Setting"}
            />
        </div>
    </Container>

}

