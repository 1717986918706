import React from "react";
import SettingListing from "../../components/listing/SettingListing";
import Container from "../../components/container";

class Project extends React.Component {
	render() {
		return (
			<Container>
				<div id="normal-listing">
					<SettingListing 
						listingTitle='Project' 
						apiURL='/api/Project' 
						listingURL='/api/Project/list' 
						sortColumn={"Code"} 
						defaultSettingTemplate={true} 
						dateFilter={false} 
						storageName={"SettingProjectListing"} 
						className={"maintenance-listing-datagrid"}
					/>
				</div>
			</Container>
		);
	}
}

export default Project;
