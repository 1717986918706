
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import Container from '../container';
import PathIndicator from "../path-indicator/PathIndicator";
import baseapi from "../../api/baseapi";
import { loading, closeLoading, showSuccessMessage, showUnsuccessfulMessage } from "../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { TextBox } from "devextreme-react/text-box";
import CustomizedLookup from "../lookup/Lookup";
import DataGrid, { LoadPanel, Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Pager, Paging, Item as ToolbarItem } from "devextreme-react/data-grid";
import PlanSelectionForm from '../popup-form/PlanSelectionForm';
import './SubscriptionForm.scss';
import utils from '../../utils/common';
import { AuthProvider, useAuth } from '../../contexts/auth';
import UpcomingPlanDetailForm from "../upcoming-subscription-plan/UpcomingPlanDetailForm";
import UpcomingReviewPaymentForm from "../upcoming-subscription-plan/UpcomingReviewPaymentForm";
import UpgradeReviewPaymentForm from "../upgrade-subscription-plan/UpgradeReviewPaymentForm";
import UpcomingOutstandingReviewPaymentForm from "../upcoming-subscription-plan/UpcomingOutstandingReviewPaymentForm";
export default function Subscription() {

    const { user } = useAuth();
    const [currentPlan, setCurrentPlan] = useState({});
    const [upcomingPlan, setUpcomingPlan] = useState({});
    const [upgradePlan, setUpgradePlan] = useState({});
    const [upcomingOutstandingPlan, setUpcomingOutstandingPlan] = useState({});
    const gridRef = useRef(null);
    const [gridDataSource, setGridDataSource] = useState({});
    const [planSelectionVisible, setPlanSelectionVisible] = useState(false);
    const [upcomingPlanDetailFormVisible, setUpcomingPlanDetailFormVisible] = useState(false);
    const [upcomingReviewPaymentFormVisible, setUpcomingReviewPaymentFormVisible] = useState(false);
    const [upgradeReviewPaymentFormVisible, setUpgradeReviewPaymentFormVisible] = useState(false);
    const [upcomingOutstandingReviewPaymentFormVisible, setUpcomingOutstandingReviewPaymentFormVisible] = useState(false);
    const currentToken = localStorage.getItem("Authorization").substring(7);
    const [activeTab, setActiveTab] = useState('upcoming'); // 'upcoming' or 'upgrade'
    const [upcomingPlanPaid, setUpcomingPlanPaid] = useState(null);



    // Fetch data from /api/UserCompany/CompanySubscriptionPlan
    const fetchCompanySubscriptionPlans = async () => {
        try {
            loading(); // Show loading spinner
            const response = await baseapi.httpget('/api/UserCompany/CompanySubscriptionPlan');
            const { CompanyPlans, CurrentPlan, UpcomingPlan, UpgradedPlan, IsUpcomingPaid, UpcomingOutstandingPlan } = response.data; // Destructure response to get CompanyPlans and CurrentPlan


            setGridDataSource(CompanyPlans);

            //set to different plan with different plan status
            setCurrentPlan(CurrentPlan);
            setUpcomingPlan(UpcomingPlan);
            setUpgradePlan(UpgradedPlan);
            setUpcomingPlanPaid(IsUpcomingPaid);
            setUpcomingOutstandingPlan(UpcomingOutstandingPlan);


            closeLoading();
        } catch (error) {
            console.error('Error fetching company subscription plans:', error.message);
            closeLoading();
        }
    };
    useEffect(() => {
        fetchCompanySubscriptionPlans();
    }, []);
    const handlePlanSelectionUpdate = () => {
        fetchCompanySubscriptionPlans();
    };

    const handlePlanSelectionClose = () => {
        setPlanSelectionVisible(false);
    };
    const handleUpcomingPlanDetailClose = () => {
        setUpcomingPlanDetailFormVisible(false);
    };
    const handleUpcomingReviewPaymentClose = () => {
        setUpcomingReviewPaymentFormVisible(false);
    };
    const handleUpgradeReviewPaymentClose = () => {
        setUpgradeReviewPaymentFormVisible(false);
    };
    const handleOutstandingUpcomingPlanDetailClose = () => {
        setUpcomingOutstandingReviewPaymentFormVisible(false);
    };
    const handlePrintPdf = (cell) => {
    
        baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportID?paramReportName=Subscription Payment Receipt (Half)&accessToken=${currentToken}`).then((response) => {
            const { data } = response;
            data.map((items) => {
                confirmReportParameters(items.ID, items.PDFViewerAccessURL, cell.CompanyPlanID);
            });
        });

    };

    const confirmReportParameters = async (reportID, ViewerAccessURL, CompanyPlanID) => {
        var documentViewerLink = ViewerAccessURL;

        try {

            documentViewerLink += `&${'CompanyPlanID'}=${CompanyPlanID}`;

            // Open the document viewer link in a new tab
            window.open(documentViewerLink, "_blank");
        } catch (error) {
            console.error("Error fetching report parameters:", error);
        }
    };


    return <Container>

        <div>
            <div className="listing-container">

                <div className="sub-container">

                    <div style={{ paddingTop: '15px' }}>
                        <span className='datagrid-customized-title'>
                            <PathIndicator />
                        </span>
                    </div>

                    <div className='custom-form-grid-section-55-5-40'>
                        <div>
                            <div className='subscription-custom-form-grid-section-50-50'>
                                <div className='popup-form-item-container2'>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Current Plan </div>
                                        <div className='subscription-plan-description '>
                                            {currentPlan?.PlanName || 'N/A'}
                                        </div>
                                    </div>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Included </div>
                                        <div className='subscription-plan-description '>
                                            {currentPlan?.IncludedUser > 1 ? `${currentPlan.IncludedUser} User (s)` : `${currentPlan?.IncludedUser || 'N/A'} User`}
                                        </div>
                                    </div>

                                </div>


                                <div className='popup-form-item-container2'>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Additional </div>
                                        <div className='subscription-plan-description '>
                                            + {currentPlan?.AdditionalUser > 1 ? `${currentPlan.AdditionalUser} User (s)` : `${currentPlan?.AdditionalUser || 'N/A'} User`}
                                        </div>
                                    </div>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-description'>
                                            <span class="subscription-price-bold">RM {utils.numberToCurrency(currentPlan.PerMonthFee)}</span> <span class="price-month">/ month</span>
                                        </div>
                                        <div className='subscription-plan-title'>Billed every {currentPlan.Duration}</div>

                                    </div>
                                </div>

                            </div>

                            <div className='subscription-custom-form-grid-section-50-50'>

                                <div className='popup-form-item-container2'>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Subscription Start Date </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(currentPlan.SubscriptionStartDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Subscription End Date </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(currentPlan.SubscriptionEndDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>

                                </div>


                                <div className='popup-form-item-container2'>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Last Payment </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(currentPlan.LastPaymentDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title payment'>Next Billing Date </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(currentPlan.NextBillingDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='subscription-custom-form-grid-section-70-30'>
                                <div class="subscription-plan-container">
                                    <div className='price-highlight'>RM {utils.numberToCurrency(currentPlan.TotalPrice)}</div>
                                    <div className='subscription-plan-title  '>
                                        {/* [ RM {utils.numberToCurrency(currentPlan.PlanAmount)} x {currentPlan.Duration} ] */}
                                        {/* {currentPlan.AdditionalUser > 0 && (
                                            <>
                                                &nbsp; + [ RM {utils.numberToCurrency(currentPlan.LicensedUserPrice)} x {currentPlan.AdditionalUser} User{currentPlan.AdditionalUser > 1 ? 's' : ''} x {currentPlan.Duration} ]
                                            </>
                                        )} */}

                                        Total Plan Cost and Licensed User Price For This Plan Period

                                    </div>
                                </div>


                            </div>



                        </div>
                        <div></div>
                        <div className="subscription-change-plan">

                            <div className="manage-plan-section">
                                <div className="plan-header">
                                    <div className="plan-title">Manage Your Plan</div>
                                    <div className="plan-description">Desc Desc Desc Desc Desc</div>
                                </div>
                                <div></div>
                                <div className="plan-actions">
                                    <button
                                        className={`${activeTab === 'upcoming' ? 'payment-background-white-button' : 'upgrade-plan'}`}
                                        onClick={() => setActiveTab('upcoming')}
                                    >
                                        Upcoming
                                    </button>
                                    <button
                                        className={`${activeTab === 'upgrade' ? 'payment-background-white-button' : 'upgrade-plan'}`}
                                        onClick={() => setActiveTab('upgrade')}
                                    >
                                        Upgrade Plan
                                    </button>
                                </div>
                            </div>

                            <hr className="hr-subscription" />

                            {activeTab === 'upcoming' && (
                                <>
                                    <div className="plan-details-section">
                                        <div className="plan-info">
                                            <div className="plan-name"> {upcomingPlan?.PlanName || 'N/A'}</div>
                                            <div className="new-plan">New Plan</div>
                                        </div>
                                        <div className="subscription-start">
                                            New Subscription start on
                                            <div className="subscription-plan-description">
                                                {new Date(upcomingPlan.SubscriptionStartDate).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })}
                                            </div>
                                        </div>
                                        <div className="plan-actions">
                                            <button className="plan-link" onClick={() => setUpcomingPlanDetailFormVisible(true)}>View Details</button>
                                            {!upcomingPlanPaid && (
                                                <button className="plan-button manage-plan" onClick={() => setPlanSelectionVisible(true)}>
                                                    Manage Plan
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <hr className="hr-subscription" />
                                    {/* upcoming pricing section */}
                                    <>
                                        <div className="subscription-pricing-section">
                                            <div>
                                                <div className="price-highlight">
                                                    RM {utils.numberToCurrency(upcomingPlan?.TotalPrice)}
                                                </div>
                                                <div className="subscription-plan-title">
                                                    [ RM {utils.numberToCurrency(upcomingPlan?.PlanAmount)} x {upcomingPlan?.DisplayDuration} ] + [ RM {utils.numberToCurrency(upcomingPlan?.LicensedUserPrice)} x {upcomingPlan?.AdditionalLicensedUser} User(s) x {upcomingPlan?.DisplayDuration} ]
                                                    
                                                </div>
                                            </div>
                                            {!upcomingPlanPaid && (
                                                <div className="plan-make-payment">
                                                    <button
                                                        className="payment-background-white-button"
                                                        onClick={() => setUpcomingReviewPaymentFormVisible(true)}
                                                    >
                                                        Make Payment
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {!upcomingPlanPaid ? (
                                            <div className="subscription-note-containter">
                                                <div className="subscription-note">
                                                    **Please note that your new subscription plan will commence once your current plan expires. To ensure uninterrupted access to the system, please make the payment before the current plan ends.
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="subscription-note-containter">
                                                <div className="subscription-note">
                                                    **You have successfully paid for your upcoming plan. Click "View Details" to see the upcoming plan information.
                                                </div>
                                            </div>
                                        )}
                                    </>

                                </>
                            )}




                            {activeTab === 'upgrade' && (
                                <>
                                    <div className="plan-update-section">
                                        <div className="plan-info">
                                            <div className="plan-name">
                                                {upgradePlan?.PlanName || currentPlan?.PlanName || 'N/A'}
                                            </div>
                                            <div className="new-plan">
                                                {upgradePlan?.PlanName ? 'Updated To' : 'Current Plan'}
                                            </div>
                                        </div>
                                        <div className="subscription-start">
                                            Included
                                            <div className="subscription-plan-description">
                                                {upgradePlan?.IncludedLicensedUser != null
                                                    ? (upgradePlan.IncludedLicensedUser > 1
                                                        ? `${upgradePlan.IncludedLicensedUser} User (s)`
                                                        : `${upgradePlan?.IncludedLicensedUser || 'N/A'} User`)
                                                    : (currentPlan?.IncludedUser > 1
                                                        ? `${currentPlan.IncludedUser} User (s)`
                                                        : `${currentPlan?.IncludedUser || 'N/A'} User`)}
                                            </div>
                                        </div>
                                        <div className="subscription-start">
                                            Additional
                                            <div className="subscription-plan-description">
                                                {upgradePlan?.AdditionalLicensedUser != null
                                                    ? (upgradePlan.AdditionalLicensedUser > 1
                                                        ? `${upgradePlan.AdditionalLicensedUser} User (s)`
                                                        : `${upgradePlan?.AdditionalLicensedUser || 'N/A'} User`)
                                                    : (currentPlan?.AdditionalUser > 1
                                                        ? `${currentPlan.AdditionalUser} User (s)`
                                                        : `${currentPlan?.AdditionalUser || 'N/A'} User`)}
                                            </div>
                                        </div>
                                        <div className="plan-updated-actions">
                                            <button className="plan-button manage-plan" onClick={() => setPlanSelectionVisible(true)}>Manage Plan</button>
                                        </div>
                                    </div>

                                    <hr className="hr-subscription" />

                                    <div className="subscription-pricing-section-outstanding">
                                        <div>
                                            Outstanding
                                            <div className="price-highlight-red">RM {utils.numberToCurrency(upgradePlan?.OutstandingAmount ?? 0)}</div>
                                            {(upgradePlan?.OutstandingAmount ?? 0) === 0 ? (
                                                <div className="subscription-plan-title">
                                                    RM 0.00
                                                </div>
                                            ) : (
                                                <div className="subscription-plan-title">
                                                    [ RM {utils.numberToCurrency(upgradePlan?.PlanAmount)} x {upgradePlan?.DisplayDuration} ] + [ RM {upgradePlan?.LicensedUserPrice} x {upgradePlan?.OutstandingAdditionalLicensedUser} User(s) x {upgradePlan?.DisplayDuration} ]

                                                    {(upgradePlan?.LicensedUserPrice - (currentPlan.LicensedUserPrice || 0)) > 0 && ( <> + [ Revised Licensed User Rate : RM {(upgradePlan?.LicensedUserPrice - (currentPlan?.LicensedUserPrice || 0)) || 0} x {currentPlan.AdditionalUser || 0} User(s) x {upgradePlan?.DisplayDuration} ] </>) }
                                                    {/* { upgradePlan?.UpcomingPaidDifferencePlanTotalPrice > 0 && ( <> + [ Paid Upcoming Plan Revised Plan Rate : RM {utils.numberToCurrency(upgradePlan?.UpcomingPaidDifferencePlanPrice)} x {upgradePlan?.UpcomingPaidDuration} ] </>)}
                                                    { upgradePlan?.UpcomingPaidDifferenceLicensedUserTotalPrice > 0 && ( <> + [ Paid Upcoming Plan Revised Licensed User Rate : RM  {upgradePlan?.UpcomingPaidDifferenceLicensedUser} seat x {upgradePlan?.UpcomingPaidDuration} x RM {utils.numberToCurrency(upgradePlan?.UpcomingPaidDifferenceLicensedUserPrice)} ] </>)}
                                                    { upgradePlan?.UpcomingHaveNotPaidLicensedUserTotalPrice > 0 && ( <> + [ Upcoming Plan Revised Licensed User Rate : RM    {upgradePlan?.UpcomingHaveNotPaidLicensedUser} seat x {upgradePlan?.UpcomingPaidDuration} x RM {utils.numberToCurrency(upgradePlan?.LicensedUserPrice)} ] </>)} */}
                                                </div>
                                            )}
                                        </div>
                                        {(upgradePlan?.OutstandingAmount ?? 0) > 0 && (
                                            <div className="plan-make-payment">
                                                <button
                                                    className="payment-background-white-button"
                                                    onClick={() => setUpgradeReviewPaymentFormVisible(true)}
                                                >
                                                    Make Payment
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {(upgradePlan?.OutstandingAmount ?? 0) > 0 ? (
                                        <div className="subscription-note-containter">
                                            <div className="subscription-note">
                                                **To activate the upgraded plan, please complete the payment for the outstanding amount now.
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="subscription-note-containter">
                                            <div className="subscription-note">
                                                **There are currently no pending payments for your upgrade.
                                            </div>
                                        </div>
                                    )}


                                </>
                            )}
                        </div>
                    </div>
                    <div className="paymentHistory">
                        Payment History
                    </div>

                    <div style={{ width: '100%' }}>

                        <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>

                            <DataGrid
                                id="id"
                                ref={gridRef}
                                dataSource={gridDataSource}
                                showBorders={false}
                                width={"100%"}
                                sorting={{ mode: "multiple" }}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                rowHeight={50}
                                hoverStateEnabled={true}
                                columnResizingMode='widget'
                                rowAlternationEnabled={false}
                                columnAutoWidth={false}
                                errorRowEnabled={false}
                                className={"listing-page-datagrid"}
                                loadPanel={{ enabled: true }}
                                height={300}
                                focusedRowEnabled={false}

                            >

                                <Column dataField='SubscriptionPlan' caption={"Subscription Plan"} editorOptions={{ readOnly: true }} width={200} />
                                <Column dataField='IncludedUser' caption={"Included"} editorOptions={{ readOnly: true }} width={150} />
                                <Column dataField='AdditionalUser' caption="Additional" editorOptions={{ disabled: true }} width={150} />
                                <Column dataField='TotalPrice' caption="Total Price (RM)" dataType='number' editorOptions={{ disabled: true }} width={200} format={{ type: 'fixedPoint', precision: 2 }} />
                                <Column
                                    dataField='PaymentStatusDescription' // Use the new string field
                                    caption="Payment Status"
                                    cellRender={(cellData) => {
                                        const displayText = cellData.value; // String value directly from backend
                                        const textColor = displayText === 'Successful' ? 'green' :
                                            (displayText === 'Failed' || displayText === 'Declined' || displayText === 'Error') ? 'red' :
                                                (displayText === 'Pending' || displayText === 'In Progress' || displayText === 'On Hold') ? 'orange' : 'black';

                                        return (
                                            <span style={{ color: textColor }}>
                                                {displayText}
                                            </span>
                                        );
                                    }}
                                    width={200}
                                />
                                <Column dataField='InvoiceNo' caption="InvoiceNo"  editorOptions={{ disabled: true }} width={200} />
                                <Column dataField='PaymentDate' caption="Payment Date" dataType='date' editorOptions={{ disabled: true }} format="dd/MM/yyyy" width={200} />
                                <Column
                                    dataField='Action'
                                    caption="Action"
                                    alignment="center"
                                    cellRender={(cellData) => {
                                        const isPaymentSuccessful = cellData.data.PaymentStatus; // Check PaymentStatus for the current row
                                        return isPaymentSuccessful ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <button
                                                    onClick={() => handlePrintPdf(cellData.data)} // Pass row data to handlePrintPdf
                                                    style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                                                >
                                                    <img src="/images/downloadicon.svg" alt="Download" style={{ width: '24px', height: '20px' }} /> {/* Render the SVG */}
                                                </button>
                                            </div>
                                        ) : null; // Return null if payment is unsuccessful, hiding the button
                                    }}
                                    width={200}
                                />

                                <Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />

                            </DataGrid>


                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PlanSelectionForm visible={planSelectionVisible} onClose={handlePlanSelectionClose} activeTab={activeTab} onUpdate={handlePlanSelectionUpdate} />
        <UpcomingPlanDetailForm visible={upcomingPlanDetailFormVisible} onClose={handleUpcomingPlanDetailClose} activeTab={activeTab} onUpdate={handlePlanSelectionUpdate} />
        <UpcomingReviewPaymentForm visible={upcomingReviewPaymentFormVisible} onClose={handleUpcomingReviewPaymentClose} activeTab={activeTab} onUpdate={handlePlanSelectionUpdate} />
        <UpgradeReviewPaymentForm visible={upgradeReviewPaymentFormVisible} onClose={handleUpgradeReviewPaymentClose} activeTab={activeTab} onUpdate={handlePlanSelectionUpdate} />
        <UpcomingOutstandingReviewPaymentForm visible={upcomingOutstandingReviewPaymentFormVisible} onClose={handleOutstandingUpcomingPlanDetailClose} activeTab={activeTab} onUpdate={handlePlanSelectionUpdate} />
    </Container>



}