import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class UOM extends React.Component {
    // render() {
    //     return <CommonTable title="UOM" url="/api/UOM" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="UOM"
                    apiURL="/api/UOM"
                    listingURL="/api/UOM/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingUOMListing"}
                    className={"maintenance-listing-datagrid"}
                />
            </div>
        </Container>
    }
}

export default UOM;