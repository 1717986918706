import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import { useNavigate } from "react-router-dom";
import baseapi from "../../api/baseapi";
import utils from "../../utils/common";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import { NumberBox } from "devextreme-react/number-box";
import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import { loading, closeLoading } from "../../utils/common";
import CustomizedLookup from "../../components/lookup/Lookup";
import CustomizedGridLookup from "../../components/lookup/GridLookup";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";
import { apdebitNoteReportNames } from "../../utils/lookupstore";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import ChildrenDatagrid from "../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../utils/module-calculation";
import Tabs from 'devextreme-react/tabs';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { Gallery } from 'devextreme-react/gallery';
import TreeList from "devextreme-react/tree-list";
import Form, { SimpleItem, Label, GroupItem } from "devextreme-react/form";
import TextArea from "devextreme-react/text-area";
import RadioGroup from "devextreme-react/radio-group";
import CustomStore from "devextreme/data/custom_store";
import { debounce } from "lodash";
export default function PlanAccessControlFrom(props) {

	const formID = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});
	const [displayError, setDisplayError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [messageType, setMessageType] = useState("");
	const [listingDataSource, setListingDataSource] = useState(null);
	const tempForm = useRef(null);
	const [startValidation, setStartValidation] = useState(0);
	const [checkboxValues, setCheckboxValues] = useState({});
	const [directAccessClicked, setDirectAccessClicked] = useState(false);
	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);


	const handleCheckboxChange = useCallback((rowId, column, value) => {
		setCheckboxValues(prevValues => {
			const newValues = { ...prevValues };
	
			// Ensure columns is an array, even if a single column is provided
			const columns = Array.isArray(column) ? column : [column];
	
			// Helper function to update row values
			const updateRow = (id, columns, val) => {
				const updates = columns.reduce((acc, col) => {
					acc[col] = val;
					return acc;
				}, {});
	
				newValues[id] = {
					...newValues[id],
					...updates
				};
			};
	
			// Update all children of the current row
			const updateChildren = (id, columns, val) => {
				const children = Object.keys(newValues).filter(key => newValues[key].ParentID === id);
				if (children.length > 0) {
					children.forEach(childId => {
						updateRow(childId, columns, val);
						updateChildren(childId, columns, val); // Recursively update child rows
					});
				}
			};
	
			// Update all parent rows (if applicable)
			const updateParents = (id, columns, val) => {
				const parentId = newValues[id].ParentID;
				if (parentId) {
					updateRow(parentId, columns, val);
					updateParents(parentId, columns, val); // Recursively update parent rows
				}
			};
	
			// Only update the row if the new value is different
			if (newValues[rowId][column] !== value) {
				// Update the current row
				updateRow(rowId, columns, value);
	
				// Check if the current row has children, and update them
				const hasChildren = Object.keys(newValues).some(key => newValues[key].ParentID === rowId);
				if (hasChildren) {
					updateChildren(rowId, columns, value);
				}
	
				// If the value is true (checked), update the parent rows; otherwise, check for unchecking condition
				if (value) {
					updateParents(rowId, columns, value);
				}
			}
	
			return newValues;
		});
	}, [setCheckboxValues]);
	



	function valueOnChange(e, receivedDataField = null) {

		
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;
				tempForm.current = copied;
				setFormValue(tempForm.current);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit({ stay }) {
		loading("Saving Subscription Plan...");

		const submittedForm = {
			Parent: formValue,
			CheckboxValues: Object.values(checkboxValues)
		};

		console.log(submittedForm);
		baseapi.httppost(
			utils.extendUrlVar("/api/PlanAccessControl/save", {
				id: formID.current || "",
				del: false,
			}),
			submittedForm
		)
			.then((response) => {
				const { data } = response;

				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Warning",
					action: data.action
				});
			});
	}

	function onInitialized(e) {

		if (formID.current === "new") loading("Loading New Plan...");
		else loading("Loading Existing Plan...");

		baseapi.httpget("/api/PlanAccessControl/Get?id=" + (formID.current || "new")).then((response) => {
			const { data } = response;
			if (formID.current === "new") {
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
			} else {

				setFormValue(data.model.Parent);
				tempForm.current = data.model.Parent;
			}

			closeLoading();
			formRead.current = true;
		});

		baseapi
			.httpget(`/api/PlanAccessControl/PlanAccessControlList`, { id: (formID.current || "new") })
			.then((response) => {
				const { data } = response;

				console.log("list", data);
				setListingDataSource(data);

				const initialValues = data.reduce((acc, item) => {
					acc[item.id] = {
						ParentID: item.ParentID,
						MenuID: item.MenuID,
						SystemAccess: item.SystemAccess
					};
					return acc;
				}, {});
				setCheckboxValues(initialValues);
				closeLoading();
			})
			.catch((error) => {
				console.log(error);
				closeLoading();
			});
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
		setCheckboxValues({});
		setListingDataSource(null);
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				disableButtons={props.lockedForm === true}
				headerClassName={"maintainance-module-form-title-bg popup-form-title"}
				creditChecking={false}
				title={"Subscription Plan"}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized(); }}
				onPasteForm={(e) => { tempForm.current = e.Parent; setFormValue(e.Parent); }}
				copyStorage={"PlanCopiedData"}
				formData={formValue}
				isEditing={isEditing}
				onValidating={setStartValidation}
				startValidation={startValidation}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Active"]}
							elementAttr={{ dataField: "Active" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"25px"}
							height={"50px"}
							iconSize={"18px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>
						<span className='popup-header-cancel-text' style={{ color: '#808080' }}>Active</span>
					</span>
				}
			>
				<hr className='maintenance-popup-form-hr' />
				<div className='popup-form-main-content'>
					<div className='maintenance-module-parent-section'>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>Subscription Plan: </div>
								<div className='popup-group-form-input'>
									<AsyncTextBox
										value={formValue["Code"]}
										elementAttr={{ dataField: "Code" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={formID.current !== "new" || props.lockedForm === true}
										startValidation={startValidation !== 0}
										asyncURL={"api/PlanAccessControl/CheckDuplicatePlan"}
										requiredMessage={"Plan is required!"}
										asyncMessage={"This Plan is already taken!"}
									/>
								</div>
							</div>
						
						</div>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Description: </div>
							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Name"]}
									elementAttr={{ dataField: "Name" }}
									onValueChanged={(e) => valueOnChange(e)}
									alignment='left'
									width={"auto"}
								>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>
					</div>
					<TreeList
						id='user-access-treelist'
						className='listing-page-treelist treelist-margin-left'
						dataSource={listingDataSource}
						allowColumnReordering={true}
						allowColumnResizing={true}
						columnAutoWidth={false}
						columnResizingMode='nextColumn'
						showRowLines={true}
						showBorders={false}
						autoExpandAll={true}
						height={"100%"}
						width={"100%"}
						keyExpr='id'
						parentIdExpr='ParentID'
						rootValue={"t2B0SsAZelE%3d"}
						loadPanel={{ enabled: true }}
						hoverStateEnabled={true}
					>
						<Column dataField={"Name"} caption={"Name"} />

						<Column
							dataField={"SystemAccess"}
							caption={"Plan Access Control"}
							alignment={"center"}
							cellRender={data => (
								<CheckBox
									value={checkboxValues[data.data.id]?.SystemAccess || false}
									//disabled={tempForm.current?.isSystemDefined}
									onValueChanged={e => {
										handleCheckboxChange(data.data.id, 'SystemAccess', e.value);
									}}
								/>
							)}
						/>

						<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} useNative={true} />
					</TreeList>
				</div>
			</PopupForm>
		</div>
	);
}
