import Listing from "../../../components/listing/newListing";
import { Column } from "devextreme-react/data-grid";
import Container from "../../../components/container";
import JournalEntryForm from "./form";
import { useState } from "react";

export default function JournalEntryListing() {
	const apiURL = `/api/GLJournal`;
	const [currentEditID, setCurrentEditID] = useState(null);

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
	}

    return(
        <Container>
		<div id="normal-listing">
            <Listing 
				apiURL={apiURL} 
				listingURL='/api/GLJournal/list' 
				className={"gl-listing-datagrid"} 
				sortColumn={"DocumentDate"}
                sortOrder={"desc"}
				listingTitle={"Journal Entry"} 
				storageName={"GLJournalListing"} 
				onAddClick={setCurrentEditID}
				handleEdit={handleEdit}
			>
				<Column dataField={"DocumentDate"} caption={"Date"} format='dd/MM/yyyy' dataType={"date"} />
				<Column dataField={"DocumentNo"} caption={"Journal No"} />
				<Column dataField={"Description"} />
				<Column dataField={"TotalLocalDebit"} caption={"Debit"} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField={"TotalLocalCredit"} caption={"Credit"} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField={"TotalDebitIncTax"} caption={"Debit Incl. Tax"} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField={"TotalCreditIncTax"} caption={"Credit Incl. Tax"} format={{ type: "fixedPoint", precision: 2 }} />
			</Listing>
            </div>

			<JournalEntryForm 
				formID={currentEditID} 
				closePopup={setCurrentEditID}
				title={"Journal Entry"} 
				apiURL={apiURL} 
			/>
		</Container>
	);
}
