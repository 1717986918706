import { useEffect, useRef, useState, useCallback } from "react";
import utils from "../../../utils/common";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { Button } from 'devextreme-react/button';
import { TextArea } from "devextreme-react";
//import { Column, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem, Sorting, LoadPanel, DataGridTypes } from "devextreme-react/data-grid";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation, processDecimalRowUpData } from "../../../utils/module-calculation";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { uomOptions } from "../../../utils/lookupstore";
import StockFilterForm from "../../../components/popup-form/StockFilterForm";
export default function StockTakeFrom(props) {

	const tempForm = useRef(null);
	const formID = useRef(null);
	const childrenGridRef = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [shelfList, setShelfList] = useState([]);
	const [forexList, setForexList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState({});
	const [taxCodeList, setTaxCodeList] = useState([]);
	const [uomList, setUomList] = useState([]);
	const [stockList, setStockList] = useState([]);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const forexLookupRef = useRef(null);
	const [gridHeight, setGridHeight] = useState('auto');
	const [defaultValue, setDefaultValue] = useState({});
	const [rowCount, setRowCount] = useState(utils.childrenGridLength(childrenGridRef));
	const stockFilterFormRef = useRef(null);

	const showStockFilter = () => {
		if (stockFilterFormRef.current) {
			stockFilterFormRef.current.setVisible(true);
		}
	};
	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			}
			else {
				setIsEditing(false);
			}

			if (utils.isEmptyArray(uomList)) {
				uomOptions.getAll().then((list) => {
					setUomList(list.data);
				});
			}
		}
	}, [props.formID]);

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Stock Take...");
		else loading("Loading Existing Stock Take...");

		baseapi.httpget("/api/StockTake/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if (!utils.isNullOrEmpty(data.defaultValues)) {
				setDefaultValue(data.defaultValues);
			}

			if (formID.current === "new") {
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
				utils.childrenGridAddRow(childrenGridRef); // Add row for the first grid
			}
			else {

				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const onInitNew = () => {
		setRowCount(prevRowCount => prevRowCount + 1);
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			LocationID: utils.popupFormGetDefaultValues(formRef)["LocationID"],
			LocationCode: utils.popupFormGetDefaultValues(formRef)["LocationCode"],
			NettAmount: 0,
			NettLocalAmount: 0,
			DetailLN: 0,
			Point: 0,
			Quantity: 0,
			UnitCost: 0

		};
	};

	const onRowUpdating = (e) => {
		e.newData = processDecimalRowUpData(e.newData, utils.popupFormGetDefaultValues(formRef)["QuantityFormat"], utils.popupFormGetDefaultValues(formRef)["CostDecimalFormat"], utils.popupFormGetDefaultValues(formRef)["UnitPriceFormat"], formRef);
	};
	function calculateTotal(gridData) {
		return gridData.reduce((acc, row) => acc + (row.Quantity * row.UnitCost), 0);
	}
	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		var totalLocal = 0;

		if (Array.isArray(childrenDetails)) {
			for (var i = 0; i < childrenDetails.length; i++) {
				const row = childrenDetails[i];

				//Reset the line
				if (!utils.isNullOrEmpty(row["Line"])) {
					row["Line"] = i + 1;
				}

				if (!utils.isNullOrEmpty(row["Quantity"])) {
					totalLocal = utils.add(totalLocal, row["Quantity"] * row["UnitCost"]);
					formValue["MainLocalAmount"] = totalLocal;
				}
			}
		}
		if (totalLocal != 0) {
			setFormValue(prevState => {
				return { ...prevState, MainLocalAmount: totalLocal };
			});
		}

	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;

		const dataSource = e.dataSource;
		currentRecords[recordIndex][columnName] = e.value;
		// Get the current row's StockID and LocationID
		const stockID = currentRecords[recordIndex]["StockID"];
		const locationID = currentRecords[recordIndex]["LocationID"]; // Assuming tempForm contains the LocationID
		if (columnName === "StockID") {
			currentRecords[recordIndex]["StockCode"] = selectedItem?.code;
			currentRecords[recordIndex]["Description"] = selectedItem?.name;
			currentRecords[recordIndex]["Description2"] = selectedItem?.description2;
			currentRecords[recordIndex]["MoreDescription"] = selectedItem?.moreDescription;
			currentRecords[recordIndex]["UOMID"] = selectedItem?.uomID;
			currentRecords[recordIndex]["UOM"] = selectedItem?.uomCode;
			baseapi.httpget(`/api/Utilities/GetStockTotalQuantity?StockID=${e.value}`)
				.then((response) => {
					if (response.status === 200) {
						const stockData = response.data.length > 0 ? response.data[0] : { unitCost: 0.00 }; // Check if data is empty and set unitCost to 0 if it is
						currentRecords[recordIndex]["UnitCost"] = stockData.unitCost;
					} else {
						console.error('stock not found');
					}
				});
			// Make the API call to get the quantity on hand
			baseapi.httpget(`/api/Utilities/GetStockShelf?StockID=${stockID}&LocationID=${locationID}&ShelfID=${null}`)
				.then((locationResponse) => {
					if (locationResponse.status === 200) {
						const stockData = locationResponse.data.length > 0 ? locationResponse.data[0] : { qtyOnHand: 0 }; // Check if data is empty and set qtyOnHand to 0 if it is
						currentRecords[recordIndex]["QuantityOnHand"] = stockData.qtyOnHand;
					} else {
						console.error('Default location not found');
						// Handle the case where there is no default location
					}
				});
			const copiedArr = [...stockList];
			if (!utils.isInArray(stockList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setStockList(copiedArr);


		} else if (columnName === "TaxCodeID") {
			currentRecords[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			const copiedArr = [...taxCodeList];
			if (!utils.isInArray(taxCodeList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setTaxCodeList(copiedArr);
		} else if (columnName === "ProjectID") {
			currentRecords[recordIndex]["ProjectCode"] = selectedItem?.code;
		} else if (columnName === "ShelfID") {
			currentRecords[recordIndex]["ShelfCode"] = selectedItem?.code;


			const shelfID = currentRecords[recordIndex]["ShelfID"];
			const shelfIDParam = shelfID ? shelfID : null;
			// Make the API call to get the quantity on hand
			baseapi.httpget(`/api/Utilities/GetStockShelf?StockID=${stockID}&LocationID=${locationID}&ShelfID=${shelfIDParam}`)
				.then((locationResponse) => {
					if (locationResponse.status === 200) {
						const stockData = locationResponse.data.length > 0 ? locationResponse.data[0] : { qtyOnHand: 0 }; // Check if data is empty and set qtyOnHand to 0 if it is
						currentRecords[recordIndex]["QuantityOnHand"] = stockData.qtyOnHand;
					} else {
						console.error('Default location not found');
						// Handle the case where there is no default location
					}
				});
		} else if (columnName === "LocationID") {
			currentRecords[recordIndex]["LocationCode"] = selectedItem?.code;
			// Make the API call to get the quantity on hand

			const shelfID = currentRecords[recordIndex]["ShelfID"];
			baseapi.httpget(`/api/Utilities/GetStockShelf?StockID=${stockID}&LocationID=${e.value}&ShelfID=${shelfID}`)
				.then((locationResponse) => {
					if (locationResponse.status === 200) {
						const stockData = locationResponse.data.length > 0 ? locationResponse.data[0] : { qtyOnHand: 0 }; // Check if data is empty and set qtyOnHand to 0 if it is
						currentRecords[recordIndex]["QuantityOnHand"] = stockData.qtyOnHand;
					} else {
						console.error('Default location not found');
						// Handle the case where there is no default location
					}
				});

		}
		e["key"] = key;
		e["data"] = currentRecords[recordIndex];
		onRowUpdated(e, currentRecords);
	};


	function valueOnChange(e, TakeDataField = null) {
		try {
			const dataField = TakeDataField !== null ? TakeDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "DocumentNo" && formRead.current) {
					copied["DocumentNo"] = e.value.toUpperCase() ?? "";
					tempForm.current = copied;
					setFormValue(tempForm.current);

				} else if (dataField === "ForexID" && formRead.current) {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;

							tempForm.current = copied;
							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				}


			}
		} catch (err) {
			console.log(err);
		}
	}



	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setGridHeight('auto');
		setFormValue(tempForm.current);
	};

	const customizedValidation = () => {
		if (utils.childrenGridLength(childrenGridRef) === 0) {
			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "Data cannot be empty when submitting!",
				type: "Warning",
			});
			return false;
		}
	};

	function handleSubmit(e) {
		if (!utils.isNullOrEmpty(e.validData)) {
			const validData = e.validData;

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};

			loading("Saving Stock Take...");

			baseapi.httppost(
				utils.extendUrlVar("/api/StockTake/save", {
					id: formID.current || "",
					del: false,
				}),
				submittedForm
			)
				.then((response) => {
					const { data } = response;
					utils.popupFormSuccessfulSubmit(formRef, data);
					if (data.status) {
						//setFormValue({})
					}
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: data.message,
						type: data.status ? "Success" : "Danger",
						action: data.action
					});
				});
		}

	}

	useEffect(() => {
		const newHeight = rowCount > 3 ? 257 : 'auto';
		setGridHeight(newHeight);
	}, [rowCount]);
	function calculateSubTotal(rowData) {

		const quantity = parseFloat(rowData.Quantity);
		const unitCost = parseFloat(rowData.UnitCost);
		if (isNaN(quantity) || isNaN(unitCost)) {
			return 0;
		}
		const subTotal = utils.multiply(rowData.Quantity, rowData.UnitCost);
		return subTotal;
	}


	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return <div><PopupForm
		ref={formRef}
		childrenGridRef={childrenGridRef}
		onClosing={clearForm}
		fullScreen={false}
		width={"95%"}
		height={"90%"}
		disableButtons={props.lockedForm === true}
		headerClassName={"inventory-module-form-title-bg popup-form-title"}
		creditChecking={false}
		title={"Stock Take"}
		apiURL={"/api/StockTake"}
		onShown={onInitialized}
		onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
		customizedValidation={customizedValidation}
		requiredColumns={{
			Children: ["StockID", "LocationID", "ProjectID"]
		}}
		filterRules={{
			Children: [
				{ name: "StockID", rules: [{ name: "Required" }] },
				{ name: "Description", rules: [{ name: "Required" }] },
				{ name: "Description2", rules: [{ name: "Required" }] },
				{ name: "Quantity", rules: [{ name: "Min", value: 1 }] },
			]
		}}
		copyFields={{
			Parent: formValue,
			Children: utils.childrenGridGetSource(childrenGridRef)
		}}
		onPasteForm={(e) => {
			tempForm.current = e.Parent;
			setFormValue(e.Parent);
			onRowUpdated({ key: null }, e.Children);
		}}
		copyStorage={"StockTakeCopiedData"}
		formData={formValue}
		isEditing={isEditing}
		onValidating={setStartValidation}
		startValidation={startValidation}
		onSaving={handleSubmit}
		cancelDocumentCustomization={
			<span className='popup-form-header-cancel'>
				<CheckBox
					value={formValue["Cancelled"]}
					elementAttr={{ dataField: "Cancelled" }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					alignment='left'
					width={"12px"}
					height={"12px"}
					iconSize={"12px"}
					readOnly={props.lockedForm === true}
					className='popup-header-cancel-input'
				/>

				<span className='popup-header-cancel-text'>Cancelled</span>
			</span>
		}
	>
		<div className='popup-form-main-content'>
			<div className='inventory-module-parent-section custom-form-grid-section-60-40' style={{ marginBottom: '20px' }}>
				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label next-doc-label'>Stock Take No </div>
						<div className='popup-group-form-input'>
							<div className='next-doc-display'>Next Doc: {formValue["NextDocNo"]}</div>
							<AsyncTextBox
								value={formValue["DocumentNo"]}
								elementAttr={{ dataField: "DocumentNo" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								placeholder={"<NEW>"}
								disabled={formID.current !== "new" || props.lockedForm === true}
								asyncURL={"api/StockTake/CheckDuplicateDocNo"}
								asyncMessage={"Stock Take No is already taken!"}
							/>
						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Date </div>
						<div className='popup-group-form-input'>
							<DateBox
								value={formValue["DocumentDate"]}
								elementAttr={{ dataField: "DocumentDate" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								readOnly={props.lockedForm === true}
							>
								<Validator>
									{startValidation !== 0 && <ValidatorRequired message={"Date is required!"} />}
								</Validator>
							</DateBox>
						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Reference No </div>
						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["RefNo"]}
								elementAttr={{ dataField: "RefNo" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								readOnly={props.lockedForm === true}

							>
							</TextBox>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label '>Issue By </div>
						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["IssueBy"]}
								elementAttr={{ dataField: "IssueBy" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								readOnly={true}
							/>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Forex </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={forexLookupRef}
								className={"ar-listing-datagrid"}
								dataSource={forexList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["ForexID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ForexID");
								}}
								required={true}
								requiredMessage={"Forex is required!"}

								dataSourceURL={"api/Utilities/GetForexs"}
								onDataSourceChanged={setForexList}
								preread={true}>
								<Column dataField='code'></Column>
								<Column dataField='name'></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Local Rate </div>

						<div className='popup-group-form-input'>
							<NumberBox
								value={formValue["LocalRate"]}
								elementAttr={{ dataField: "LocalRate" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								format={ utils.getDecimalFormat(defaultValue["LocalRateFixCheck"], defaultValue["LocalRateFormat"]) }
								readOnly={formValue["ForexID"] === defaultValue["ForexID"]}>
								<Validator>{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
							</NumberBox>
						</div>
					</div>
				</div>

				<div>
					<br />
					<div className='popup-group-form-item group-form-item-align-top'>
						<div className='popup-group-form-label next-doc-label'>Remarks </div>
						<div className='popup-group-form-input'>
							<TextArea
								value={formValue["Remarks"]}
								elementAttr={{ dataField: "Remarks" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								height={116}
								readOnly={props.lockedForm === true}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='children-datagrid-container inventory-module-children-datagrid' style={{ padding: "0 0" }}>


				<ChildrenDatagrid
					ref={childrenGridRef}
					name={"Children"}
					defaultColumnValues={onInitNew}
					lookupFields={["StockID"]}
					keyExpr='ID'
					showBorders={true}
					loadPanel={false}
					allowColumnReordering={true}
					allowColumnResizing={true}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowRemoved={onRowUpdated}
					//readOnly={props.lockedForm === true}
					gridLookupOpen={setGridLookupOpen}
					storageName={"StockTakeChildrenGrid"}
					onParentValueChanged={valueOnChange}
					defaultToolbar={false}
					paging={{ enabled: true, defaultPageSize: 20 }}
					pager={{
						showPageSizeSelector: true,
						allowedPageSizes: [10, 20, 50, 100],
						showInfo: true
					}}
				>

					<Toolbar>

						<ToolbarItem
							location='before'
							widget={"dxButton"}
							options={{
								icon: "filter",
								rtlEnabled: "true",
								text: "Add Stock By Filter",
								onClick: () => {
									showStockFilter()
								}
							}}
						/>
						{/* <hr className='inventory-popup-form-hr' style={{ width: '39.4%' }} /> */}
						<ToolbarItem
							widget={"dxButton"}
							options={{
								icon: "add",
								onClick: () => {
									if (childrenGridRef.current) {
										childrenGridRef.current.addRow();
									}
								}
							}}
						/>
					</Toolbar>
					<Column dataField='Line' dataType='number' defaultColumnIndex={0} />

					<Column
						visible={true}
						dataField='StockID'
						dataType='string'
						caption='Stock Code'
						cellRender={(e) => {
							const data = e.data;
							return data.StockCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								dataSourceURL={"/api/Utilities/GetStock"}
								opened={gridLookupOpen}
								className={"workshop-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}

							>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>

					<Column dataField='Description' />

					<Column dataField='Description2' width={"160px"} />

					<Column visible={true} dataField='MoreDescription' dataType='string' isHtmlEditor={true} />
					<Column
						visible={true}
						dataField='ProjectID'
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							return data.ProjectCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"ar-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
								dataSourceURL={"/api/Utilities/GetProjects"}
							>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}
						editorOptions={{ readOnly: true }}></Column>
					<Column
						visible={true}
						dataField='LocationID'
						caption="Location"
						width={"80px"}
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							return data.LocationCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "LocationID")}
								dataSourceURL={"api/Utilities/GetLocations"}
								onDataSourceChanged={setLocationList}

								sourceList={locationList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>


					<Column
						visible={true}
						dataField='ShelfID'
						dataType='string'
						width={"75px"}
						caption="Bin/Shelf"
						cellRender={(e) => {
							const data = e.data;
							return data.ShelfCode;
						}}

						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "ShelfID")}
								dataSourceURL={"api/Utilities/GetShelf"}
								onDataSourceChanged={setShelfList}
								sourceList={shelfList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>


					<Column visible={true} dataField='UOM' editorOptions={{ readOnly: true }} width="50px" />
					<Column visible={true} dataField='QuantityOnHand' caption="Qty On Hand" dataType='number' width="100px" editorOptions={{ readOnly: true }} allowEditing={false} format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />
					<Column visible={true} dataField='Quantity' dataType='number' caption="Physical Qty" width="100px" format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />


					<Column
						visible={true}
						dataField='DiffQty'
						dataType='number'
						allowEditing={false}
						format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])}
						calculateCellValue={(rowData) => {
							const quantity = Number(rowData.Quantity);
							const quantityOnHand = Number(rowData.QuantityOnHand);

							// Check if Quantity and QuantityOnHand are valid numbers
							if (!isNaN(quantity) && !isNaN(quantityOnHand)) {
								if (quantity == 0) {
									return 0;
								}
								if (quantityOnHand >= 0) {
									return utils.roundUp(quantity - quantityOnHand, defaultValue["QuantityFormat"]);
								} else {
									return utils.roundUp(quantity + Math.abs(quantityOnHand), defaultValue["QuantityFormat"]);
								}
							}
							return 0;
						}}
						width="70px"

					/>
					<Column visible={false} dataField='ItemDiscount' dataType='number' />

					<Column visible={true} dataField='UnitCost' dataType='number' format={utils.getDecimalFormat(defaultValue["CostDecimalFixCheck"], defaultValue["CostDecimalFormat"])} width="90px" />

					<Column
						dataField='SubTotal'
						caption='Sub Total'
						dataType='number'
						format={{ type: "fixedPoint", precision: 2 }}
						editorOptions={{ readOnly: true }}
						allowEditing={false}
						calculateCellValue={calculateSubTotal}
						width="74px"
					/>

					<Column visible={true} dataField='Remarks' />

					<Column visible={false} dataField='NettPrice' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />


					<Column visible={false} dataField='TaxRate' dataType='number' editorOptions={{ readOnly: true }} />

					<Column visible={false} dataField='TaxInclusive' dataType='boolean' />

					<Column visible={false} dataField='ForexTaxable' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

					<Column visible={false} dataField='ForexTaxAmount' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='ForexTaxablePlusTax' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

					<Column
						visible={false}
						dataField='TaxCodeID'
						width={150}
						cellRender={(e) => {
							const data = e.data;
							var result = taxCodeList.find((c) => c.id === data.TaxCodeID)?.code;
							result = data.TaxCodeID === null ? null : result;
							return result === undefined ? data.TaxCode : result;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
								dataSourceURL={"api/Utilities/GetGSTRate"}
								onDataSourceChanged={setTaxCodeList}
								sourceList={taxCodeList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>

					<Column visible={false} dataField='PartNo' />

					<Column visible={false} dataField='BrandCode' />

					<Column visible={false} dataField='LinePrint' />

					<Column visible={false} dataField='PrintPrice' dataType='boolean' />

					<Column visible={false} dataField='Confirmation' dataType='boolean' />

					<Column visible={false} dataField='Show' dataType='boolean' />

					<Column visible={false} dataField='SalesType' />

					<Column visible={false} dataField='PriceCheckID' />

					<Column visible={false} dataField='SalesCode' />

					<Column visible={false} dataField='OrderType' />

					<Column visible={false} dataField='BOM' dataType='boolean' />

					<Column visible={false} dataField='ProductionNo' />

					<Column visible={false} dataField='Details' dataType='boolean' />



					<Column visible={false} dataField='ModelCode' />


					<Column visible={false} dataField='SerialNo' />

					<Column visible={false} dataField='Project' />

					<Column visible={false} dataField='GroupNo' />

					<Column visible={false} dataField='GroupUp' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='GroupAmt' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='BaseRate' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='PriceDisplay' dataType='boolean' />

					<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='ForexAmount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='NettAmount' caption='Nett Amount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

					<Summary recalculateWhileEditing={true}>
						<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettLocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettPrice' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='UniCost' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ItemDiscountAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='GroupUp' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='GroupAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='BaseRate' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />


						<TotalItem column='SubTotal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
					</Summary>
				</ChildrenDatagrid>
			</div>

			<div className='inventory-module-total-section custom-form-grid-section-70-30'>


				<div></div>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Total </div>

					<div className='popup-group-form-input'>
						<NumberBox
							value={formValue["MainLocalAmount"]}
							elementAttr={{ dataField: "MainLocalAmount" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"auto"}
							readOnly={true}
							format='#,##0.00'>
							{/* <Validator 
							>
								<ValidatorRequired message={"Outstanding is required!"} />
							</Validator> */}
						</NumberBox>
					</div>
				</div>
			</div>
		</div>
	</PopupForm>

		<StockFilterForm
			ref={stockFilterFormRef}
			ReturnResult={(e) => {
				if (e.data && e.data.length > 0) {
					setRowCount(prevRowCount => prevRowCount + 3);
					utils.childrenGridSetSource(childrenGridRef, e.data);
				} else {
					utils.childrenGridSetSource(childrenGridRef, e.data);
				}
			}}
		/>


	</div>
}