import React, { useEffect, useState, useRef, useCallback, Children } from "react";
import baseapi from "../../../api/baseapi";
import { TextBox } from "devextreme-react/text-box";
import utils, { closeLoading } from "../../../utils/common";
import { workshopTypeOfService, customerOptions, workshopModel, uomOptions, wkJobbsheetReportNames, workshopJobSheet, workshopServiceAdvisor, workshopMechanic } from "../../../utils/lookupstore";
import DataGrid, { Column, Lookup, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { loading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import TextArea from "devextreme-react/text-area";
import { getFromLS } from "../../../utils/localstorage";
import TypeOfServiceForm from "../type-of-service/form";
import ServiceAdvisorForm from "../serviceadvisor/form";
import MechanicForm from "../mechanic/form";

export default function JobSheetForm(props) {
	const formID = useRef(null);
	const regNoLookupRef = useRef(null);
	const typeOfServiceLookupRef = useRef(null);
	const modelLookupRef = useRef(null);
	const customerLookupRef = useRef(null);
	const referInvLookupRef = useRef(null);
	const serviceAdvisorLookupRef = useRef(null);
	const mechanicLookupRef = useRef(null);
	const formRef = useRef(null);
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const projectLookupRef = useRef(null);
	const [projectMainList, setProjectMainList] = useState([]);

	// Lookup List
	const [customerList, setCustomerList] = useState([]);
	const [typeOfServiceList, setTypeOfServiceList] = useState([]);
	const [serviceAdvisorList, setServiceAdvisorList] = useState([]);
	const [mechanicList, setMechanicList] = useState([]);
	const [registrationList, setRegistrationList] = useState([]);
	const [workshopInvoiceList, setWorkShopInvoiceList] = useState([]);
	const [refInvoiceList, setRefInvoiceList] = useState([]);
	const [stockList, setStockList] = useState([]);
	const [uomList, setUomList] = useState([]);
	const [taxCodeList, setTaxCodeList] = useState([]);
	const [modelList, setModelList] = useState([]);
	const [lockedForm, setLockedForm] = useState(false);
	const formRead = useRef(false);
	const tempForm = useRef(null);
	const [submitForm, setSubmitForm] = useState(false);
	const [formValue, setFormValue] = useState({});
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const childrenGridRef = useRef(null);
	const childrenGridRef2 = useRef(null);
	const [defaultLocation, setDefaultLocation] = useState({ id: "", code: "" });
	const [locationList, setLocationList] = useState([]);
	const [dataSourceURL, setDataSourceURL] = useState(`api/Utilities/GetWKJobSheetInvoice?regID=${formValue["RegisterID"]}`);
	const [defaultValue, setDefaultValue] = useState({});

	useEffect(() => {
		if (formValue["RegisterID"]) {
			setDataSourceURL(`api/Utilities/GetWKJobSheetInvoice?regID=${formValue["RegisterID"]}`);
		}
	}, [formValue["RegisterID"]]);
	useEffect(() => {
		Promise.allSettled([customerOptions.store.load(), workshopModel.store.load(), workshopTypeOfService.store.load(), workshopServiceAdvisor.store.load(), workshopJobSheet.store.load()]).then((results) => {
			if (results[0].status === "fulfilled") {
				setCustomerList(results[0].value.data);
			}
			if (results[1].status === "fulfilled") {
				setModelList(results[1].value.data);
			}
		});
	}, []);
	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}

		if (utils.isEmptyArray(uomList)) {
			uomOptions.getAll().then((list) => {
				setUomList(list.data);
			});
		}
	}, [props.formID]);

	function valueOnChange(e, receivedDataField = null) {
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;

			// Update state immediately with the new value for the current field
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (dataField === "CustomerID" && formRead.current) {
				copied["Telephone"] = "";
				baseapi
					.httpget(`api/Customer/Get`, { id: e.value })
					.then((response) => {
						const { data } = response;
						const copied = { ...tempForm.current };

						// Update the copied object with new data
						copied["CustomerName"] = data.model.Name;
						copied["Telephone"] = data.model.Telephone;
						copied["SalesmanID"] = data.model?.salesmanID;
						// Update the state with the new data from the API response
						tempForm.current = copied;
						setFormValue(tempForm.current);
					})
					.catch((err) => {
						console.error("Error fetching customer data:", err);
					});
			} else if (dataField === "RegisterID" && formRead.current) {
				const regNoFound = registrationList.find((c) => c.id === e.value);
				copied["Telephone"] = "";
				if (regNoFound !== undefined) {
					copied["CustomerName"] = regNoFound?.customerName;
					copied["Telephone"] = regNoFound?.telephone;
					copied["CarColor"] = regNoFound?.colour;
					copied["CustomerID"] = regNoFound?.customerID;
					copied["ModelID"] = regNoFound?.modelID;
					copied["ModelCode"] = regNoFound?.modelCode;
					copied["Mileage"] = regNoFound?.mileage;
					copied["SalesmanID"] = regNoFound?.salesmanID;

					tempForm.current = copied;
					setFormValue(tempForm.current);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit(e) {
		if (!utils.isNullOrEmpty(e.validData)) {
			const validData = e.validData;

			const submittedForm = {
				Parent: formValue,
				WKJobsheetDetails: validData["Children"],
				WKJobsheetRepairs: validData["WKJobsheetRepairsChildrenGrid"]
			};

			if (props.onSaved !== undefined) {
				props.onSaved(submittedForm);
			}
			loading(`Saving Job Sheet...`);
			baseapi.httppost(utils.extendUrlVar("/api/WKJobsheet/save", { id: formID.current || "", del: false }), submittedForm).then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
			});

			if (props.clearTransfer) {
				props.clearTransfer(false);
			}
		}
	}

	const clearForm = () => {
		props.closePopup(null);

		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
		//set to empty
		utils.childrenGridSetSource(childrenGridRef2, []);
		props.clearTransfer(false);
		setLockedForm(false);
	};

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Job Sheet...");
		else loading("Loading Existing Job Sheet...");
		baseapi.httpget("/api/Utilities/GetDefaultLocation").then((locationResponse) => {
			if (locationResponse.status === 200) {
				const locationData = locationResponse.data;
				setDefaultLocation({ id: locationData.id, code: locationData.code }); // Set the default location state
			} else {
				console.error("Default location not found");
				// Handle the case where there is no default location
			}
		});
		baseapi.httpget("/api/WKJobsheet/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if (!utils.isNullOrEmpty(data.defaultValues)) {
				setDefaultValue(data.defaultValues);
			}

			if (data.isTransfer) {
				setLockedForm(true);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: "This job sheet is being transferred from invoice, Cannot be edited",
					type: "Warning"
				});
			}
			if (formID.current === "new") {
				tempForm.current = data.model.Parent;

				if (props.transfer) {
					const invoiceData = getFromLS("WorkshopInvoiceAddToJobSheet");
					if (invoiceData !== null) {
						tempForm.current["CustomerID"] = invoiceData["Parent"]["CustomerID"];
						tempForm.current["CustomerName"] = invoiceData["Parent"]["CustomerName"];
						tempForm.current["Address"] = invoiceData["Parent"]["Address"];
						tempForm.current["Address1"] = invoiceData["Parent"]["Address1"];
						tempForm.current["Address2"] = invoiceData["Parent"]["Address2"];
						tempForm.current["Address3"] = invoiceData["Parent"]["Address3"];
						tempForm.current["Telephone"] = invoiceData["Parent"]["Telephone"];
						tempForm.current["Terms"] = invoiceData["Parent"]["Terms"];
						tempForm.current["CreditTermsID"] = invoiceData["Parent"]["CreditTermsID"];
						tempForm.current["CreditTermsCode"] = invoiceData["Parent"]["CreditTermsCode"];
						tempForm.current["SalesmanID"] = invoiceData["Parent"]["SalesmanID"];
						tempForm.current["RegisterID"] = invoiceData["Parent"]["RegisterID"];
						tempForm.current["RegisterCode"] = invoiceData["Parent"]["ResgitserCode"];
						tempForm.current["ModelID"] = invoiceData["Parent"]["ModelID"];
						tempForm.current["ModelName"] = invoiceData["Parent"]["ModelName"];
						tempForm.current["CarColor"] = invoiceData["Parent"]["Colour"];
						tempForm.current["Mileage"] = invoiceData["Parent"]["Mileage"];
						tempForm.current["ModelCode"] = invoiceData["Parent"]["ModelCode"];
						tempForm.current["Remarks"] = invoiceData["Parent"]["Remarks"];

						tempForm.current["ServiceAdvisorID"] = invoiceData["Parent"]["ServiceAdvisorID"];
						tempForm.current["ServiceAdvisorName"] = invoiceData["Parent"]["ServiceAdvisorName"];
						tempForm.current["MechanicID"] = invoiceData["Parent"]["MechanicID"];
						tempForm.current["MechanicName"] = invoiceData["Parent"]["MechanicName"];
					}
					const updatedChildren = invoiceData["Children"].map((child, index) => {
						return {
							...child, // Keep other properties
							Line: index + 1 // Set the Line number starting from 1
						};
					});

					// Set the updated children data to the grid
					onRowUpdated({ key: null }, updatedChildren);
				} else {
					utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
					utils.childrenGridAddRow(childrenGridRef2);
				}

				setFormValue(tempForm.current);
			} else {
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
				utils.childrenGridSetSource(childrenGridRef, data.model.WKJobsheetDetails);
				utils.childrenGridSetSource(childrenGridRef2, data.model.WKJobsheetRepairs);
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const JobSheetRowDefaultValue = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			Quantity: 0,
			UnitPrice: 0,
			ItemDiscountAmt: 0,
			Amount: 0,
			DateRef: new Date(),
			NettAmount: 0,
			LocationID: utils.popupFormGetDefaultValues(formRef)["LocationID"],
			LocationCode: utils.popupFormGetDefaultValues(formRef)["LocationCode"],
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
		};
	};

	const ComplaintRowDefaultValue = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef2) + 1
		};
	};

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = moduleCalculation(currentRow, parentValue, defaultValue["QuantityFormat"], defaultValue["UnitCostFormat"], defaultValue["UnitPriceFormat"]);
	};

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, calculationResult.children);
	};

	const onRepairRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef2);
		utils.childrenGridSetSource(childrenGridRef2, childrenDetails);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const selectedItem = e.selectedItem;
		const dataSource = e.dataSource;
		const JobSheetChildren = utils.childrenGridGetSource(childrenGridRef);
		const recordIndex = JobSheetChildren.findIndex(record => record.ID === key)
		JobSheetChildren[recordIndex][columnName] = e.value;

		if (columnName === "StockID") {
			JobSheetChildren[recordIndex]["StockCode"] = selectedItem?.code;
			JobSheetChildren[recordIndex]["Description"] = selectedItem?.name;
			JobSheetChildren[recordIndex]["Description2"] = selectedItem?.description2;
			JobSheetChildren[recordIndex]["MoreDescription"] = selectedItem?.moreDescription;
			JobSheetChildren[recordIndex]["UnitPrice"] = selectedItem?.price1;
			JobSheetChildren[recordIndex]["UOMID"] = selectedItem?.uomID;
			JobSheetChildren[recordIndex]["UOMCode"] = selectedItem?.uomCode;
			JobSheetChildren[recordIndex]["ChartAccountID"] = selectedItem?.cashSalesChartAccountID;
			JobSheetChildren[recordIndex]["ChartAccountCode"] = selectedItem?.cashSalesChartAccountCode;
			JobSheetChildren[recordIndex]["MechanicID"] = formValue["MechanicID"];
		} else if (columnName === "TaxCodeID") {
			JobSheetChildren[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			JobSheetChildren[recordIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "MechanicID") {
			const copiedArr = [...mechanicList];
			if (!utils.isInArray(mechanicList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setMechanicList(copiedArr);
		} else if (columnName === "LocationID") {
			JobSheetChildren[recordIndex]["LocationCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = JobSheetChildren[recordIndex];
		onRowUpdated(e, JobSheetChildren);
	};

	const gridOnSelectionChanged2 = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef2);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const selectedItem = e.selectedItem;
		const RepairDetails = utils.childrenGridGetSource(childrenGridRef2);
		const recordIndex = RepairDetails.findIndex(record => record.ID === key)
		RepairDetails[recordIndex][columnName] = e.value;

		if (columnName === "MechanicID") {
			const copiedArr = [...mechanicList];
			if (!utils.isInArray(mechanicList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setMechanicList(copiedArr);
		}
		e["key"] = key;
		e["data"] = RepairDetails[recordIndex];
		onRepairRowUpdated(e, RepairDetails);
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={[childrenGridRef, childrenGridRef2]}
				ReportDocumentNo={formValue["DocumentNo"]}
				onClosing={clearForm}
				lockFormOnChanged={setLockedForm}
				disableButtons={lockedForm === true}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				headerClassName={"workshop-module-form-title-bg popup-form-title"}
				creditChecking={false}
				title={"Job Sheet"}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyFields={{
					Parent: formValue,
					Children: utils.childrenGridGetSource(childrenGridRef),
					WKJobsheetRepairsChildrenGrid: utils.childrenGridGetSource(childrenGridRef2)
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
					onRowUpdated({ key: null }, e.Children);
				}}
				requiredColumns={{
					Children: ["StockID"],
					WKJobsheetRepairsChildrenGrid: []
				}}
				filterRules={{
					Children: [
						{ name: "StockID", rules: [{ name: "Required" }] },
						{ name: "Description", rules: [{ name: "Required" }] },
						{ name: "Quantity", rules: [{ name: "Min", value: 1 }] }
					],
					WKJobsheetRepairsChildrenGrid: [{ name: "Description", rules: [{ name: "Required" }] }]
				}}
				copyStorage={"WorkshopJobSheetCopiedData"}
				formData={formValue}
				defaultSelectedReport={"MhTWSK0vIiU%3d"}
				reportURL={wkJobbsheetReportNames.url}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[regNoLookupRef, typeOfServiceLookupRef, modelLookupRef, customerLookupRef, serviceAdvisorLookupRef, mechanicLookupRef, projectLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							disabled={lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-from-group-container'>
					<div className='popup-group-form-container workshop-module-border-bottom'>
						<div className='popup-form-item-container3'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Reg No </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={regNoLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={registrationList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["RegisterID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "RegisterID");
										}}
										disabled={lockedForm === true}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Reg No is required!"}
										dataSourceURL={"api/Utilities/GetWKRegistration"}
										onDataSourceChanged={setRegistrationList}
										displayText={formValue["RegisterCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='modelCode' caption='Model'></Column>
										<Column dataField='customerName' caption='Customer'></Column>
										<Column dataField='mileage'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>Job No</div>

								<div className='popup-group-form-input'>
									<div className='next-doc-display'>Next Doc {formValue["NextDocNo"]}</div>
									<AsyncTextBox
										value={formValue["DocumentNo"]}
										elementAttr={{ dataField: "DocumentNo" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										placeholder={"<NEW>"}
										disabled={lockedForm === true || props.formID !== "new"}
										asyncURL={"api/WKJobsheet/CheckDuplicateDocNo"}
										asyncMessage={"This Job No is already taken!"}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Date </div>

								<div className='popup-group-form-input'>
									<DateBox
										value={formValue["DocumentDate"]}
										elementAttr={{ dataField: "DocumentDate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										disabled={lockedForm === true}></DateBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>QT/SR </div>

								<div className='popup-group-form-input'>
									<SelectBox
										value={formValue["JobNo"]}
										elementAttr={{ dataField: "JobNo" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={true}></SelectBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Ref No </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["RefNo"]}
										elementAttr={{ dataField: "RefNo" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={lockedForm === true}></TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Refer Inv </div>

								<div className='popup-group-form-input'>
							
									<CustomizedLookup
										ref={referInvLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={workshopInvoiceList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["WKInvoiceID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "WKInvoiceID");
										}}
										disabled={lockedForm === true}
										dataSourceURL={dataSourceURL}
										onDataSourceChanged={setWorkShopInvoiceList}
										displayText={formValue["WKInvoiceCode"]}
										refreshDataSource={true}
									>
										<Column dataField='name' caption='Document No'></Column>
										<Column dataField='date' dataType="date" format="yyyy/MM/dd"></Column>
										<Column dataField='customerName' dataType="customerName"></Column>
										<Column dataField='regNo' dataType="regNo"></Column>
										<Column dataField='amount' caption='Forex' format={{ type: "fixedPoint", precision: 2 }}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container6'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Code </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={customerLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={customerList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CustomerID"]}
										onSelectionChanged={(e) => valueOnChange(e, "CustomerID")}
										disabled={lockedForm === true}
										dataSourceURL={"api/Utilities/GetCustomers"}
										onDataSourceChanged={setCustomerList}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Customer is required!"}
										displayText={formValue["CustomerCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Customer Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Name </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["CustomerName"]}
										elementAttr={{ dataField: "CustomerName" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={lockedForm === true}></TextBox>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container3'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Car Model </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={modelLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={modelList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ModelID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ModelID");
										}}
										disabled={lockedForm === true}
										//acceptCustomValue={true}
										dataSourceURL={"api/Utilities/GetItemModels"}
										onDataSourceChanged={setModelList}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Model is required!"}
										displayText={formValue["ModelCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"}></Column>
									</CustomizedLookup>

									{/* <TextBox
                                        value={formValue["ModelCode"]}
                                        elementAttr={{dataField : "ModelCode"}}
                                        onValueChanged={(e) => {valueOnChange(e)}}
                                        alignment="left"
                                        width={"auto"}
                                    >
                                    </TextBox> */}
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Car Colour </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["CarColor"]}
										elementAttr={{ dataField: "CarColor" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}></TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Telephone </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Telephone"]}
										elementAttr={{ dataField: "Telephone" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={lockedForm === true}></TextBox>
								</div>
							</div>
							<div className='popup-group-form-item' style={{ display: "none" }}>
								<div className='popup-group-form-label'>Project </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<CustomizedLookup
										ref={projectLookupRef}
										className={"ar-listing-datagrid"}
										dataSource={projectMainList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ProjectID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ProjectID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Project is required!"}
										dataSourceURL={"api/Utilities/GetProjects"}
										onDataSourceChanged={setProjectMainList}
										disabled={lockedForm === true}
										displayText={formValue["ProjectCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Project Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>

						<br />

						<div className='custom-hr2'></div>

						<div className='popup-form-item-container2'>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Type of Service </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={typeOfServiceLookupRef}
											className={"workshop-listing-datagrid"}
											dataSource={typeOfServiceList}
											displayExpr={"name"}
											valueExpr={"id"}
											value={formValue["ServiceTypeID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ServiceTypeID");
											}}
											allowAdd={true}
											apiURL={"/api/WKTypeOfService"}
											lookupTitle={"Type of Service"}
											popupForm={<TypeOfServiceForm formID={"new"} closePopup={null} directSaveAndClose={true} onRefresh={() => {
												workshopTypeOfService.store.load().then((list) => {
													setTypeOfServiceList(list.data);
												});
											}} />}
											popupClassName={"workshop-popup-theme"}
											disabled={lockedForm === true}
											dataSourceURL={"api/Utilities/GetWKTypeOfService"}
											onDataSourceChanged={setTypeOfServiceList}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Type of Service is required!"}
											displayText={formValue["ServiceTypeName"]}>
											<Column
												dataField='code'
												dataType={"string"}
												setCellValue={(newData, value) => {
													newData.code = value.toUpperCase();
												}}></Column>
											<Column dataField='name' caption={"Description"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Mileage </div>

									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["Mileage"]}
											elementAttr={{ dataField: "Mileage" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											format='#,##0'
											disabled={lockedForm === true}></NumberBox>
									</div>
								</div>
							</div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Service Advisor </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={serviceAdvisorLookupRef}
											className={"workshop-listing-datagrid"}
											dataSource={serviceAdvisorList}
											displayExpr={"name"}
											valueExpr={"id"}
											value={formValue["ServiceAdvisorID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ServiceAdvisorID");
											}}
											allowAdd={true}
											popupForm={<ServiceAdvisorForm formID={"new"} closePopup={null} directSaveAndClose={true} onRefresh={() => {
												serviceAdvisorLookupRef.current?.resetPagingIndex();
												workshopServiceAdvisor.store.load().then((list) => {
													setServiceAdvisorList(list.data);
												});
											}} />}
											apiURL={"/api/WKServiceAdvisor"}
											disabled={lockedForm === true}
											dataSourceURL={"api/Utilities/GetWKServiceAdvisor"}
											onDataSourceChanged={setServiceAdvisorList}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Service Advisor is required!"}
											displayText={formValue["ServiceAdvisorName"]}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Mechanic </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={mechanicLookupRef}
											className={"workshop-listing-datagrid"}
											dataSource={mechanicList}
											displayExpr={"name"}
											valueExpr={"id"}
											value={formValue["MechanicID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "MechanicID");
											}}

											allowAdd={true}
											popupForm={<MechanicForm formID={"new"} closePopup={null} directSaveAndClose={true} onRefresh={() => {
												mechanicLookupRef.current?.resetPagingIndex();
												workshopMechanic.store.load().then((list) => {
													setMechanicList(list.data);
												});
											}} />}
											disabled={lockedForm === true}
											dataSourceURL={"api/Utilities/GetWKMechanic"}
											onDataSourceChanged={setMechanicList}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Mechanic is required!"}
											displayText={formValue["MechanicName"]}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label' style={{ paddingBottom: "50px" }}>
									Remarks
								</div>

								<div className='popup-group-form-input'>
									<TextArea
										value={formValue["Remarks"]}
										elementAttr={{ dataField: "Remarks" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										height={80}
										disabled={lockedForm === true}></TextArea>
								</div>
							</div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Issue By </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["IssueBy"]}
											elementAttr={{ dataField: "IssueBy" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={true}></TextBox>
									</div>
								</div>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Project </div>

									<div className='popup-group-form-input' id='customized-lookup1'>
										<CustomizedLookup
											ref={projectLookupRef}
											className={"ar-listing-datagrid"}
											dataSource={projectMainList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ProjectID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ProjectID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Project is required!"}
											dataSourceURL={"api/Utilities/GetProjects"}
											onDataSourceChanged={setProjectMainList}
											disabled={lockedForm === true}
											displayText={formValue["ProjectCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Project Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Time in </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["TimeIn"]}
											elementAttr={{ dataField: "TimeIn" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											mask={"Hh:Mm"}
											maskRules={utils.TimeFormatMask}
											disabled={lockedForm === true}></TextBox>
									</div>
								</div>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Time Out </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["TimeOut"]}
											elementAttr={{ dataField: "TimeOut" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											mask={"Hh:Mm"}
											maskRules={utils.TimeFormatMask}
											disabled={lockedForm === true}></TextBox>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div className='popup-group-form-container workshop-module-border-bottom popup-form-datagrid-container'>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							defaultColumnValues={JobSheetRowDefaultValue}
							allowColumnReordering={true}
							allowColumnResizing={true}
							keyExpr='ID'
							showBorders={false}
							lookupFields={["StockID", "MechanicID", "TaxCodeID"]}
							onRowUpdating={onRowUpdating}
							onRowUpdated={onRowUpdated}
							onRowRemoved={onRowUpdated}
							gridLookupOpen={setGridLookupOpen}
							storageName={"WorkshopJobSheetChildrenGrid"}
							disabled={lockedForm === true}
							enabledDescription={false}>
							<Column dataField='Line' dataType='number' editorOptions={{ disabled: true }} caption={"No"} />

							<Column
								visible={true}
								dataField='StockID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.StockCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										dataSourceURL={"/api/Utilities/GetStock"}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}

									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column dataField='Description' />
							<Column
								visible={true}
								dataField='LocationID'
								caption="Location"
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.LocationCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"inventory-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "LocationID")}
										dataSourceURL={"api/Utilities/GetLocations"}
										onDataSourceChanged={setLocationList}

										sourceList={locationList}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column
								visible={true}
								dataField='MechanicID'
								caption={"Mechanic"}
								cellRender={(e) => {
									const data = e.data;
									var result = mechanicList.find((c) => c.id === data.MechanicID)?.name;
									result = data.MechanicID === null ? null : result;
									return result === undefined ? data.MechanicName : result;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										displayExpr={"name"}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "MechanicID")}
										dataSourceURL={"api/Utilities/GetWKMechanic"}
										onDataSourceChanged={setMechanicList}
										sourceList={mechanicList}>
										<Column dataField='code'></Column>
										<Column dataField='name' width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='Quantity' dataType='number' format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />

							<Column
								visible={true}
								dataField='UOMID'
								cellRender={(e) => {
									const data = e.data;
									return data.UOMCode;
								}}
								editorOptions={{ readOnly: true }}
							/>

							<Column visible={true} dataField='UnitPrice' dataType='number' format={utils.getDecimalFormat(defaultValue["UnitPriceFixCheck"], defaultValue["UnitPriceFormat"])} />

							<Column visible={true} dataField='ItemDiscount' dataType='number' />

							<Column visible={true} dataField='ForexAmount' dataType={"number"} />

							<Column
								visible={true}
								dataField='TaxCodeID'
								caption='Tax Code'
								cellRender={(e) => {
									const data = e.data;
									return data.TaxCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
										dataSourceURL={"api/Utilities/GetGSTRate"}
										onDataSourceChanged={setTaxCodeList}
										sourceList={taxCodeList}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='TaxRate' width={"auto"} dataType='number' />

							<Column visible={true} dataField='TaxInclusive' dataType='boolean' />

							<Column visible={true} dataField='ForexTaxable' dataType='number' />

							<Column visible={true} dataField='ForexTaxAmount' dataType='number' />

							<Column visible={true} dataField='NettAmount' dataType='number' />

							<Column visible={true} dataField='ImportDocumentNo' caption='From Document No' editorOptions={{ disabled: true }} width={150} />

							<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

							<Column visible={false} dataField='DateRef' format='dd/MM/yyyy' dataType={"date"} />

							<Summary>
								<TotalItem column='Quantity' summaryType='sum' customizeText={calculateDataGridTotal} />

								<TotalItem column='ItemDiscountAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								
							</Summary>
						</ChildrenDatagrid>
					</div>

					<div className='workshop-module-border-bottom custom-form-grid-section2'>
						<div className='popup-group-form-item'></div>

						<div className='popup-form-item-container2 custom-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label' style={{ width: "80px" }}>
									Rounding{" "}
								</div>

								<div className='popup-group-form-input' style={{ paddingBottom: "7px" }}>
									<CheckBox
										value={formValue["Rounding"]}
										elementAttr={{ dataField: "Rounding" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}></CheckBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Total </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["MainForexAmount"]}
										elementAttr={{ dataField: "MainForexAmount" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={true}
										format='#,##0.00'>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Company Name is required!"} />}</Validator>
									</NumberBox>
								</div>
							</div>
						</div>
					</div>

					<div className='popup-group-form-container workshop-module-border-bottom popup-form-datagrid-container' id='workshop-jobSheet-datagrid'>
						<ChildrenDatagrid
							ref={childrenGridRef2}
							name={"WKJobsheetRepairsChildrenGrid"}
							defaultColumnValues={ComplaintRowDefaultValue}
							allowColumnReordering={true}
							allowColumnResizing={true}
							keyExpr='ID'
							showBorders={false}
							lookupFields={["MechanicID"]}
							onRowUpdated={onRepairRowUpdated}
							onRowRemoved={onRepairRowUpdated}
							disabled={lockedForm === true}
							enabledDescription={false}>
							<Column dataField='Line' dataType='number' editorOptions={{ disabled: true }} caption={"No"} />

							<Column dataField='Description' caption={"Description/Complaint"} width={400} />

							<Column dataField='Remark' />

							<Column
								visible={true}
								dataField='MechanicID'
								caption={"Mechanic"}
								cellRender={(e) => {
									const data = e.data;
									var result = mechanicList.find((c) => c.id === data.MechanicID)?.name;
									result = data.MechanicID === null ? null : result;
									return result === undefined ? data.MechanicName : result;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										displayExpr={"name"}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged2(e, "MechanicID")}
										dataSourceURL={"api/Utilities/GetWKMechanic"}
										onDataSourceChanged={setMechanicList}
										sourceList={mechanicList}>
										<Column dataField='code'></Column>
										<Column dataField='name' width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>
						</ChildrenDatagrid>
					</div>
				</div>
			</PopupForm>
		</div>
	);
}
