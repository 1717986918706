import { useEffect, useState, useRef } from "react";
import utils, { loading , closeLoading} from "../../../utils/common";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { NumberBox, Button as NumberBoxButton } from "devextreme-react/number-box";
import { Column, Toolbar, ToolbarItem } from "devextreme-react/data-grid";
import CustomizedLookup from "../../../components/lookup/Lookup";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import baseapi from "../../../api/baseapi";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";

export default function DocumentNumberSettingForm(props){

	const formID = useRef(null);
	const childrenGridRef = useRef(null);
	const formRef = useRef(null);
    const infoRef = useRef(null);
    const previousFormValue = useRef(null);
    const documentTypeLookupRef = useRef(null);

    const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState({});
	const [lockedForm, setLockedForm] = useState(false);
	const [defaultValue, setDefaultValue] = useState({});
    const [displayAllInfo, setDisplayAllInfo] = useState(true);
    const [displayPrefixOnly, setDisplayPrefixOnly] = useState(false);
    const [displayFormatOnly, setDisplayFormatOnly] = useState(false);
    const [displayNextNumberOnly, setDisplayNextNumberOnly] = useState(false);

    // Ref to track if we are in the middle of a bulk update
	const isBulkUpdating = useRef(false);

	// useEffect to re-enable onValueChanged handlers after state update
	useEffect(() => {
		if (isBulkUpdating.current) {
			// Re-enable handlers after the state update is finished
			isBulkUpdating.current = false;
		}
		
		previousFormValue.current = formValue;
	}, [formValue]); // This effect runs whenever formValues is updated

    useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}

	}, [props.formID]);

    useEffect(() => {
        if(
            !utils.isNullOrEmpty(formValue["Code"])
            && !utils.isNullOrEmpty(formValue["NextNumber"])
            && !utils.isNullOrEmpty(formValue["Format"])
        ){
            baseapi.httpget("/api/DocumentSettingV2/GetGenerateRunningNumber", {
                code : formValue["Code"],
                format: formValue["Format"],
                nextNumber: formValue["NextNumber"]
            }).then((response) => {
                const { data } = response;
                setFormValue((prevValues) => ({
					...prevValues,
					Sample: data
				}));
            });
        }
    }, [formValue["Code"], formValue["NextNumber"], formValue["Format"]]);

    const groupDataByYear = (arr) => {
        const monthNames = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December"
        };

        const groupedData = {};
        
        arr.forEach(item => {
            const { Year, Month, NextNumber, ID } = item;
            if (!groupedData[Year]) {
                groupedData[Year] = {
                    "Year": Year,
                    "ID": ID
                };
            }
    
            // Set the month name and its corresponding NextNumber
            const monthName = monthNames[Month];
            groupedData[Year][monthName] = NextNumber;
        });
    
        // Convert the grouped data to an array if needed
        return Object.values(groupedData);
    }
    
    // Handler for setting values for all TextBoxes
	const setAllValues = (newValues) => {
		isBulkUpdating.current = true; // Disable onValueChanged handlers
		setFormValue(newValues); // Set form values
	};

    function onInitialized(e) {
        if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		baseapi.httpget("/api/DocumentSettingV2/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;

			// //Set Default Values
			// if(!utils.isNullOrEmpty(data.defaultValues)){
			// 	setDefaultValue(data.defaultValues);
			// }

			if (formID.current === "new") {
				//If there is transfer data
				utils.childrenGridAddRow(childrenGridRef);
			} else {
				utils.childrenGridSetSource(childrenGridRef, groupDataByYear(data.model.Children));
			}

            setAllValues(data.model.Parent);
			closeLoading();
		});
	}

    function valueOnChange(e, receivedDataField = null) {
        if (!isBulkUpdating.current) {
			try {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const obj = {};

				obj[dataField] = e.value;

				if(dataField === "Code" && !utils.isNullOrEmpty(e.value)){
					const regex = /^[A-Za-z0-9\-\/]+$/;
                    if(!regex.test(e.value)){
                        utils.popupFormSetErrorForm(formRef, {
                            visible: true,
                            message: "Only digits, characters and - / are allowed",
                            type: "Danger",
                        });
                    }
				}
                else if(dataField === "Format" && !utils.isNullOrEmpty(e.value)){
                    const regex = /^[A-Za-z0-9\-\/@{}]+$/;
                    if (!regex.test(e.value) || !e.value.includes('0')) {
                        utils.popupFormSetErrorForm(formRef, {
                            visible: true,
                            message: "Only digits, characters, and - / @ {} are allowed, and must include at least one '0'.",
                            type: "Danger",
                        });
                    }
                }

                //Generate Sample

				setFormValue((prevValues) => ({
					...prevValues,
					...obj
				}));
			} catch (error) {
				console.log("error", error)
			}	
		}
	}

    function handleSubmit(e) {
		if(!utils.isNullOrEmpty(e.validData)){
            const children = utils.childrenGridGetSource(childrenGridRef);

            const monthMap = {
                "January": 1,
                "February": 2,
                "March": 3,
                "April": 4,
                "May": 5,
                "June": 6,
                "July": 7,
                "August": 8,
                "September": 9,
                "October": 10,
                "November": 11,
                "December": 12
            };

            // Extract and format data
            const formattedData = [];
            children.forEach(data => {
                for (const [monthName, value] of Object.entries(data)) {
                    if (monthMap.hasOwnProperty(monthName)) {
                        formattedData.push({
                            Year: data.Year,
                            Month: monthMap[monthName],
                            NextNumber: value
                        });
                    }
                }
            });

            baseapi
			.httppost(
				utils.extendUrlVar("/api/DocumentSettingV2/save", {
					id: formID.current || "",
					del: false
				}),
				{
                    Parent: formValue,
                    Children: formattedData
                }
			)
			.then((response) => {
				const { data } = response;
                utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
			});
        }
	}

    const clearForm = () => {
		props.closePopup(null);
		formID.current = null;
		setAllValues({});
	};

    const onToolbarPreparing = (e) => {
        const toolbarItems = e.toolbarOptions.items;
        toolbarItems.length = 0;

         // Add a custom text item (Description)
         toolbarItems.unshift({
            location: 'before',
            text: "Set Running Number for each month",
            cssClass: "popup-form-toolbar-section running-number-toolbar-section",
        });

        // Add a custom dxTextBox
        toolbarItems.unshift({
            location: 'before',
            widget: 'dxCheckBox',
            options: {
                value: formValue["MonthlyReset"],
                onValueChanged: (e) => valueOnChange(e),
                elementAttr: { dataField: "MonthlyReset" },
                width: "auto"
            },
            cssClass: 'popup-form-toolbar-section running-number-toolbar-section',
        });

        // Add a custom dxButton
        toolbarItems.push({
            location: "after",
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: () => utils.childrenGridAddRow(childrenGridRef),
            }
        });
        // console.log("toolbar", e)
    };

    const onInitNew = () => {
        return {
            Year: new Date().getFullYear() + utils.childrenGridLength(childrenGridRef),
            January : 1,
            February: 1,
            March: 1,
            April: 1,
            May: 1,
            June: 1,
            July: 1,
            August: 1,
            September: 1,
            October: 1,
            November: 1,
            December: 1
        }
    };

    const popupHeaderCustomization = () => {
        return <div className={"maintenance-module-form-title-bg popup-form-title"}>
            <div className='popup-form-title-grid'>
                <div>
                    Document Number Setting
                    <Button 
                        icon={"info"}
                        stylingMode="text"
                        onClick={showInformation}
                    />
                </div>

                <div className='popup-form-close-container'>
                    <span className='popup-form-header-cancel'>
                        <CheckBox
                            value={formValue["IsDefault"]}
                            elementAttr={{ dataField: "IsDefault" }}
                            onValueChanged={(e) => {
                                valueOnChange(e);
                            }}
                            alignment='left'
                            width={"12px"}
                            height={"12px"}
                            iconSize={"12px"}
                            readOnly={lockedForm === true}
                            className='popup-header-cancel-input'
                        />

                        <span className='popup-header-cancel-text'>Default</span>
                    </span>
                    <Button icon='close' stylingMode='text' className='popup-form-close-btn' onClick={() => utils.popupFormCloseButtonAction(formRef)} />
                </div>
            </div>
        </div>
    };

    const showInformation = (info) => {

        if(info === "Prefix"){
            setDisplayAllInfo(false);
            setDisplayFormatOnly(false);
            setDisplayNextNumberOnly(false);
            setDisplayPrefixOnly(true);
        }
        else if(info === "Format"){
            setDisplayAllInfo(false);
            setDisplayNextNumberOnly(false);
            setDisplayPrefixOnly(false);
            setDisplayFormatOnly(true);
        }
        else if(info === "NextNumber"){
            setDisplayAllInfo(false);
            setDisplayPrefixOnly(false);
            setDisplayFormatOnly(false);
            setDisplayNextNumberOnly(true);
        }
        else{
            setDisplayAllInfo(true);
        }

        if(infoRef.current){
            infoRef.current.instance.show();
        }
    };

    const customizedValidation = () => {
		const children = utils.childrenGridGetSource(childrenGridRef);

		if(Array.isArray(children)){
            const duplicate = utils.checkArrayPropDuplicates(children, "Year");
            if(duplicate.length > 0){
                utils.popupFormSetErrorForm(formRef, {
                    visible: true,
                    message: "Cannot have duplicate year!",
                    type: "Warning",
                });
        
                return false;
            }
		}

        const formatRegex = /^[A-Za-z0-9\-\/@{}]+$/;
        if(!formatRegex.test(formValue["Format"])){
            utils.popupFormSetErrorForm(formRef, {
                visible: true,
                message: "Only digits, characters and - / @ {} are allowed for Format",
                type: "Danger",
            });

            return false;
        }

        const codeRegex = /^[A-Za-z0-9\-\/]+$/;
        if(!codeRegex.test(formValue["Code"])){
            utils.popupFormSetErrorForm(formRef, {
                visible: true,
                message: "Only digits, characters and - / are allowed for Prefix",
                type: "Danger",
            });

            return false;
        }

		return true
	};

    const copyFields = () => {
        const Parent = formValue;
        const Children = utils.childrenGridGetSource(childrenGridRef);

        return{
            Parent,
            Children
        }
    };

    return <div>
        <PopupForm
            ref={formRef}
            childrenGridRef={childrenGridRef}
            onClosing={clearForm}
            fullScreen={false}
            width={"95%"}
            height={"90%"}
            disableButtons={lockedForm === true}
            headerClassName={"maintenance-module-form-title-bg popup-form-title"}
            creditChecking={false}
            apiURL={props.apiURL}
            title={props.title}
            lockFormOnChanged={setLockedForm}
            onShown={onInitialized}
            onSuccessfulSubmit={({ stay }) => {
                if (stay) onInitialized();
            }}
            copyFields={copyFields()}
            onPasteForm={(e) => {
                setAllValues(e.Parent);
            }}
            copyStorage={"DocumentNumberSettingCopiedData"}
            formData={formValue}
            isEditing={isEditing}
            onValidating={setStartValidation}
            startValidation={startValidation}
            onSaving={handleSubmit}
            saveButtonOnly={true}
            headerCustomization={popupHeaderCustomization}
            customizedValidation={customizedValidation}
        >
                <div className="document-running-number-container">
                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>Name </div>

                            <div className='popup-group-form-input'>
                                <AsyncTextBox
                                    value={formValue["Name"]}
                                    elementAttr={{ dataField: "Name" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={lockedForm === true}
                                    asyncURL={`${props.apiURL}/CheckDuplicateName`}
                                    asyncArgs={{ id: formID.current }}
                                    asyncProperty={"name"}
                                    asyncMessage={"This Name is already taken!"}
                                    toUpperCase={false}
                                    startValidation={startValidation !== 0}
                                    required={true}
                                    requiredMessage={"Name is required!"}
                                />
                            </div>
                        </div>

                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>Document Type </div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <CustomizedLookup
                                    ref={documentTypeLookupRef}
                                    className={"ar-listing-datagrid"}
                                    displayExpr={"code"}
                                    valueExpr={"id"}
                                    value={formValue["DocumentTypeID"]}
                                    onSelectionChanged={(e) => {
                                        valueOnChange(e, "DocumentTypeID");
                                    }}
                                    startValidation={startValidation !== 0}
                                    required={true}
                                    requiredMessage={"Document Type is required!"}
                                    dataSourceURL={"api/Utilities/GetDocumentType"}
                                    readOnly={lockedForm === true}
                                    displayText={formValue["DocumentTypeCode"]}
                                >
                                    <Column dataField='code'></Column>
                                    <Column dataField='category'></Column>
                                </CustomizedLookup>
                            </div>
                        </div>
                    </div>

                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>Prefix </div>

                            <div className='popup-group-form-input'>
                                <AsyncTextBox
                                    value={formValue["Code"]}
                                    elementAttr={{ dataField: "Code" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={lockedForm === true}
                                    textButton={
                                        <TextBoxButton
                                            name={"Prefix"}
                                            location="after"
                                            options={{
                                                icon : "info",
                                                stylingMode : "text",
                                                onClick : () => showInformation("Prefix")
                                            }}
                                        />
                                    }
                                    asyncURL={`${props.apiURL}/CheckDuplicateCode`}
                                    asyncArgs={{ id: formID.current }}
                                    asyncProperty={"code"}
                                    asyncMessage={"This Prefix is already taken!"}
                                    toUpperCase={false}
                                    startValidation={startValidation !== 0}
                                    required={true}
                                    requiredMessage={"Prefix is required!"}
                                >
                                </AsyncTextBox>
                            </div>
                        </div>

                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>Format </div>

                            <div className='popup-group-form-input'>
                                <TextBox
                                    value={formValue["Format"]}
                                    elementAttr={{ dataField: "Format" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={lockedForm === true}
                                >
                                    <TextBoxButton
                                        name={"Format"}
                                        location="after"
                                        options={{
                                            icon : "info",
                                            stylingMode : "text",
                                            onClick : () => showInformation("Format")
                                        }}
                                    />
                                </TextBox>
                            </div>
                        </div>
                    </div>

                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>Next Number </div>

                            <div className='popup-group-form-input'>
                                <NumberBox
                                    value={formValue["NextNumber"]}
                                    elementAttr={{ dataField: "NextNumber" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={lockedForm || formValue["MonthlyReset"]}
                                >
                                    <NumberBoxButton
                                        name={"NextNumber"}
                                        location="after"
                                        options={{
                                            icon : "info",
                                            stylingMode : "text",
                                            onClick : () => showInformation("NextNumber")
                                        }}
                                    />
                                </NumberBox>
                            </div>
                        </div>

                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>Sample </div>

                            <div className='popup-group-form-input'>
                                <TextBox
                                    value={formValue["Sample"]}
                                    elementAttr={{ dataField: "Sample" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='' style={{ padding: "0 0" }} id="document-running-setting-children-datagrid">
                        <ChildrenDatagrid
                            ref={childrenGridRef}
                            name={"Children"}
                            defaultColumnValues={onInitNew}
                            keyExpr='ID'
                            showBorders={true}
                            loadPanel={false}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            // onRowUpdating={onRowUpdating}
                            // onRowUpdated={onRowUpdated}
                            // onRowRemoved={onRowUpdated}
                            disabled={lockedForm === true}
                            storageName={"DocumentNumberSettingChildrenGrid"}
                            onParentValueChanged={valueOnChange}
                            defaultDescription={formValue["Description"]}
                            defaultToolbar={false}
                            onToolbarPreparing={onToolbarPreparing}
                            disabledCommonColumns={true}
                        >
                            <Toolbar>
                                <ToolbarItem 
                                    location='before' 
                                    text="Description" 
                                    cssClass={'popup-form-toolbar-section'}
                                />

                                <ToolbarItem 
                                    location='before' 
                                    widget={"dxTextBox"}
                                    cssClass={'popup-form-toolbar-section popup-form-toolbar-description'}
                                    options={{
                                        value: formValue["Description"],
                                        onValueChanged : (e) => valueOnChange(e),
                                        elementAttr : { dataField: "Description" },
                                        width: "55vw"
                                    }}                  
                                />

                                <ToolbarItem 
                                    widget={"dxButton"} 
                                    options={{
                                        icon: "add",
                                        onClick: () => utils.childrenGridAddRow(childrenGridRef)
                                    }}
                                />
                                
                                <ToolbarItem name='columnChooserButton' />
                            </Toolbar>

                            <Column dataField={"Year"}/>
                            <Column dataField={"January"} caption={"Jan"}/>
                            <Column dataField={"February"} caption={"Feb"}/>
                            <Column dataField={"March"} caption={"Mar"}/>
                            <Column dataField={"April"} caption={"Apr"}/>
                            <Column dataField={"May"} caption={"May"}/>
                            <Column dataField={"June"} caption={"Jun"}/>
                            <Column dataField={"July"} caption={"Jul"}/>
                            <Column dataField={"August"} caption={"Aug"}/>
                            <Column dataField={"September"} caption={"Sep"}/>
                            <Column dataField={"October"} caption={"Oct"}/>
                            <Column dataField={"November"} caption={"Nov"}/>
                            <Column dataField={"December"} caption={"Dec"}/>
                        </ChildrenDatagrid>
                    </div>
                </div>
                
                <Popup
                    hideOnOutsideClick={true}
                    fullScreen={false}
                    ref={infoRef}
                    width={"50%"}
                    height={"auto"}
                    showTitle={false}
                >
                    <div className="document-number-note-container">
                        {
                            (displayAllInfo || displayPrefixOnly) && <div>
                                <strong>Prefix:</strong>

                                <div>
                                    Define the document type's code with customizable formats, including special characters like '-'. 
                                    The prefix remains constant before the running number.
                                </div>

                                <ul>
                                    <li>Example: 'QT03-A-', 'QT-', 'Q24-09-', 'QT'.</li>
                                </ul> 
                            </div>
                        }
                        
                        {
                            (displayAllInfo || displayFormatOnly) && <div>
                                <strong>Running Number:</strong>

                                <div>
                                    Set the number of digits using '0' as a placeholder.
                                    The running number increments with each new document.
                                </div>

                                <ul>
                                    <li>Example: '00000' displays '00005' for the number 5.</li>
                                </ul> 

                                <strong>Monthly Running Number:</strong> 

                                <div>
                                    If enabled, you can define the date between the prefix and the running number. Date formats include options like 'yyyyMM', 'MMyyyy', etc.
                                </div>

                                <strong>Date Format Conventions:</strong>
                                <ul>
                                    <li>yyyy: 4-digit year</li>
                                    <li>MM: 2-digit month (e.g., 01 for January, 02 for February)</li>
                                </ul>

                                <strong>Rules for Date Formatting:</strong>
                                <ul>
                                    <li>Uppercase MM must be used for months to avoid confusion with minutes (mm).</li>
                                </ul>

                                Example:
                                <ul>
                                    <li>'prefix-yyyyMM-00000' displays 'QT-202409-00005' for the number 5.</li>
                                </ul>
                            </div>
                        }
                        
                        {
                            (displayAllInfo || displayNextNumberOnly) && <div>
                                <strong>Next Number:</strong>

                                <div>
                                    Preset the next running number or leave it blank for automatic calculation based on the current sequence.
                                </div>

                                <ul>
                                    <li>Example: Setting the next number to '5' starts the running number at '00005'.</li>
                                </ul>
                            </div>
                        }
                        
                        {
                            (displayAllInfo) && <div>
                                <strong>Final Format:</strong> 
                                <div>The final code combines the prefix, format, and next number.</div>
                                <ul>
                                    <li>Example: 'QT-00005'.</li>
                                </ul> 
                            </div>
                        }
                    </div>
                </Popup>

            </PopupForm>
    </div>;
}