import React from 'react';
import { Popup } from 'devextreme-react/popup'; // Adjust the import if necessary

const TermsOfUse = ({ visible, onClose }) => {
  return (
    <Popup
   
      height={'700px'}
      visible={visible}
      onHiding={onClose}
      hideOnOutsideClick={true}
      showCloseButton={true}
      showTitle={true}
      title='Terms of Use'
      className="terms-popup" // Add a custom class
    >
      <div style={{ padding: '10px', overflowY: 'auto' }}>
        <p>
          Welcome to Greenplus Accounting. By using our services, you agree to the following terms and conditions:
        </p>
        <h3 style={{ paddingTop: '10px' }}>1. Acceptance of Terms</h3>
        <p>
          By accessing and using our services, you accept and agree to be bound by the terms and provisions of this agreement.
          In addition, when using these particular services, you shall be subject to any posted guidelines or rules applicable
          to such services.
        </p>
        <h3 style={{ paddingTop: '10px' }}>2. Services</h3>
        <p>
          Our services include, but are not limited to, accounting and bookkeeping services, financial management tools, and
          other related services. We reserve the right to modify or discontinue any part of our services at any time.
        </p>
        <h3 style={{ paddingTop: '10px' }}>3. User Responsibilities</h3>
        <p>
          You are responsible for maintaining the confidentiality of your account and password and for restricting access to
          your computer. You agree to accept responsibility for all activities that occur under your account or password.
        </p>
        <h3 style={{ paddingTop: '10px' }}>4. Privacy Policy</h3>
        <p>
          Your use of our services is also subject to our Privacy Policy. Please review our Privacy Policy to understand our
          practices.
        </p>
        <h3 style={{ paddingTop: '10px' }}>5. Intellectual Property</h3>
        <p>
          All content included on this site, such as text, graphics, logos, icons, images, and software, is the property of
          Greenplus Accounting or its content suppliers and is protected by international copyright laws.
        </p>
        <h3 style={{ paddingTop: '10px' }}>6. Contact Information</h3>
        <p>
          If you have any questions about these Terms of Use, please contact us at
          admin@greenstem.com.my.
        </p>
      </div>
    </Popup>
  );
};

export default TermsOfUse;
