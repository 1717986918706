import { useEffect, useRef, useState, useCallback, forwardRef } from "react";
import utils from "../../../utils/common";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea } from "devextreme-react";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation, processDecimalRowUpData } from "../../../utils/module-calculation";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { uomOptions, salesQuotationReportNames } from "../../../utils/lookupstore";
import AsyncDateBox from "../../../components/async-date-box/AsyncDateBox";

export default forwardRef(function SalesQuotationForm(props, ref) {
	const tempForm = useRef(null);
	const formID = useRef(null);
	const childrenGridRef = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);

	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState({});
	const [reportDocumentNo, setReportDocumentNo] = useState(null);
	const [lockedForm, setLockedForm] = useState(false);
	const [defaultValue, setDefaultValue] = useState({});

	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const customerLookupRef = useRef(null);
	const forexLookupRef = useRef(null);
	const projectLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["DocumentNo"])) {
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		baseapi.httpget("/api/SalesQuotation/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if(!utils.isNullOrEmpty(data.defaultValues)){
				setDefaultValue(data.defaultValues);
			}

			tempForm.current = data.model.Parent;

			if (formID.current === "new") {

				//If there is transfer data
				if (!utils.isNullOrEmpty(props.transferData)) {
					const transferData = props.transferData;

					if (!utils.isNullOrEmpty(transferData["Parent"])) {
						tempForm.current = transferData["Parent"];
						tempForm.current["DocumentNo"] = data.model.Parent["DocumentNo"];
					}

					if (!utils.isNullOrEmpty(transferData["Children"])) {
						const fomulatedChildren = moduleCalculation(transferData["Children"], tempForm.current);
						tempForm.current = ParentValueCalculation(transferData["Children"], tempForm.current).parent;
						utils.childrenGridSetSource(childrenGridRef, fomulatedChildren);
					}

					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: transferData["status"] ? "Data Transfered Successful" : "Data Transfered Unsuccessful",
						type: transferData["status"] ? "Success" : "Danger"
					});
				} else {
					utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
				}
			} else {
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
			}

			setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = moduleCalculation(currentRow, parentValue, utils.popupFormGetDefaultValues(formRef)["QuantityFormat"], utils.popupFormGetDefaultValues(formRef)["UnitCostFormat"], utils.popupFormGetDefaultValues(formRef)["UnitPriceFormat"]);
	};

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, calculationResult.children);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;
		const dataSource = e.dataSource;
		currentRecords[recordIndex][columnName] = e.value;

		if (columnName === "StockID") {
			currentRecords[recordIndex]["StockCode"] = selectedItem?.code;
			currentRecords[recordIndex]["Description"] = selectedItem?.name;
			currentRecords[recordIndex]["Description2"] = selectedItem?.description2;
			currentRecords[recordIndex]["MoreDescription"] = selectedItem?.moreDescription;
			currentRecords[recordIndex]["UnitPrice"] = selectedItem?.price1;
			currentRecords[recordIndex]["UOMID"] = selectedItem?.uomID;
			currentRecords[recordIndex]["UOMCode"] = selectedItem?.uomCode;
		} else if (columnName === "TaxCodeID") {
			currentRecords[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			currentRecords[recordIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "ProjectID") {
			currentRecords[recordIndex]["ProjectCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = currentRecords[recordIndex];
		onRowUpdated(e, currentRecords);
	};

	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (!utils.isNullOrEmpty(e.value)){
				if (dataField === "CustomerID" && formRead && !utils.isNullOrEmpty(e.value)) {
					baseapi.httpget(`api/Customer/Get`, { id: e.value }).then((response) => {
						const { data } = response;
						const copied = { ...tempForm.current };
						copied["CustomerName"] = data.model.Name;
						copied["Address"] = data.model.CustomerAddress;
						copied["SalesmanID"] = data.model.SalesmanID;
						copied["SalesmanCode"] = data.model.SalesmanCode;
						copied["AreaID"] = data.model.AreaID;
						copied["AreaCode"] = data.model.AreaCode;
						copied["ForexID"] = data.model.ForexID;
						copied["CreditTermsID"] = data.model.CreditTermID;
						copied["CreditTermsCode"] = data.model.CreditTermCode;

						baseapi
							.httpget(`/api/Forex/Get`, { id: data.model.ForexID })
							.then((response) => {
								const { data } = response;
								copied["LocalRate"] = data.model.LocalRate;
								copied["ForexRate"] = data.model.ForexRate;

								tempForm.current = copied;
								setFormValue(tempForm.current);
							})
							.catch((error) => {
								console.log(error);
							});
					});
				} else if (dataField === "ForexID" && formRead && !utils.isNullOrEmpty(e.value)) {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;
							tempForm.current = copied;

							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				} else if (dataField === "LocalRate" && formRead) {
					const copied = { ...tempForm.current };
					const copiedDetails = utils.childrenGridGetSource(childrenGridRef);
					var balance = 0;

					for (var i = 0; i < Object.keys(copiedDetails).length; i++) {
						if (copiedDetails[i]["NettAmount"] === undefined) {
							balance += parseFloat(copiedDetails[i]["ForexAmount"]);
						} else {
							balance += parseFloat(copiedDetails[i]["NettAmount"]);
						}
					}

					copied["MainLocalAmount"] = balance * e.value;

					setFormValue(copied);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	function handleSubmit(e) {
		if(!utils.isNullOrEmpty(e.validData)){
			const validData = e.validData;

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};
	
			loading(`Saving ${props.title}...`);
	
			baseapi
			.httppost(
				utils.extendUrlVar("/api/SalesQuotation/save", {
					id: formID.current || "",
					del: false
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
				if (data.docNo) setReportDocumentNo(data.docNo);
			});
		}	
	}

	const onInitNew = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			Quantity: 0,
			BankInDate: new Date(),
			NettAmount: 0,
			NettLocalAmount: 0,
			BankCharges: 0,
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			DetailLN: 0,
			Point: 0
		};
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return (
		<PopupForm
			ref={formRef}
			childrenGridRef={childrenGridRef}
			onClosing={clearForm}
			fullScreen={false}
			width={"95%"}
			height={"90%"}
			disableButtons={lockedForm === true}
			headerClassName={"sales-module-form-title-bg popup-form-title"}
			creditChecking={false}
			apiURL={props.apiURL}
			title={props.title}
			onShown={onInitialized}
			requiredColumns={{
				Children: ["StockID"]
			}}
			filterRules={{
				Children:[
					{name: "StockID", rules : [{name : "Required"}]},
					{name: "Description", rules : [{name : "Required"}]},
					{name: "Description2", rules : [{name : "Required"}]},
					{name: "Quantity", rules : [{name : "Min", value: 1}]},
				]
			}}
			onSuccessfulSubmit={({ stay }) => {
				if (stay) onInitialized();
			}}
			copyFields={{
				Parent: formValue,
				Children : utils.childrenGridGetSource(childrenGridRef)
			}}
			onPasteForm={(e) => {
				tempForm.current = e.Parent;
				setFormValue(e.Parent);
			}}
			lockFormOnChanged={setLockedForm}
			copyStorage={"SalesQuotationCopiedData"}
			ReportDocumentNo={reportDocumentNo}
			defaultSelectedReport={"32aQ_xmfWqQ%3d"}
			reportURL={salesQuotationReportNames.url}
			formData={formValue}
			isEditing={isEditing}
			onValidating={setStartValidation}
			startValidation={startValidation}
			onSaving={handleSubmit}
			lookupValidateRef={[customerLookupRef, forexLookupRef, projectLookupRef, areaLookupRef, salesmanLookupRef]}
			cancelDocumentCustomization={
				<span className='popup-form-header-cancel'>
					<CheckBox
						value={formValue["Cancelled"]}
						elementAttr={{ dataField: "Cancelled" }}
						onValueChanged={(e) => {
							valueOnChange(e);
						}}
						alignment='left'
						width={"12px"}
						height={"12px"}
						iconSize={"12px"}
						readOnly={lockedForm === true}
						className='popup-header-cancel-input'
					/>

					<span className='popup-header-cancel-text'>Cancelled</span>
				</span>
			}>
			<div className='popup-form-main-content'>
				<div className='sales-module-parent-section custom-form-grid-section1'>
					<div className=''>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Customer Code </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={customerLookupRef}
									className={"ar-listing-datagrid"}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["CustomerID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "CustomerID");
									}}
									startValidation={startValidation !== 0}
									required={true}
									requiredMessage={"Customer Code is required!"}
									dataSourceURL={"api/Utilities/GetCustomers"}
									readOnly={lockedForm === true}
									displayText={formValue["CustomerCode"]}
								>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Customer Name"}></Column>
								</CustomizedLookup>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Customer Name </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["CustomerName"]}
									elementAttr={{ dataField: "CustomerName" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									readOnly={lockedForm === true}>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Customer Name is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>

						<div className='popup-group-form-item group-form-item-align-top'>
							<div className='popup-group-form-label'>Address </div>

							<div className='popup-group-form-input'>
								<TextArea
									value={formValue["Address"]}
									elementAttr={{ dataField: "Address" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									height={100}
									readOnly={lockedForm === true}>
									{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Delivery Address is required!"} />}</Validator> */}
								</TextArea>
							</div>
						</div>
					</div>

					<div>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>Quotation No </div>

								<div className='popup-group-form-input'>
									<div className='next-doc-display'>Next Doc {formValue["NextDocNo"]}</div>
									<AsyncTextBox
										value={formValue["DocumentNo"]}
										elementAttr={{ dataField: "DocumentNo" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										placeholder={"<NEW>"}
										alignment='left'
										width={"auto"}
										disabled={formID.current !== "new" || lockedForm === true}
										asyncURL={"api/SalesQuotation/CheckDuplicateDocNo"}
										asyncMessage={"This Quotation No is already taken!"}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Date </div>

								<div className='popup-group-form-input'>
									<AsyncDateBox
										value={formValue["DocumentDate"]}
										elementAttr={{ dataField: "DocumentDate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										readOnly={lockedForm === true}
										asyncURL={"api/Utilities/CheckAfterFiscalYear"}
										asyncMessage={"Date cannot set before current fiscal year"}
										asyncProperty={"inputDate"}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Salesman </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={salesmanLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["SalesmanID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "SalesmanID");
										}}
										dataSourceURL={"api/Utilities/GetSalesmans"}
										displayText={formValue["SalesmanCode"]}
										readOnly={lockedForm === true}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Salesman is required!"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Ref </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Remarks"]}
										elementAttr={{ dataField: "Remarks" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm === true}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Project </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<CustomizedLookup
										ref={projectLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ProjectID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ProjectID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Project is required!"}
										dataSourceURL={"api/Utilities/GetProjects"}
										readOnly={lockedForm === true}
										displayText={formValue["ProjectCode"]}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Project Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Area </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={areaLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["AreaID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "AreaID");
										}}
										displayText={formValue["AreaCode"]}
										dataSourceURL={"api/Utilities/GetAreas"}
										readOnly={lockedForm === true}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Area is required!"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Terms </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CreditTermsID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "CreditTermsID");
										}}
										displayText={formValue["CreditTermsCode"]}
										dataSourceURL={"api/Utilities/GetCreditTerms"}
										readOnly={lockedForm === true}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption='Days'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Issue By </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["IssueBy"]}
										elementAttr={{ dataField: "IssueBy" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Forex </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={forexLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ForexID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ForexID");
										}}
										required={true}
										requiredMessage={"Forex is required!"}
										readOnly={true}
										dataSourceURL={"api/Utilities/GetForexs"}
										preread={true}
									>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Local Rate </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["LocalRate"]}
										elementAttr={{ dataField: "LocalRate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										format={ utils.getDecimalFormat(defaultValue["LocalRateFixCheck"], defaultValue["LocalRateFormat"]) }
										readOnly={lockedForm === true || formValue["ForexID"] === defaultValue["ForexID"]}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Forex Rate is required!"} />}</Validator>
									</NumberBox>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='children-datagrid-container sales-module-children-datagrid' style={{ padding: "0 0" }}>
					<ChildrenDatagrid
						ref={childrenGridRef}
						name={"Children"}
						defaultColumnValues={onInitNew}
						lookupFields={["StockID", "ProjectID", "ChartAccountID", "TaxCodeID"]}
						keyExpr='ID'
						showBorders={true}
						loadPanel={false}
						allowColumnReordering={true}
						allowColumnResizing={true}
						onRowUpdating={onRowUpdating}
						onRowUpdated={onRowUpdated}
						onRowRemoved={onRowUpdated}
						disabled={lockedForm === true}
						gridLookupOpen={setGridLookupOpen}
						storageName={"SalesCashSalesChildrenGrid"}
						onParentValueChanged={valueOnChange}
						defaultDescription={formValue["Description"]}
					>
						<Column dataField='Line' dataType='number' defaultColumnIndex={0} />

						<Column
							visible={true}
							dataField='StockID'
							dataType='string'
							cellRender={(e) => {
								const data = e.data;
								return data.StockCode;
							}}
							editCellComponent={(component) => (
								<CustomizedGridLookup
									{...component}
									opened={gridLookupOpen}
									className={"ar-listing-datagrid"}
									onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}
									dataSourceURL={"/api/Utilities/GetStock"}
								>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Description"} width={"300px"}></Column>
								</CustomizedGridLookup>
							)}></Column>

						<Column dataField='Description' />

						<Column dataField='Description2' />

						<Column visible={true} dataField='MoreDescription' dataType='string' isHtmlEditor={true} />

						<Column
							visible={true}
							dataField='ProjectID'
							dataType='string'
							cellRender={(e) => {
								const data = e.data;;
								return data.ProjectCode;
							}}
							editCellComponent={(component) => (
								<CustomizedGridLookup
									{...component}
									opened={gridLookupOpen}
									className={"ar-listing-datagrid"}
									onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
									dataSourceURL={"/api/Utilities/GetProjects"}
								>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Description"} width={"300px"}></Column>
								</CustomizedGridLookup>
							)}
							editorOptions={{ readOnly: true }}></Column>

						<Column visible={true} dataField='Quantity' dataType='number' format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])}/>

						<Column 
							visible={true} 
							dataField='UOMID'
							cellRender={(e) => {
								const data = e.data;
								return data.UOMCode;
							}}
							editorOptions={{ readOnly: true }} 
						/>

						<Column visible={true} dataField='ItemDiscount' dataType='number' />

						<Column visible={true} dataField='UnitPrice' dataType='number' format={utils.getDecimalFormat(defaultValue["UnitPriceFixCheck"], defaultValue["UnitPriceFormat"])} />

						<Column visible={true} dataField='NettPrice' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

						<Column
							visible={true}
							dataField='TaxCodeID'
							width={150}
							cellRender={(e) => {
								const data = e.data;
								return data.TaxCode;
							}}
							editCellComponent={(component) => (
								<CustomizedGridLookup
									{...component}
									opened={gridLookupOpen}
									className={"ar-listing-datagrid"}
									onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
									dataSourceURL={"api/Utilities/GetGSTRate"}
								>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Description"} width={"300px"}></Column>
								</CustomizedGridLookup>
							)}></Column>

						<Column visible={true} dataField='TaxRate' dataType='number' editorOptions={{ readOnly: true }} />

						<Column visible={true} dataField='TaxInclusive' dataType='boolean' />

						<Column visible={true} dataField='ForexTaxable' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

						<Column visible={true} dataField='ForexTaxAmount' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

						<Column visible={true} dataField='ForexTaxablePlusTax' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

						<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

						<Column visible={false} dataField='PartNo' />

						<Column visible={false} dataField='BrandCode' />

						<Column visible={false} dataField='LinePrint' />

						<Column visible={false} dataField='PrintPrice' dataType='boolean' />

						<Column visible={false} dataField='Confirmation' dataType='boolean' />

						<Column visible={false} dataField='Show' dataType='boolean' />

						<Column visible={false} dataField='SalesType' />

						<Column visible={false} dataField='PriceCheckID' />

						<Column visible={false} dataField='SalesCode' />

						<Column visible={false} dataField='OrderType' />

						<Column visible={false} dataField='BOM' dataType='boolean' />

						<Column visible={false} dataField='ProductionNo' />

						<Column visible={false} dataField='Details' dataType='boolean' />

						<Column visible={false} dataField='UniCost' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='ModelCode' />

						<Column visible={false} dataField='BinShelfNo' />

						<Column visible={false} dataField='SerialNo' />

						<Column visible={false} dataField='Project' />

						<Column visible={false} dataField='GroupNo' />

						<Column visible={false} dataField='GroupUp' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='GroupAmt' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='BaseRate' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='PriceDisplay' dataType='boolean' />

						<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='ForexAmount' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

						<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='NettAmount' caption='Nett Amount' dataType='number' format='#,##0.00' />

						<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

						<Summary recalculateWhileEditing={true}>
							<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='ForexTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='ForexTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='LocalTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='NettLocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='NettPrice' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='UniCost' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='ItemDiscountAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='GroupUp' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='GroupAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='BaseRate' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

							<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
						</Summary>
					</ChildrenDatagrid>
				</div>

				<div className='sales-module-total-section custom-form-grid-section2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Local Total </div>

						<div className='popup-group-form-input' style={{ width: "150px" }}>
							<NumberBox
								value={formValue["MainLocalAmount"]}
								elementAttr={{ dataField: "MainLocalAmount" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								readOnly={true}
								format='#,##0.00'>
								{/* <Validator 
							>
								<ValidatorRequired message={"Local Amount is required!"} />
							</Validator> */}
							</NumberBox>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Total </div>

						<div className='popup-group-form-input' style={{ width: "150px" }}>
							<NumberBox
								value={formValue["MainForexAmount"]}
								elementAttr={{ dataField: "MainForexAmount" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								readOnly={true}
								format='#,##0.00'>
								{/* <Validator 
							>
								<ValidatorRequired message={"Outstanding is required!"} />
							</Validator> */}
							</NumberBox>
						</div>
					</div>
				</div>
			</div>
		</PopupForm>
	);
});
