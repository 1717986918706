import React, { useEffect, useState, useRef } from "react";
import DataGrid, { Column, Lookup, Button as ButtonColumn, Sorting, Scrolling, Pager,Paging } from "devextreme-react/data-grid";
import Container from "../../../components/container";
import { forexOptions } from "../../../utils/lookupstore";
import Listing from "../../../components/listing/newListing";
import utils from "../../../utils/common";
import EInvoiceMain from "../../e-invoice/EInvoiceMain";

export default function ConsolidatedEInvoicesListing() {
	const [currentEditID, setCurrentEditID] = useState(null);
	const [currentDetailID, setCurrentDetailID] = useState(null);
	const [forexList, setForexList] = useState([]);
	const [dropDownRead, setDropDownRead] = useState(false);
	const [transferData, setTransferData] = useState(null);
	const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
	const [invType, setInvType] = useState(null);

	const apiURL = `/api/SalesCashSales`;

	useEffect(() => {
	
		Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
	
	}, []);





	const closePopup = (value) => {
		setCurrentEditID(value);
		setCurrentDetailID(value);
		setTransferData(null);
	};



	return (
		<Container>
			<div id='normal-listing'>
			<Listing
            className="ap-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/APDebitNote/list?isCons=true&page=1&rows=100"
            sortColumn={"DocumentNo"}
            listingTitle={"AP Debit Note"}
            storageName={"APDebitNoteListingColumnWidth"}
            addShow={true}
            submitShow={true}
            submitButtonOnClick={()=>{
setInvType('ConsolidatedSelfBilled');
                setCurrentDetailID('CINV-' + new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate() + new Date().getSeconds())
            }}
        >

            <Column dataField="DocumentNo" />
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="SupplierCode"  />
            <Column dataField="SupplierName" />
            <Column dataField="ForexID" caption={"Forex"} >
                <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}}/>
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
			</div>


		


			<EInvoiceMain 
				formID={currentDetailID} 
				closePopup={closePopup} 
				apiURL={apiURL} 
				title={"E-Invoice Main"} 
				  invoiceType={invType}
				transferData={transferData}
				clearTransfer={setTransferData}
			/>

		
		</Container>
	);
}