import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import { useNavigate } from "react-router-dom";
import baseapi from "../../api/baseapi";
import utils from "../../utils/common";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import { loading, closeLoading } from "../../utils/common";
import CustomizedLookup from "../../components/lookup/Lookup";
import CustomizedGridLookup from "../../components/lookup/GridLookup";
import { Validator, RequiredRule as ValidatorRequired, StringLengthRule } from "devextreme-react/validator";
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";
import { apdebitNoteReportNames } from "../../utils/lookupstore";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import ChildrenDatagrid from "../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../utils/module-calculation";
import Tabs from 'devextreme-react/tabs';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { Gallery } from 'devextreme-react/gallery';
import CustomizedHtmlEditor from '../../components/html-editor/CustomizedHtmlEditor';
import Form, { SimpleItem, Label, GroupItem } from "devextreme-react/form";
import TextArea from "devextreme-react/text-area";
import FileUploader from 'devextreme-react/file-uploader';
import RadioGroup from "devextreme-react/radio-group";

export default function StockForm(props) {
	const tempForm = useRef(null);
	const formID = useRef(null);
	const imageIRef = useRef(null);
	const imageIIRef = useRef(null);
	const imageIIIRef = useRef(null);
	const [listingSellingDataSource, setListingSellingDataSource] = useState([]);
	const [listingUpToDateDataSource, setListingUpToDateDataSource] = useState([]);
	const childrenStockShelfGridRef = useRef(null);
	const childrenStockAlternateGridRef = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const htmlEditorRef = useRef(null);
	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});
	const [stockAlternateList, setStockAlternateList] = useState([]);
	const [taxCodeSalesList, setTaxCodeSalesList] = useState([]);
	const [taxCodePurchaseList, setTaxCodePurchaseList] = useState([]);
	const [customTarrifList, setCustomTarrifList] = useState([]);
	const [uomList, setUomList] = useState([]);
	const [stockGroupList, setStockGroupListList] = useState([]);
	const [sellingDetails, setSellingDetails] = useState([]);
	const [itemTypeList, setItemTypeList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [modelList, setModelList] = useState([]);
	const [shelfList, setShelfList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const [imageData1, setImageData1] = useState(null);
	const [imageData2, setImageData2] = useState(null);
	const [imageData3, setImageData3] = useState(null);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const supplierLookupRef = useRef(null);
	const itemTypeLookupRef = useRef(null);
	const categoryLookupRef = useRef(null);
	const uomLookupRef = useRef(null);
	const stockgroupRef = useRef(null);
	const projectLookupRef = useRef(null);
	const [displayError, setDisplayError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [messageType, setMessageType] = useState("");
	const [selectedFiles1, setSelectedFiles1] = useState(null);
	const [selectedFiles2, setSelectedFiles2] = useState(null);
	const [selectedFiles3, setSelectedFiles3] = useState(null);
	const [isDropZoneActive, setIsDropZoneActive] = useState(false);
	const warningMessage = "The price is lower than the standard cost."
	const [textVisible1, setText1Visible] = useState(true);
	const [textVisible2, setText2Visible] = useState(true);
	const [textVisible3, setText3Visible] = useState(true);
	const allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
	const [id, setID] = useState("");
	function onSelectedFiles1Changed(e) {
		if (e.value.length > 0) {
			setIsDropZoneActive(false);

			setText1Visible(false);
			setSelectedFiles1(e.value[0]);
			var reader = new FileReader();
			reader.onload = (function (theFile) {
				return function (e) {
					setImageData1(e.target.result);
					setFormValue({ ...formValue, ...{ "ImageI": e.target.result } })
				};
			})(e.value[0]);
			reader.readAsDataURL(e.value[0]);
		}
	}

	const [toggleState, setToggleState] = useState(1);

	const toggleTab = (index) => {
		setToggleState(index);
	};
	function onSelectedFiles2Changed(e) {
		if (e.value.length > 0) {
			setIsDropZoneActive(false);

			setText2Visible(false);
			setSelectedFiles2(e.value[0]);

			var reader = new FileReader();
			reader.onload = (function (theFile) {
				return function (e) {
					setImageData2(e.target.result);
					setFormValue({ ...formValue, ...{ "ImageII": e.target.result } })
				};
			})(e.value[0]);
			reader.readAsDataURL(e.value[0]);
		}
	}
	function onSelectedFiles3Changed(e) {
		if (e.value.length > 0) {
			setIsDropZoneActive(false);
			setText3Visible(false);
			setSelectedFiles3(e.value[0]);
			var reader = new FileReader();
			reader.onload = (function (theFile) {
				return function (e) {
					setImageData3(e.target.result);
					setFormValue({ ...formValue, ...{ "ImageIII": e.target.result } })
				};
			})(e.value[0]);
			reader.readAsDataURL(e.value[0]);
		}
	}


	useEffect(() => {
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);



	function valueOnChange(e, receivedDataField = null) {
		try {

			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;

			if (formRead) {
				if (dataField === "Code" || dataField === "Description" || dataField === "Description2" || dataField === "Remarks") {
					copied[dataField] = e.value.toUpperCase() ?? "";
				}
			}
			if (dataField !== "ImageI" && dataField !== "ImageII" && dataField !== "ImageIII") {
				tempForm.current = copied;
				setFormValue(tempForm.current);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit({ stay }) {
		//console.log(dropDownList, e.validationGroup.validate(), form);
		loading("Saving Stock...");


		let imageIres;
		if (imageIRef.current != null) {
			imageIres = imageIRef.current.instance;
		}
		let imageIIres;
		if (imageIIRef.current != null) {
			imageIIres = imageIIRef.current.instance;
		}
		let imageIIIres;
		if (imageIIIRef.current != null) {
			imageIIIres = imageIIIRef.current.instance;
		}
		if (htmlEditorRef.current != null) {
			formValue["MoreDescription"] = htmlEditorRef.current.getValue();
		}

		const validationStockShelfRules = [
			{ name: "LocationID", rules: [{ name: "Required" }] },

		];
		const validationStockAlternateRules = [
			{ name: "AlternateID", rules: [{ name: "Required" }] },

		];
		const StockShelfDetails = utils.childrenGridGetSource(childrenStockShelfGridRef);
		const filteredStockShelfChildren = utils.filterGridValue(StockShelfDetails, validationStockShelfRules);
		const StockAlternateDetails = utils.childrenGridGetSource(childrenStockAlternateGridRef);
		const filteredStockAlternateChildren = utils.filterGridValue(StockAlternateDetails, validationStockAlternateRules);

		const submittedForm = {
			Parent: formValue,
			StockShelfChildren: filteredStockShelfChildren,
			StockAlternateChildren: filteredStockAlternateChildren
		};

		const formData = new FormData();

		// Append image files if they exist
		if (selectedFiles1 !== null && (imageIres._files.length < 1 || imageIres._files[0].isValid())) {
			formData.append('Parent.ImageIFile', selectedFiles1);
		}
		if (selectedFiles2 !== null && (imageIIres._files.length < 1 || imageIIres._files[0].isValid())) {
			formData.append('Parent.ImageIIFile', selectedFiles2);
		}
		if (selectedFiles3 !== null && (imageIIIres._files.length < 1 || imageIIIres._files[0].isValid())) {
			formData.append('Parent.ImageIIIFile', selectedFiles3);
		}
		// Append Parent properties to formData
		for (const key in submittedForm.Parent) {
			if (submittedForm.Parent.hasOwnProperty(key)) {
				formData.append(`Parent.${key}`, submittedForm.Parent[key]);
				console.log("submittedForm.Parent[key]", submittedForm.Parent[key])

			}
		}
		submittedForm.StockShelfChildren.forEach((child, index) => {
			for (const key in child) {
				if (child.hasOwnProperty(key)) {
					formData.append(`StockShelfChildren[${index}].${key}`, child[key]);
				}
			}
		});

		submittedForm.StockAlternateChildren.forEach((child, index) => {
			for (const key in child) {
				if (child.hasOwnProperty(key)) {
					formData.append(`StockAlternateChildren[${index}].${key}`, child[key]);
				}
			}
		});
		baseapi
			.httppost(
				utils.extendUrlVar("/api/Stock/save", {
					id: formID.current || "",
					del: false,
				}),
				formData
			)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Warning",
					action: data.action
				});

				if (data.status) {
					setMessageType("Success");
					console.log("submmitied")
					setSelectedFiles1(null);
					setSelectedFiles2(null);
					setSelectedFiles3(null);
					if (imageIRef.current) {
						imageIRef.current.instance.reset();
					}
					if (imageIIRef.current) {
						imageIIRef.current.instance.reset();
					}
					if (imageIIIRef.current) {
						imageIIIRef.current.instance.reset();
					}
					if (htmlEditorRef.current) {
						htmlEditorRef.current.setValue("");
					}
					setText1Visible(true)
					setText2Visible(true)
					setText3Visible(true)
					setImageData1(null);
					setImageData2(null);
					setImageData3(null);
					setToggleState(1);
				} else {
					setMessageType("Danger");
				}
				setDisplayError(true);
				setErrorMessage(data.message);
			});
	}
	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Stock...");
		else loading("Loading Existing Stock...");

		baseapi.httpget("/api/Stock/Get?id=" + (formID.current || "new")).then((response) => {
			const { data } = response;
			console.log("data", data.model)

			if (formID.current === "new") {
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
				utils.childrenGridAddRow(childrenStockShelfGridRef);
				utils.childrenGridAddRow(childrenStockAlternateGridRef);

			} else {
				setFormValue(data.model.Parent);
				setImageData1(data.model.Parent.ImageI);
				if (data.model.Parent.ImageI != null) {
					setText1Visible(false)
				}
				if (data.model.Parent.ImageII != null) {
					setText2Visible(false)
				}
				if (data.model.Parent.ImageIII != null) {
					setText3Visible(false)
				}
				setImageData2(data.model.Parent.ImageII);
				setImageData3(data.model.Parent.ImageIII);
				tempForm.current = data.model.Parent;
				const moreDescription = tempForm.current["MoreDescription"] === undefined ? "" : tempForm.current["MoreDescription"];
				htmlEditorRef.current?.setValue(moreDescription);
				if (imageIRef.current != null)
					imageIRef.current.instance.reset();
				if (imageIIRef.current != null)
					imageIIRef.current.instance.reset();
				if (imageIIIRef.current != null)
					imageIIIRef.current.instance.reset();
				utils.childrenGridSetSource(childrenStockShelfGridRef, data.model.StockShelfChildren);
				utils.childrenGridSetSource(childrenStockAlternateGridRef, data.model.StockAlternateChildren);
				setListingUpToDateDataSource(data.transactions);

			}
			closeLoading();
		});
		baseapi
			.httpget("/api/Stock/GetDynamicFields?id=" + (formID.current || "new"))
			.then((response) => {
				const { data } = response;
				if (data !== null) {
					if (data.length != 0) {
						setID("");
						setListingSellingDataSource(data);
					}
				}
			})
			.catch((e) => {
				throw e;
			});

		formRead.current = true;

		if (htmlEditorRef.current !== null && tempForm.current) {
			htmlEditorRef.current.setValue(tempForm.current["MoreDescription"] || "");
		}
	}

	const onInitStockShelfNew = () => {
		const gridLength = utils.childrenGridLength(childrenStockShelfGridRef);
		return {
			NO: gridLength === -1 ? 1 : gridLength + 1,
			NetQuantity: 0
		};
	};
	const onInitStockAlternateNew = () => {
		return {
			NO: utils.childrenGridLength(childrenStockAlternateGridRef) + 1,

		};
	};
	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = utils.roundUpObjectProperties(moduleCalculation(currentRow, parentValue));
	};

	const onRowUpdated = (e, children = null) => {

		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenStockShelfGridRef);
		if (Array.isArray(childrenDetails)) {
			for (var i = 0; i < childrenDetails.length; i++) {
				const row = childrenDetails[i];

				//Reset the line
				if (!utils.isNullOrEmpty(row["NO"])) {
					row["NO"] = i + 1;
				}
			}
		}
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenStockShelfGridRef, calculationResult.children);
	};
	const onRowStockAlternateUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = utils.roundUpObjectProperties(moduleCalculation(currentRow, parentValue));
	};

	const onRowStockAlternateUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenStockAlternateGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenStockAlternateGridRef, calculationResult.children);
	};

	const clearForm = () => {

		formRead.current = false;
		tempForm.current = {};
		//set state to null
		setText1Visible(true)
		setText2Visible(true)
		setText3Visible(true)
		setImageData1(null);
		setImageData2(null);
		setImageData3(null);
		setToggleState(1);
		setListingSellingDataSource([]);
		setListingUpToDateDataSource([]);
		setFormValue(tempForm.current);
		props.closePopup(null);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenStockShelfGridRef);
		const StockShelfDetails = utils.childrenGridGetSource(childrenStockShelfGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = StockShelfDetails.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;
		StockShelfDetails[recordIndex][columnName] = e.value;
		if (columnName === "ShelfID") {
			const copiedArr = [...shelfList];
			if (!utils.isInArray(shelfList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setShelfList(copiedArr);
		} else if (columnName === "LocationID") {
			StockShelfDetails[recordIndex]["LocationCode"] = selectedItem?.code;
		}
		e["key"] = key;
		e["data"] = StockShelfDetails[recordIndex];
		onRowUpdated(e, StockShelfDetails);
	};
	const gridOnStockAlternateSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenStockAlternateGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenStockAlternateGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;
		currentRecords[recordIndex][columnName] = e.value;
		if (columnName === "AlternateID") {

			currentRecords[recordIndex]["Description"] = selectedItem?.name;
			const copiedArr = [...stockAlternateList];
			if (!utils.isInArray(stockAlternateList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setStockAlternateList(copiedArr);

		}
		e["key"] = key;
		e["data"] = currentRecords[recordIndex];
		onRowStockAlternateUpdated(e, currentRecords);
	};

	function calculateNextRowPrice(rowIndex, dataSource, newMarkup, fieldName) {
		if (rowIndex < dataSource.length) {
			const currentRow = dataSource[rowIndex];
			const indexRow = dataSource[0];
			const currentPrice = indexRow.Price;
			//const newPrice = currentPrice + (currentPrice * newMarkup / 100);
			const newPrice = utils.add(currentPrice, utils.multiply(currentPrice, utils.divide(newMarkup, 100)))
			currentRow.Price = newPrice;

			if (newPrice < formValue["StandardCost"]) {
				// Display the warning popup
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: warningMessage,
					type: "Warning"
				});
			}
			const profitMargin = ((newPrice - formValue["StandardCost"]) / newPrice) * 100;
			const safeProfitMargin = isFinite(profitMargin) ? profitMargin : 0.00;
			currentRow.ProfitMargin = safeProfitMargin;
			// Update the dataSource and refresh the grid
			dataSource[rowIndex] = currentRow;
			formValue[fieldName] = newPrice;

		}
	}
	function calculateNextRowMarkUp(rowIndex, dataSource, newPrice, fieldName) {
		if (rowIndex < dataSource.length) {
			const currentRow = dataSource[rowIndex];
			const indexRow = dataSource[0];
			const indexPrice = indexRow.Price;

			if (indexPrice !== 0) {
				const newMarkup = ((newPrice / indexPrice) * 100) - 100;
				currentRow.MarkUp = newMarkup;
				const calculatePrice = indexPrice + (indexPrice * (newMarkup / 100));
				const profitMargin = ((calculatePrice - formValue["StandardCost"]) / calculatePrice) * 100;
			
				currentRow.ProfitMargin = +profitMargin;
				dataSource[rowIndex] = currentRow;
				formValue[fieldName + "Markup"] = newMarkup;
			} else {
				// Handle the case where indexPrice is zero
				currentRow.MarkUp = 0;
				currentRow.ProfitMargin = 0;
				dataSource[rowIndex] = currentRow;
				formValue[fieldName + "Markup"] = 0;
			}
		}
	}
	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={[childrenStockShelfGridRef, childrenStockAlternateGridRef]}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				alternateValue={utils.childrenGridGetSource(childrenStockAlternateGridRef)}
				stockPriceValue={listingSellingDataSource}
				apiURL={props.apiURL}
				headerClassName={"inventory-module-form-title-bg popup-form-title"}
				creditChecking={false}
				title={"Stock"}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
				copyFields={{
					Parent: formValue,
					ChildrenStockShelfGridRef: utils.childrenGridGetSource(childrenStockShelfGridRef),
					ChildrenStockAlternateGridRef: utils.childrenGridGetSource(childrenStockAlternateGridRef),
					StockPrice: listingSellingDataSource
				}}
				onPasteForm={(e) => {
					const { LastSales, LastPurchase, ...filteredParent } = e.Parent;
					// Set NetQuantity and RealCost to 0 in the filteredParent object
					filteredParent.NetQuantity = 0;
					filteredParent.RealCost = 0;
					
					tempForm.current = filteredParent;
					
					// Set the filtered object as the form value
					setFormValue(filteredParent);
					
					// Set the listing data source as before
					setListingSellingDataSource(e.StockPrice);
				}}
				formData={formValue}
				copyStorage={"StockCopiedData"}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[itemTypeLookupRef, categoryLookupRef, stockgroupRef, uomLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"25px"}
							height={"50px"}
							iconSize={"18px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-form-main-content'>
					<div className='inventory-module-border-bottom custom-form-grid-section1 ' id="top-section">
						<div className='' id="top-input-section-1">

							<div className='popup-group-form-item' >
								<div className='popup-group-form-label'>Stock Code </div>


								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Code"]}
										elementAttr={{ dataField: "Code" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={props.lockedForm === true}
										style={{ width: "50%" }}
									>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Stock Code is required!"} />}
										<StringLengthRule
											message="Stock code cannot exceed 20 characters."
											max={20}
										/>
										
										</Validator>
									
									</TextBox>
								</div>
							</div>


							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Description </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Description"]}
										elementAttr={{ dataField: "Description" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={props.lockedForm === true}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Description2 </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<TextBox
										value={formValue["Description2"]}
										elementAttr={{ dataField: "Description2" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={props.lockedForm === true}>
										{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Description 2 is required!"} />}</Validator> */}
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Remark </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Remarks"]}
										elementAttr={{ dataField: "Remarks" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={props.lockedForm === true}>
										{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Remarks is required!"} />}</Validator> */}
									</TextBox>
								</div>
							</div>
						</div>

						<div id="top-input-section-2">
							<div className='popup-form-item-container2 inventory-module-parent-stock-item-uom-section' >


								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>UOM </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={uomLookupRef}
											className={"inventory-listing-datagrid"}
											dataSource={uomList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["UOMID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "UOMID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"UOM is required!"}
											dataSourceURL={"api/Utilities/GetUOMs"}
											onDataSourceChanged={setUomList}
											disabled={props.lockedForm === true}
											displayText={formValue["UOMCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Descripion"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Stock Group </div>
									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={stockgroupRef}
											className={"inventory-listing-datagrid"}
											dataSource={stockGroupList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["StockGroupID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "StockGroupID");
											}}

											dataSourceURL={"api/Utilities/GetStockGroups"}
											onDataSourceChanged={setStockGroupListList}
											disabled={props.lockedForm === true}
											displayText={formValue["StockGroupCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Group Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>
							<div className='inventory-module-parent-stock-item-net-quantity-section'>
								<div className='popup-form-item-container2'>
									<div className='popup-group-form-item '>
										<div className='popup-group-form-label'>Net Qty </div>

										<div className='popup-group-form-input'>
											<NumberBox
												value={formValue["NetQuantity"]}
												elementAttr={{ dataField: "NetQuantity" }}
												onValueChanged={(e) => {
													valueOnChange(e);
												}}
												alignment='left'
												width={"auto"}
												disabled={props.lockedForm === true}
												format="#,##0.##"
												readOnly={true}
											/>
										</div>
									</div>

									<div className='popup-group-form-item'>
										<div className='popup-group-form-label'>Reorder Quantity </div>
										<div className='popup-group-form-input'>
											<NumberBox
												value={formValue["ReorderQuantity"]}
												elementAttr={{ dataField: "ReorderQuantity" }}
												onValueChanged={(e) => {
													valueOnChange(e);
												}}
												alignment='left'
												width={"auto"}
												disabled={props.lockedForm === true} format="#,##0.##">
												{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Reorder Quantity is required!"} />}</Validator> */}
											</NumberBox>
										</div>
									</div>

								</div>


								<div className='popup-form-item-container2'>
									<div className='popup-group-form-item'>
										<div className='popup-group-form-label'>Last Sales </div>

										<div className='popup-group-form-input'>
											<TextBox
												value={formValue["LastSales"]}
												elementAttr={{ dataField: "LastSales" }}
												onValueChanged={(e) => {
													valueOnChange(e);
												}}
												alignment='left'
												width={"auto"}
												readOnly={true}
											></TextBox>
										</div>
									</div>

									<div className='popup-group-form-item'>
										<div className='popup-group-form-label'>Min Level </div>
										<div className='popup-group-form-input'>
											<NumberBox
												value={formValue["MinLevel"]}
												elementAttr={{ dataField: "MinLevel" }}
												onValueChanged={(e) => {
													valueOnChange(e);
												}}
												alignment='left'
												width={"auto"}
												disabled={props.lockedForm === true} format="#,##0.##">

											</NumberBox>
										</div>

									</div>

									<div></div>
									<div className='popup-group-form-item'>
										<div className='popup-group-form-label'>Max Level </div>

										<div className='popup-group-form-input'>
											<NumberBox
												value={formValue["MaxLevel"]}
												elementAttr={{ dataField: "MaxLevel" }}
												onValueChanged={(e) => {
													valueOnChange(e);
												}}
												alignment='left'
												width={"auto"}
												disabled={props.lockedForm === true} format="#,##0.##">

											</NumberBox>
										</div>
									</div>
								</div>

							</div>
							<div></div>
						</div>
					</div>
					<div className='popup-from-group-container' id="bin-shelf-container">
						<div className='inventory-module-border-bottom-without-padding custom-form-grid-section1'>
							<div className='popup-form-item-container1'>

								<div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
									<ChildrenDatagrid
										ref={childrenStockShelfGridRef}
										name="ChildrenStockShelfGridRef"
										keyExpr='ID'
										showBorders={true}
										defaultColumnValues={onInitStockShelfNew}
										allowColumnReordering={true}
										allowColumnResizing={true}
										onRowUpdating={onRowUpdating}
										onRowUpdated={onRowUpdated}
										onRowRemoved={onRowUpdated}
										disabled={props.lockedForm === true}
										lookupFields={["ShelfID", "LocationID"]}
										storageName={"StockShelfChildrenGrid"}
										onParentValueChanged={valueOnChange}
										scrolling={{ mode: "Standard" }}
										height={200}
										defaultToolbar={false}

									>
										<Toolbar>
											<ToolbarItem
												location='before'
												text="Bin / Shelf No"
												cssClass={'inventory-popup-form-group-name'}
											/>

											{/* <hr className='inventory-popup-form-hr' style={{ width: '39.4%' }} /> */}
											<ToolbarItem
												widget={"dxButton"}
												options={{
													icon: "add",
													onClick: () => {
														if (childrenStockShelfGridRef.current) {
															childrenStockShelfGridRef.current.addRow();
														}
													}
												}}
											/>
										</Toolbar>
										<Column dataField='NO' caption="No" dataType='number' editorOptions={{ disabled: true }} />

										<Column
											visible={true}
											dataField='ShelfID'
											dataType='string'
											caption="Shelf"
											cellRender={(e) => {
												const data = e.data;

												var result = shelfList.find((c) => c.id === data.ShelfID)?.code;
												result = data.ShelfID === null ? null : result;
												return result === undefined ? data.ShelfCode : result;
											}}
											editCellComponent={(component) => (
												<CustomizedGridLookup
													{...component}
													opened={gridLookupOpen}
													className={"inventory-listing-datagrid"}
													onSelectionChanged={(e) => gridOnSelectionChanged(e, "ShelfID")}
													dataSourceURL={"api/Utilities/GetShelf"}
													onDataSourceChanged={setShelfList}
													sourceList={shelfList}>
													<Column dataField='code'></Column>
													<Column dataField='name' caption={"Description"} width={"300px"}></Column>
												</CustomizedGridLookup>
											)}></Column>

										<Column
											visible={true}
											dataField='LocationID'
											caption="Location"
											dataType='string'
											cellRender={(e) => {
												const data = e.data;
												return data.LocationCode;
											}}
											editCellComponent={(component) => (
												<CustomizedGridLookup
													{...component}
													opened={gridLookupOpen}
													className={"inventory-listing-datagrid"}
													onSelectionChanged={(e) => gridOnSelectionChanged(e, "LocationID")}
													dataSourceURL={"api/Utilities/GetLocations"}
													onDataSourceChanged={setLocationList}

													sourceList={locationList}>
													<Column dataField='code'></Column>
													<Column dataField='name' caption={"Description"} width={"300px"}></Column>
												</CustomizedGridLookup>
											)}></Column>



									</ChildrenDatagrid>
								</div>
							</div>
							<div className='popup-form-item-container2 inventory-module-parent-stock-item-type-section' >

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Item Type </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={itemTypeLookupRef}
											className={"inventory-listing-datagrid"}
											dataSource={itemTypeList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ItemTypeID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ItemTypeID");
											}}
											startValidation={startValidation !== 0}
											dataSourceURL={"api/Utilities/GetItemTypes"}
											onDataSourceChanged={setItemTypeList}
											disabled={props.lockedForm === true}
											displayText={formValue["ItemTypeCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Item Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Category Code </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={categoryLookupRef}
											className={"inventory-listing-datagrid"}
											dataSource={categoryList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CategoryID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "CategoryID");
											}}
											// startValidation={startValidation !== 0}
											// required={true}
											// requiredMessage={"Cateogry Code is required!"}
											dataSourceURL={"api/Utilities/GetItemCategories"}
											onDataSourceChanged={setCategoryList}
											disabled={props.lockedForm === true}
											displayText={formValue["CategoryCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Cateogry Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='inventory-popup-group-form-item'>
									<div className='popup-group-form-label'>Brand Code </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"inventory-listing-datagrid"}
											dataSource={brandList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["BrandID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "BrandID");
											}}
											startValidation={startValidation !== 0}
											// required={true}
											// requiredMessage={"Brand Code is required!"}
											dataSourceURL={"api/Utilities/GetBrands"}
											onDataSourceChanged={setBrandList}
											disabled={props.lockedForm === true}
											displayText={formValue["BrandCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Brand Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='inventory-popup-group-form-item'>
									<div className='popup-group-form-label'>Model Code </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"inventory-listing-datagrid"}
											dataSource={modelList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ModelID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ModelID");
											}}
											startValidation={startValidation !== 0}
											dataSourceURL={"api/Utilities/GetItemModels"}
											onDataSourceChanged={setModelList}
											disabled={props.lockedForm === true}
											displayText={formValue["ModelCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Model Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div>

						<div className="container" id="tab-group-container">
							<div className="bloc-tabs">
								<button
									className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
									onClick={() => toggleTab(1)}
								>
									Price
								</button>
								<button
									className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
									onClick={() => toggleTab(2)}
								>
									Other
								</button>
								<button
									className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
									onClick={() => toggleTab(3)}
								>
									Image
								</button>
								<button
									className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
									onClick={() => toggleTab(4)}
								>
									More Description
								</button>
							</div>

							<div className="content-tabs">
								<div
									className={toggleState === 1 ? "content  active-content" : "content"} id='tab-group-1'
								>

									<div className='inventory-module-border-bottom custom-form-grid-section-30-70'>
										<div className=''>

											<div className='popup-group-form-item' style={{ marginTop: '17px' }}>
												<div className='popup-group-form-label'>Real Cost </div>

												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["RealCost"]}
														elementAttr={{ dataField: "RealCost" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
													
														disabled={props.lockedForm === true} format="#,##0.00">

													</NumberBox>
												</div>
											</div>

											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Standard Cost </div>

												<div className='popup-group-form-input' id='customized-lookup1'>
													<NumberBox
														value={formValue["StandardCost"]}
														elementAttr={{ dataField: "StandardCost" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														disabled={props.lockedForm === true} format="#,##0.00">

													</NumberBox>
												</div>
											</div>
											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Min. Selling Price </div>

												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["MinSellingPrice"]}
														elementAttr={{ dataField: "MinSellingPrice" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														disabled={props.lockedForm === true} format="#,##0.00">

													</NumberBox>
												</div>
											</div>
											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Max. Selling Price </div>

												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["MaxSellingPrice"]}
														elementAttr={{ dataField: "MaxSellingPrice" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														disabled={props.lockedForm === true} format="#,##0.00">

													</NumberBox>
												</div>
											</div>

										</div>

										<div className='popup-form-item-container2'>

											<div className='popup-group-form-item' id="prices-datagrid">
												<div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
													<DataGrid
														dataSource={listingSellingDataSource}
														sorting={false}
														onEditorPreparing={(e) => {
															if (e.row.rowIndex === 0 && e.dataField === 'MarkUp') {
																e.editorOptions.disabled = true;
															}

														}}
														onCellPrepared={(e) => {
															if (e.rowType === 'data' && e.rowIndex === 0 && e.column.dataField === 'MarkUp') {
																e.cellElement.style.backgroundColor = '#BEBEBE'; // Lighter shade of grey
																e.cellElement.textContent = '';
															}
														}}
														onOptionChanged={(e) => {
															if (e.name === "editing" && e.fullName === "editing.changes") {
																const updatedChanges = e.value;
																updatedChanges.forEach(change => {
																	const updatedData = change.data;
																	const fieldName = change.key.OriginalName;
																	const key = change.key;
																	// Find the rowIndex
																	const rowIndex = listingSellingDataSource.findIndex(item => item.OriginalName === key.OriginalName);
																	// Update formValue based on what's available in updatedData
																	if (fieldName) {

																		if (updatedData.hasOwnProperty('Price') && (updatedData.Price < formValue["StandardCost"])) {

																			utils.popupFormSetErrorForm(formRef, {
																				visible: true,
																				message: warningMessage,
																				type: "Warning"
																			});
																		}
																		if (updatedData.hasOwnProperty('Price') && (updatedData.Price === '' || updatedData.Price === null)) {
																			updatedData.Price = 0.00;

																		}
																		else if (updatedData.hasOwnProperty('MarkUp') && (updatedData.MarkUp === '' || updatedData.MarkUp === null)) {
																			updatedData.MarkUp = 0.00;

																		}
																		else if (rowIndex === 0 && updatedData.hasOwnProperty('Price')) {
																			const newFirstRowPrice = updatedData.Price;
																			formValue[fieldName] = newFirstRowPrice;

																			// Iterate over the entire dataSource
																			function calculatePriceAndProfit(row, newFirstRowPrice, formValue) {
																				const nextRowMarkup = row.MarkUp ?? 0;
																				const fieldName = row.OriginalName;
																				const newPrice = utils.add(newFirstRowPrice, utils.multiply(newFirstRowPrice, utils.divide(nextRowMarkup, 100)));

																				row.Price = newPrice;
																				const profitMargin = utils.multiply(utils.divide(utils.subtract(newPrice, formValue["StandardCost"]), newPrice), 100);
																				row.ProfitMargin = +profitMargin;
																				formValue[fieldName] = newPrice;
																			}

																			if (newFirstRowPrice !== 0) {
																				const firstRow = listingSellingDataSource[0];
																				calculatePriceAndProfit(firstRow, newFirstRowPrice, formValue);

																			}
																			listingSellingDataSource.slice(1).forEach((row, index) => {
																				if (row.Price === 0.00) {
																					return; // Continue to the next iteration
																				} else {
																					calculatePriceAndProfit(row, newFirstRowPrice, formValue);
																				}
																			});
																		} else if (updatedData.hasOwnProperty('Price')) {
																			formValue[fieldName] = updatedData.Price;
																			const newPrice = updatedData.Price;
																			calculateNextRowMarkUp(rowIndex, listingSellingDataSource, newPrice, fieldName);
																		} else if (updatedData.hasOwnProperty('MarkUp')) {
																			const newMarkup = updatedData.MarkUp;
																			formValue[fieldName + "Markup"] = newMarkup;
																			calculateNextRowPrice(rowIndex, listingSellingDataSource, newMarkup, fieldName);
																		}
																	}
																});
																const copied = { ...tempForm.current };
																tempForm.current = copied;
																setFormValue(tempForm.current);

															}
														}}
													>
														<Column dataField='OriginalName' editorOptions={{ readOnly: true }} visible={false} />
														<Column dataField='DisplayName' caption={""} editorOptions={{ readOnly: true }} />
														<Column dataField='Price' caption={"Price"} alignment={"center"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
														<Column dataField='MarkUp' caption={"Mark Up%"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
														<Column dataField='ProfitMargin' caption={"Profit Margin%"} editorOptions={{ readOnly: true }} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
														<Editing allowUpdating={true} allowDeleting={false} mode='cell' />
													</DataGrid>
												</div>
											</div>
											<div className='popup-from-group-container' id='up-to-date-cost-container'>

												<div className='inventory-popup-form-group-name'>Up To Date Cost</div>
												{/* <hr className='inventory-popup-form-hr' /> */}
												<div className='popup-group-form-item'>
													<div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
														<DataGrid
															dataSource={listingUpToDateDataSource}
															showBorders={true}
															//defaultColumnValues={onInitNew}
															enabledDescription={true}
															allowColumnReordering={true}
															allowColumnResizing={true}
															disabled={props.lockedForm === true}
														>
															<Column dataField='Quantity' caption={"Quantity"} />
															<Column dataField='UnitCost' caption={"Unit Cost"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
															<Column dataField='Date' caption={"Date"} dataType="date" format="dd/MM/yyyy" />
														</DataGrid>
													</div>
												</div>
											</div>

										</div>
									</div>

								</div>

								<div
									className={toggleState === 2 ? "content  active-content" : "content"} id="tab-group-2"
								>

									<div className='inventory-module-border-bottom custom-form-grid-section1'>
										<div className=''>

											<div className='popup-group-form-item'>
												<div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
													<ChildrenDatagrid
														ref={childrenStockAlternateGridRef}
														name="ChildrenStockAlternateGridRef"
														keyExpr='ID'
														showBorders={true}
														//lookupFields={["AlternateID"]}
														defaultColumnValues={onInitStockAlternateNew}
														enabledDescription={false}
														allowColumnReordering={true}
														allowColumnResizing={true}
														onRowUpdating={onRowStockAlternateUpdating}
														onRowUpdated={onRowStockAlternateUpdated}
														onRowRemoved={onRowStockAlternateUpdated}
														disabled={props.lockedForm === true}
														//defaultColumnValues={onInitNew}
														onParentValueChanged={valueOnChange}
														scrolling={{ mode: "Standard" }}
														height={200}

													>

														<Column dataField='NO' visible={false} dataType='number' editorOptions={{ disabled: false }} />
														<Column
															visible={true}
															dataField='AlternateID'
															caption='Alternate Code'
															dataType='string'
															cellRender={(e) => {
																const data = e.data;

																var result = stockAlternateList.find((c) => c.id === data.AlternateID)?.code;
																result = data.AlternateID === null ? null : result;
																return result === undefined ? data.AlternateCode : result;
															}}
															editCellComponent={(component) => (
																<CustomizedGridLookup
																	{...component}
																	opened={gridLookupOpen}
																	className={"inventory-listing-datagrid"}
																	onSelectionChanged={(e) => gridOnStockAlternateSelectionChanged(e, "AlternateID")}
																	dataSourceURL={"/api/Utilities/GetStock"}
																	onDataSourceChanged={setStockAlternateList}
																	sourceList={stockAlternateList}>
																	<Column dataField='code'></Column>
																	<Column dataField='name' caption={"Description"} width={"300px"}></Column>
																</CustomizedGridLookup>
															)}></Column>

														<Column dataField='Description' caption={"Description"} width={"300px"} />



													</ChildrenDatagrid>
												</div>
											</div>
										</div>

										<div className='popup-form-item-container2'>
											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Inner Diameter (ID) </div>
												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["InnerDiameter"]}
														elementAttr={{ dataField: "InnerDiameter" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														disabled={props.lockedForm === true} format="#,##0.##">
													</NumberBox>
												</div>
											</div>
											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Tax Code (Sales) </div>
												<div className='popup-group-form-input'>
													<CustomizedLookup
														className={"inventory-listing-datagrid"}
														dataSource={taxCodeSalesList}
														displayExpr={"code"}
														valueExpr={"id"}
														value={formValue["TaxIDSales"]}
														onSelectionChanged={(e) => {
															valueOnChange(e, "TaxIDSales");
														}}
														startValidation={startValidation !== 0}
														dataSourceURL={"api/Utilities/GetTaxCodes"}
														onDataSourceChanged={setTaxCodeSalesList}
														disabled={props.lockedForm === true}
														displayText={formValue["TaxIDSalesCode"]}>
														<Column dataField='code'></Column>
														<Column dataField='name' caption={"Tax Name"}></Column>
													</CustomizedLookup>
												</div>
											</div>

											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Outer Diameter (OD) </div>
												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["OuterDiameter"]}
														elementAttr={{ dataField: "OuterDiameter" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														format="#,##0.##"
														disabled={props.lockedForm === true}>
													</NumberBox>
												</div>
											</div>

											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Tax Code (Purchase) </div>

												<div className='popup-group-form-input'>
													<CustomizedLookup
														className={"inventory-listing-datagrid"}
														dataSource={taxCodePurchaseList}
														displayExpr={"code"}
														valueExpr={"id"}
														value={formValue["TaxIDPurchase"]}
														onSelectionChanged={(e) => {
															valueOnChange(e, "TaxIDPurchase");
														}}
														dataSourceURL={"api/Utilities/GetTaxCodes"}
														onDataSourceChanged={setTaxCodePurchaseList}
														disabled={props.lockedForm === true}
														displayText={formValue["TaxIDPurchaseCode"]}>
														<Column dataField='code'></Column>
														<Column dataField='name' caption={"Tax Name"}></Column>
													</CustomizedLookup>
												</div>
											</div>

											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Height </div>
												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["Height"]}
														elementAttr={{ dataField: "Height" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														disabled={props.lockedForm === true} format="#,##0.##">
														{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Supplier Name is required!"} />}</Validator> */}
													</NumberBox>
												</div>
											</div>

											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Custom Tarrif Code </div>
												<div className='popup-group-form-input'>
													<CustomizedLookup
														className={"inventory-listing-datagrid"}
														dataSource={customTarrifList}
														displayExpr={"code"}
														valueExpr={"id"}
														value={formValue["CustomTarrifID"]}
														onSelectionChanged={(e) => {
															valueOnChange(e, "CustomTarrifID");
														}}
														startValidation={startValidation !== 0}
														dataSourceURL={"api/Utilities/GetCustomTarrif"}
														onDataSourceChanged={setCustomTarrifList}
														disabled={props.lockedForm === true}
														displayText={formValue["CustomTarrifCode"]}>
														<Column dataField='code'></Column>
														<Column dataField='name' caption={"Custom Tarrif Name"}></Column>
													</CustomizedLookup>
												</div>
											</div>

											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>Weight</div>

												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["Weight"]}
														elementAttr={{ dataField: "Weight" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														disabled={props.lockedForm === true} >
														{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Supplier Name is required!"} />}</Validator> */}
													</NumberBox>
												</div>
											</div>
											<div className='popup-group-form-item'>
												<div className='popup-group-form-label'>MM Size </div>

												<div className='popup-group-form-input'>
													<NumberBox
														value={formValue["MMSize"]}
														elementAttr={{ dataField: "MMSize" }}
														onValueChanged={(e) => {
															valueOnChange(e);
														}}
														alignment='left'
														width={"auto"}
														disabled={props.lockedForm === true}>
														{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Supplier Name is required!"} />}</Validator> */}
													</NumberBox>
												</div>
											</div>
										</div>
									</div>

								</div>

								<div
									className={toggleState === 3 ? "content  active-content" : "content"} id="tab-group-3"
								>
									<Form colCount={3}>
										<SimpleItem>
											<div className="popup-group-form-item">
												<div className="widget-container flex-box" style={{ marginTop: '20px' }}>
													<div
														id="dropzone-external1"
														className={`flex-box ${isDropZoneActive
															? 'dx-theme-accent-as-border-color dropzone-active'
															: 'dx-theme-border-color'
															}`}
													>

														{textVisible1 && (
															<div
																id="dropzone-text"
																className="flex-box"
															>
																<span>Drag & Drop the desired file …</span>
																<span>or click to browse for a file instead.</span>
															</div>
														)}
														{imageData1 ? <img src={imageData1} className='img-thumbnail' style={{ width: '300px', height: '200px', marginLeft: '13px' }}></img> : null}


													</div>
													<FileUploader
														id="file-uploader1"
														dialogTrigger="#dropzone-external1"
														dropZone="#dropzone-external1"
														multiple={false}
														allowedFileExtensions={allowedFileExtensions}
														ref={imageIRef}
														maxFileSize={10000000}
														selectButtonText="Select Image 1"
														labelText=""
														accept="image/*"
														// style={{ marginLeft: '110px' }}
														uploadMode="useButtons" // Use buttons for uploading
														onValueChanged={onSelectedFiles1Changed}
													/>
												</div>
											</div>

										</SimpleItem>
										<SimpleItem>
											<div className="popup-group-form-item">
												<div className="widget-container flex-box" style={{ marginTop: '20px' }}>
													<div
														id="dropzone-external2"
														className={`flex-box ${isDropZoneActive
															? 'dx-theme-accent-as-border-color dropzone-active'
															: 'dx-theme-border-color'
															}`}
													>
														{textVisible2 && (
															<div
																id="dropzone-text"
																className="flex-box"
															>
																<span>Drag & Drop the desired file …</span>
																<span>or click to browse for a file instead.</span>
															</div>
														)}

														{imageData2 ? <img src={imageData2} className='img-thumbnail' style={{ width: '300px', height: '200px', marginLeft: '13px' }}></img> : null}

													</div>
													<FileUploader id="file-uploader2"
														dialogTrigger="#dropzone-external2"
														dropZone="#dropzone-external2"
														multiple={false}
														allowedFileExtensions={allowedFileExtensions}
														ref={imageIIRef}
														maxFileSize={10000000}
														selectButtonText="Select Image 2"
														labelText=""
														accept="image/*"
														// style={{ marginLeft: '115px' }}
														uploadMode="useButtons"
														onValueChanged={onSelectedFiles2Changed} />
												</div>
											</div>

										</SimpleItem>



										<SimpleItem>

											<div className="popup-group-form-item">

												<div className="widget-container flex-box" style={{ marginTop: '20px' }}>

													<div
														id="dropzone-external3"
														className={`flex-box ${isDropZoneActive
															? 'dx-theme-accent-as-border-color dropzone-active'
															: 'dx-theme-border-color'
															}`}
													>

														{textVisible3 && (
															<div
																id="dropzone-text"
																className="flex-box"
															>
																<span>Drag & Drop the desired file …</span>
																<span>or click to browse for a file instead.</span>
															</div>
														)}

														{imageData3 ? <img src={imageData3} className='img-thumbnail' style={{ width: '300px', height: '200px', marginLeft: '13px' }}></img> : null}

													</div>
													<FileUploader
														dialogTrigger="#dropzone-external3"
														dropZone="#dropzone-external3"
														multiple={false}
														allowedFileExtensions={allowedFileExtensions}
														ref={imageIIIRef}
														maxFileSize={10000000}
														selectButtonText="Select Image 3"
														labelText=""
														accept="image/*"
														// style={{ marginLeft: '115px' }}
														uploadMode="useButtons"
														onValueChanged={onSelectedFiles3Changed} />
												</div>
											</div>

										</SimpleItem>
									</Form>

								</div>

								<div
									className={toggleState === 4 ? "content  active-content" : "content"} id="tab-group-4"
								>

									<p>
										<CustomizedHtmlEditor
											ref={htmlEditorRef}
											imageEnabled={true}
											spaceCustomized={true}
											enabledDivTag={true}
										/>
									</p>
								</div>
							</div>
						</div>

					</div>

				</div>
			</PopupForm>

		</div>
	);
}
