import React, { useRef, useEffect, useState } from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, AsyncRule } from 'devextreme-react/data-grid';
import utils from '../../utils/common';
import UserGroupsForm from './UserGroupsForm';
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";
import Listing from '../../components/listing/newListing';
import baseapi from '../../api/baseapi';
import PlanAccessControlFrom from './PlanAccessControlForm';


export default function PlanAccessControl() {
    const apiURL = "/api/PlanAccessControl";
    const [currentEditID, setCurrentEditID] = useState(null);

    useEffect(() => {
        baseapi.httpget('/api/PlanAccessControl/list')
            .then(response => {
                const { data } = response;
                console.log(data)
            });

    },)

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }

    
    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/PlanAccessControl/list"
                sortColumn={"Code"}
                className={"maintenance-listing-datagrid"}
                listingTitle={"Plan"}
                storageName={"PlanListingColumnWidth"}
                onAddClick={setCurrentEditID}
                dateFilter={false}
                handleEdit={handleEdit}
            >
              
			         <Column visible={true} dataField="Code" caption="Plan Code"  width={400}/>
                <Column visible={true} dataField="Name" caption="Plan Name"  width={500} />
         
                <Column visible={true} dataField="Active" caption="Active" width={100}/>

            </Listing>
        </div>

        <PlanAccessControlFrom
            formID={currentEditID}
            closePopup={setCurrentEditID}
        />
    </Container>;
}