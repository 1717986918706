import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import { useNavigate } from 'react-router-dom';
import { useResponsivePopupWidth } from '../../utils/windowUtils';
const VerifyEmailSuccess = ({ onClose, visible,email}) => {

    const navigate = useNavigate();
    const popupWidth = useResponsivePopupWidth();



    const handleOnclick = () =>{
        navigate('/login');
    }

    return (
        <>
            <Popup
                width={popupWidth.current}
                height={'50%'}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={false}
                visible={visible}
            >

                <div className="auth-popup-content">
                <div className="icon-flex">
                    <div className="icon-container">
                        <i className="fas fa-thumbs-up"></i>
                    </div>
                    </div>
                    <p className="auth-popup-title">Email is Verified!</p>
                    <p className="auth-popup-subtitle">Your email {email} has been successfully verified! You can now login to your account.</p>

                    <div className="auth-buttons">
                        <button className="auth-button" onClick={handleOnclick}>login</button>
                    </div>


                </div>
            </Popup>



        </>
    );
};

export default VerifyEmailSuccess;