import { useRef, useState, useEffect } from "react";
import Container from "../../../components/container";
import TreeList, { Column, Lookup, Scrolling, Editing } from "devextreme-react/tree-list";
import { Button } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import baseapi from "../../../api/baseapi";
import { taxCodeOptions, forexOptions } from "../../../utils/lookupstore";
import ChartAccountForm from "./form";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import utils, { loading, closeLoading } from "../../../utils/common";

export default function ChartAccountListing() {
	const treelistRef = useRef(null);
	const [listingDataSource, setListingDataSource] = useState(null);
	const [dropDownRead, setDropDownRead] = useState(false);
	const [forexList, setForexList] = useState([]);
	const [taxCodeList, setTaxCodeList] = useState([]);
	const [currentFormID, setCurrentFormID] = useState(null);
	const [formData, setFormData] = useState(null);
	const [node, setNode] = useState(null);
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const documentViewerLink = useRef(null);

	const dataSource = {
		store: new CustomStore({
			key: "id",
			load: () => {
				return baseapi
					.httpget(`/api/GLChartAccount/list?sidx=ID&sord=true`)
					.then((response) => {
						const { data } = response;
						// console.log(data.items)
						if (data !== null) {
							return data.items;
						} else {
							return [];
						}
					})
					.catch(() => {
						throw "Network error";
					});
			}
		})
	};

	useEffect(() => {
		if (listingDataSource === null) {
			setListingDataSource(dataSource);
		}
	}, [listingDataSource]);

	const buttonAction = (formID, data) => {
		setCurrentFormID(formID);
		setFormData(data);
	};

	const directToReport = (e) => {
		baseapi.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`).then((response) => {
			const { data } = response;
			for (var i = 0; i < data.length; i++) {
				if (data[i].ID === "XnTxgUFafwc%3d") {
					documentViewerLink.current = data[i].ViewerAccessURL;
					loading("Redirecting You To The Report");
					window.open(documentViewerLink.current.toString(), "_blank");
					closeLoading();
				}
			}
		});
	};

	return (
		<Container>
			<div className='listing-page-title-container'>
				<div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
					<span className='datagrid-customized-title'>
						<PathIndicator />
					</span>
					<div style={{alignSelf:"flex-end"}}>
						<Button 
							text="Preview Report"
							icon='textdocument' 
							className={`listing-page-add-btn gl-listing-btn1`}
							onClick={() => {
								loading("Redirecting you to report");
								directToReport();
							}}
						/>
					</div>
				</div>
			</div>

			<div className='listing-filter-container'></div>

			<TreeList
				ref={treelistRef}
				id='chart-account-treelist'
				className='listing-page-treelist'
				dataSource={listingDataSource}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnAutoWidth={false}
				columnResizingMode='nextColumn'
				showRowLines={true}
				showBorders={false}
				autoExpandAll={true}
				height={"100%"}
				width={"100%"}
				keyExpr='id'
				parentIdExpr='ParentID'
				rootValue={"t2B0SsAZelE%3d"}
				loadPanel={{ enabled: true }}
				hoverStateEnabled={true}>
				<Column dataField={"Name"} caption={"Account"} fixed={"true"} minWidth={150}></Column>
				<Column dataField={"Code"} minWidth={100} sortIndex={0} sortOrder={"asc"}></Column>
				<Column dataField={"SpecialAccountType"} caption={"Special Account"} minWidth={60}></Column>
				<Column dataField={"GroupLevel"} caption={"Level"} width={75} alignment={"center"}></Column>
				<Column dataField={"ForexCode"} caption={"Forex"} minWidth={75}></Column>
				<Column dataField={"TaxCode"} caption={"Tax Code"} minWidth={150}></Column>
				{/* <Column dataField={"ForexID"} caption={"Forex"}>
                <Lookup dataSource={forexList} valueExpr={"id"} displayExpr={"code"}/>
            </Column>
            <Column dataField={"TaxCodeID"} caption={"Tax Code"}>
                <Lookup dataSource={taxCodeList} valueExpr={"id"} displayExpr={"code"}/>
            </Column> */}
				<Column
					type='buttons'
					width={"75px"}
					cellRender={(e) => {
						const data = e.data;
						const id = data.id;
						const node = treelistRef.current.instance.getNodeByKey(id);
						var allowEdit = true;

						if(data.SpecialAccountType === "AD" || data.GroupLevel === 5 || (data.AccountType === "FA" && data.GroupLevel === 3)){
							allowEdit = false;
						}

						return (
							<span>
								{
									allowEdit
								 	&& 
									<Button
										icon='plus'
										stylingMode='text'
										className='listing-customized-btn'
										onClick={() => {
											buttonAction("new", data);
											setNode(node);
										}}
									/>
								}
								<Button
									icon='edit'
									stylingMode='text'
									className='listing-customized-btn'
									onClick={() => {
										buttonAction(id, data);
										setNode(node);
									}}
								/>
							</span>
						);
					}}
				/>
				<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} useNative={true} />
			</TreeList>

			<ChartAccountForm
				formID={currentFormID}
				closePopup={setCurrentFormID}
				formData={formData}
				node={node}
				onSubmit={() => {
					if (treelistRef.current !== null) treelistRef.current.instance.refresh();
				}}
				onDeleted={() => {
					if (treelistRef.current !== null) treelistRef.current.instance.refresh();
				}}
			/>
		</Container>
	);
}
