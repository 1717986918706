import React, { useEffect, useState } from "react";
import { Button } from "devextreme-react/button";
import Container from "../../components/container";
import Listing from "../../components/listing/newListing";
import UserForm from "./form";
import ManageUserForm from "./manage";
import baseapi from "../../api/baseapi"; // Assuming baseapi is used for making API calls
import { Column } from "devextreme-react/data-grid";

// Define the reusable RemainingSeatSection component
function RemainingSeatSection({ seatData, showInactiveSeat = true }) {
	return (
		<div className="remaining-seat-section">
			<div className="total-seat">Total User Seat in Subscription Plan: {seatData.TotalUserSeat}</div>
			<div className="seat-in-use">Seat in Use: {seatData.SeatInUse}</div>
			{showInactiveSeat && seatData.InactivedSeat > 0 && (
				<div className="inactive-seat">Inactivated Seat: {seatData.InactivedSeat}</div>
			)}
			<div className="seat-remaining">Seat Remaining: {seatData.RemainingSeat}</div>
		</div>
	);
}

export default function User() {
	const apiURL = "/api/User";
	const [currentEditID, setCurrentEditID] = useState(null);
	const [managerUserFormVisible, setManagerUserFormVisible] = useState(false);
	const [seatData, setSeatData] = useState({
		SeatInUse: 0,
		TotalUserSeat: 0,
		RemainingSeat: 0,
		UserLocked: false,
		UserLockedCount: 0,
		InactivedSeat: 0 // Make sure this is included in the initial state
	});

	const fetchSeatData = async () => {
		try {
			const response = await baseapi.httpget("/api/User/GetLicensedUser");
			console.log(response);
			if (response.data.status) {
				setSeatData({
					SeatInUse: response.data.SeatInUse,
					TotalUserSeat: response.data.TotalUserSeat,
					RemainingSeat: response.data.RemainingSeat,
					UserLocked: response.data.UserLocked,
					UserLockedCount: response.data.UserLockedCount,
					InactivedSeat: response.data.InactiveSeat
				});
			}
		} catch (error) {
			console.error("Error fetching seat data:", error);
		}
	};

	useEffect(() => {
		fetchSeatData();
	}, []);

	const decrementRemainingSeat = () => {
		setSeatData((prev) => ({
			...prev,
			RemainingSeat: Math.max(prev.RemainingSeat - 1, 0),
			SeatInUse: prev.SeatInUse + 1
		}));
	};

	const incrementRemainingSeat = () => {
		setSeatData((prev) => ({
			...prev,
			RemainingSeat: prev.RemainingSeat + 1,
			SeatInUse: Math.max(prev.SeatInUse - 1, 0)
		}));
	};

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
	}

	return (
		<Container>
			<div id="normal-listing">
				<Listing
					apiURL={apiURL}
					listingURL="/api/User/list"
					sortColumn={"CreatedDate"}
					className={"user-listing-datagrid"}
					listingTitle={"User"}
					storageName={"UserListingColumnWidth"}
					onAddClick={setCurrentEditID}
					dateFilter={false}
					userListing={true}
					handleEdit={handleEdit}
					userLock={seatData.UserLocked}
					userLockCount={seatData.UserLockedCount}
					refresh={fetchSeatData}
					remainingSeatAlert={seatData.UserLocked && (
						<div className="subscription-user-background">
							<div className="subscription-user-expired-alert">
								<img
									src="/images/errorwarning-message.svg"
									alt="Warning"
									className="subscription-expired-alert-icon"
								/>
								<p className="subscription-expired-alert-message">
									The additional users exceeding your subscription seat limit have been locked and will be removed after 45 days. To manage user access, click the 'Manage' button below.
								</p>
							</div>
						</div>
					)}
					remainingSeatSection={<RemainingSeatSection seatData={seatData} />}
					customizedAddButton={[
						seatData.UserLocked && (
							<Button
								key="manage-user"
								text="Manage User"
							    icon="user"
								className="user-listing-btn"
								onClick={() => {
									setManagerUserFormVisible(true);
								}}
							/>
						),
						<Button
							key="invite-user"
							height={"38px"}
							icon="plus"
							onClick={(e) => {
								setCurrentEditID("new");
							}}
							style={{ marginLeft: "15px" }}
							text="Invite User"
							className="user-listing-btn"
						/>
					]}
				>
					<Column dataField="Username" />
					<Column dataField="Email" />
					<Column dataField="UserRoleName" caption="User Role" width={"200"} />
				</Listing>
			</div>

			<UserForm
				formID={currentEditID}
				closePopup={setCurrentEditID}
				apiURL={apiURL}
				fetchSeatData={fetchSeatData}
			/>
			<ManageUserForm
				visible={managerUserFormVisible}
				onClose={() => setManagerUserFormVisible(false)}
				remainingSeat={seatData.RemainingSeat}
				decrementRemainingSeat={decrementRemainingSeat}
				incrementRemainingSeat={incrementRemainingSeat}
				remainingSeatSection={<RemainingSeatSection seatData={seatData} showInactiveSeat={false} />}
				fetchSeatData={fetchSeatData}
			/>
		</Container>
	);
}
