import { useRef, useState, useEffect } from "react";
import Container from "../../components/container";
import TreeList, { Column, Lookup, Scrolling, Editing } from "devextreme-react/tree-list";
import { balanceSheetListing, projectOptions } from "../../utils/lookupstore";
import { Button } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import baseapi from "../../api/baseapi";
import { DataGrid, Button as Pager, Toolbar, Column as DGColumn, Selection } from "devextreme-react/data-grid";
import utils, { loading, closeLoading } from "../../utils/common";
import PathIndicator from "../../components/path-indicator/PathIndicator";
import CustomizedLookup from "../../components/lookup/Lookup";
import { TextBox, NumberBox, SelectBox, DropDownBox, Popover, CheckBox } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import { Popup } from "devextreme-react/popup";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";

export default function BalanceSheet() {
	const fromProjectLookupRef = useRef(null);
	const toProjectLookupRef = useRef(null);
	const [fromProjectList, setFromProjectList] = useState([]);
	const [toProjectList, setToProjectList] = useState([]);

	const treelistRef = useRef(null);
	const tempForm = useRef(null);
	const [listingDataSource, setListingDataSource] = useState(null);
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});
	const [originalDropDownList, setOriginalDropDownList] = useState({});
	const userID = JSON.parse(localStorage.getItem("data"));
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const [date, setDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [paramValue, setParamValue] = useState([
		{
			ParameterName: "Date",
			ParameterValue: utils.dateboxFormat(date)
		},
		{
			ParameterName: "ShowZeroBalance",
			ParameterValue: false
		},
		{
			ParameterName: "ShowCode",
			ParameterValue: false
		},
		{
			ParameterName: "Level",
			ParameterValue: 2
		},
		{
			ParameterName: "ReportName",
			ParameterValue: "This Year (Report Listing)"
		},
		{
			ParameterName: "ReportID",
			ParameterValue: "e0D1lcAT76E%3d"
		}
	]);
	// const [dropdownOpened, setDropdownOpened] = useState(false);
	// const [openSpecific, setOpenSpecific] = useState();
	const [openReportPop, setOpenReportPop] = useState(false);
	const reportFormatLookupRef = useRef(null);
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const [openProjectRange, setOpenProjectRange] = useState();
	const [selectedProject, setSelectedProject] = useState(0);
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
	const popupMessageRef = useRef(null);
	const [loadingData, setLoadingData] = useState(false);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});

	useEffect(() => {
		if (pagingIndex.current["loading"] === true && Array.isArray(dropDownList)) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}

		// if (Array.isArray(listingDataSource)) {
		// 	previousDataSource.current = [...listingDataSource];
		// }
	}, [dropDownList]);

	const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];

	useEffect(() => {
		Promise.allSettled([balanceSheetListing.store.load(), projectOptions.store.load()]).then((lists) => {
			setDropDownList({
				reportList: lists[0].value.data,
				projectList: lists[1].value.data
			});
			setOriginalDropDownList({
				reportList: lists[0].value.data,
				projectList: lists[1].value.data
			});

			// console.log(lists[0].value.data)
		});
	}, []);

	const handlePrintBtn = (e) => {
		if (returnSelectedValue("ReportID") !== null) {
			baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportName?reportID=${returnSelectedValue("ReportID")}&accessToken=${currentToken}`).then((response) => {
				const { data } = response;
				data.map((items) => {
					confirmReportParameters(items.ID, items.ViewerAccessURL);
				});
			});
		}
	};

	const confirmReportParameters = async (reportID, ViewerAccessURL) => {
		var documentViewerLink = ViewerAccessURL;

		try {
			// const existingParamArr = [];
			for (const param of paramValue) {
				if (param.ParameterName.includes("List")) {
					documentViewerLink += `&${param.ParameterName}=${listingJoin(param.ParameterValue)}`;
				} else {
					documentViewerLink += `&${param.ParameterName}=${param.ParameterValue}`;
				}
			}
			// console.log(existingParamArr);

			window.open(documentViewerLink, "_blank");
		} catch (error) {
			console.error("Error fetching report parameters:", error);
		}
	};

	function listingJoin (listValue) {
		return listValue.replace("$%", ",");
	}

	function clearDatas () {
		setListingDataSource(null);
		setPrintRepDisabled(true);
	}

	const onInitialized = () => {
		setLoadingData(true);
		var parameters = `?userID=${userID?.userID}&reportListingType=isBS&reportName=${returnSelectedValue("ReportName")}`;
		loading();
		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;
		// console.log(copied);

		baseapi
			.httppost(`${utils.ReportURL}/api/Report/BalanceProfitLoss${parameters}`, copied)
			.then((response) => {
				const { data } = response;
				// console.log(data);
				if (data !== null && data !== undefined) {
					setListingDataSource(data);
					setLoadingData(false);

					if (data.length > 0) {
						setPrintRepDisabled(false);
					}
				}

				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}

				closeLoading();
			})
			.catch((e) => {
				// closeLoading();
				console.log(e);
			});

		tempForm.current.forEach((param) => {
			if (param.ParameterName === "ShowZeroBalance" || param.ParameterName === "ShowCode") {
				param.ParameterValue = param.ParameterValue === "true";
			} else if (param.ParameterName === "Level") {
				param.ParameterValue = parseInt(param.ParameterValue);
			}
		});
	};

	function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				clearDatas();
				const dataField = e.element.getAttribute("dataField");
				if (dataField === "Date") {
					setDate(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								setOpenProjectRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const updateParamValue = (key, value) => {
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (index !== -1) {
				// Update the existing item if it exists
				updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
			} else {
				// Add a new item if the key doesn't exist
				updatedParamValue.push({ ParameterName: key, ParameterValue: value });
			}

			return updatedParamValue;
		});
	};

	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			var updatedParamValue = null;
			updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "Project" && paramObject["ProjectList"]) {
			selectedKeys = ensureArray(paramObject["ProjectList"]);
		}

		return selectedKeys;
	};

	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	const returnDropDownList = (type) => {
		if (type === "Project") {
			return dropDownList.projectList;
		}
	};

	const returnDropDownListURL = (type) => {
		if (type === "Project") {
			return "api/Utilities/GetProjects";
		}
	};

	function refreshLookupData(type) {
		if (type === "Project") {
			setDropDownList((prevState) => ({
				...prevState,
				projectList: [...originalDropDownList.projectList]
			}));
		}
	}

	const arrayToString = (arr) => {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	};

	const updateDropDownList = (paramValue, type, page, paging) => {
		const typeToArrayNameMap = {
			Project: "projectList"
		};
		// console.log(paramValue);
		var value = "";

		if (paramValue !== undefined) {
			value = paramValue;
		}

		if (!paging) {
			page = 0;
		}

		const arrayName = typeToArrayNameMap[type];
		const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;

		baseapi
			.httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
			.then((response) => {
				const data = response.data;
				setDropDownList((prevState) => {
					const existingArray = prevState[arrayName] || [];
					const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
					return {
						...prevState,
						[arrayName]: [...existingArray, ...newData]
					};
				});
			})
			.catch(() => {
				throw "Network error";
			});
	};

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								// if (type === "Project") {
								// 	removeParamValue("ProjectList");
								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// 	refreshLookupData(type);
								// }
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "Project") {
									removeParamValue("ProjectList");
									removeParamValue("FromProject");
									removeParamValue("ToProject");
									setSelectedProject(0);
								}
								refreshLookupData(type);
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid ar-listing-datagrid"}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr='code'
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "Project") {
									removeParamValue("ProjectList");
									refreshLookupData(type);
								}
							}
						}
					}}
					onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										// console.log("page", pagingIndex.current["page"]);
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
										// console.log(e);
									}
								}
							});
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					// onFocusedRowChanged={(e) => {
					//   const data = e.row.data;
					//   currentFocusIndex.current = e.rowIndex;
					//   if(e.event.key === "Enter") {
					//     setSelectedCustKey(data.id);
					//   }
					// }}
				>
					<DGColumn dataField='code' defaultSortOrder={"asc"} elementAttr={"ProjectCode"} caption={"Project Code"} visible={true} alignment='left' allowGrouping={false} />

					<DGColumn dataField='name' elementAttr={"ProjectName"} caption={"Project Name"} visible={true} alignment='left' allowGrouping={false} />

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "Project") {
			updateParamValue("ProjectList", e.selectedRowKeys);
		}
	};

	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "Project") {
				setOpenProjectRange(false);
			}
		} else {
			if (type === "Project") {
				setOpenProjectRange(true);
			}
		}
	};

	return (
		<Container>
			<div className='listing-page-title-container'>
				<span className='datagrid-customized-title'>
					<PathIndicator />
				</span>
			</div>

			<Popup visible={openReportPop} width={"auto"} height={"auto"} dragEnabled={false} title='Print Report' showCloseButton={false}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Report </div>
					<SelectBox
						value={returnSelectedValue("ReportID")}
						elementAttr={{ dataField: "ReportID" }}
						onValueChanged={(e) => {
							// console.log(e.value);
							updateParamValue("ReportID", e.value);
						}}
						alignment='left'
						width={"450px"}
						dataSource={dropDownList.reportList}
						displayExpr={"code"}
						valueExpr={"id"}
						searchEnabled={true}
						showClearButton={true}
					/>
				</div>
				<div className='popup-group-form-item' style={{ marginTop: "15px", justifyContent: "flex-end" }}>
					<Button text='Cancel' type='normal' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(false)} />
					<Button text='Print Report' type='success' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => handlePrintBtn(e)} />
				</div>
			</Popup>

			<div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", width: "75%", padding: "0.7%" }}>
				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Date </div>
						<DateBox
							type='date'
							value={date}
							elementAttr={{ dataField: "Date" }}
							useMaskBehavior={true}
							label='To'
							labelMode='floating'
							displayFormat='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							onValueChanged={(e) => {
								valueOnChange(e, "Date");
							}}
						/>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Project : </div>
						{selectedProject === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedProject}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Project");
								}}
							/>
						)}
						{selectedProject === 1 && (
							<div onClick={() => setOpenProjectRange(true)}>
								<TextBox
									value={returnSelectedValue("FromProject") || returnSelectedValue("ToProject") ? `${returnSelectedValue("FromProject")} to ${returnSelectedValue("ToProject")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Project'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Project'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openProjectRange}
										onHiding={(e) => popFunction(e, "Project")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromProject")}
														onSelectionChanged={(e) => {
															updateParamValue("FromProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("FromProject")}
														onDataSourceChanged={setFromProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToProject")}
														onSelectionChanged={(e) => {
															updateParamValue("ToProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("ToProject")}
														onDataSourceChanged={setToProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("ProjectList");
														removeParamValue("FromProject");
														removeParamValue("ToProject");
														setSelectedProject(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedProject === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Project").length !== 0 ? `${returnSelectedKeys("Project").length} Selected  (${returnSelectedKeys("Project")})` : `${returnSelectedKeys("Project").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Project")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Project")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>

				{/* <div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Report Format </div>
						<SelectBox
							ref={reportFormatLookupRef}
							value={returnSelectedValue("ReportName")}
							elementAttr={{ dataField: "ReportName" }}
							displayValue={returnSelectedValue("ReportName")}
							onValueChanged={(e) => {
								console.log(e.value);
								updateParamValue("ReportName", e.value);
							}}
							alignment='left'
							width={"auto"}
							dataSource={dropDownList.reportList}
							displayExpr={"name"}
							valueExpr={"name"}
							searchEnabled={true}
							showClearButton={true}
						/>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Show up to Level </div>
						<NumberBox
							width={"250px"}
							//dataSource={filterTypes}// report format
							value={returnSelectedValue("Level")}
							max={5}
							min={0}
							showSpinButtons={true}
							showClearButton={true}
							elementAttr={{ dataField: "Level" }}
							onValueChanged={(e) => {
								valueOnChange(e, "Level");
							}}
						/>
					</div>
				</div> */}

				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Show up to Level </div>
						<NumberBox
							width={"230px"}
							//dataSource={filterTypes}// report format
							value={returnSelectedValue("Level")}
							max={5}
							min={0}
							showSpinButtons={true}
							showClearButton={true}
							elementAttr={{ dataField: "Level" }}
							onValueChanged={(e) => {
								valueOnChange(e, "Level");
							}}
						/>
					</div>

					<div className="popup-group-form-item" style={{alignItems: "center"}}>
						<div className='popup-group-form-item'>
							<CheckBox
								value={returnSelectedValue("ShowZeroBalance")}
								defaultValue={false}
								text='Show Zero Balance'
								onValueChanged={(e) => {
									clearDatas();
									updateParamValue("ShowZeroBalance", e.value);
								}}
							/>
						</div>

						<div className='popup-group-form-item'>
							<CheckBox
								value={returnSelectedValue("ShowCode")}
								defaultValue={false}
								text='Show Code Number'
								onValueChanged={(e) => {
									clearDatas();
									updateParamValue("ShowCode", e.value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='popup-group-form-item' style={{ marginTop: "0.5%" }}>
				<Button text='Inquiry' type='success' width={"auto"} onClick={(e) => onInitialized(e)} />
				<Button text='Print Report' disabled={printRepDisabled} type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(true)} />
			</div>

			<TreeList
				ref={treelistRef}
				id='chart-account-treelist'
				className='listing-page-treelist'
				dataSource={listingDataSource}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnAutoWidth={false}
				columnResizingMode='nextColumn'
				showRowLines={true}
				showBorders={false}
				autoExpandAll={true}
				height={"100%"}
				width={"100%"}
				keyExpr='id'
				paging={false}
				parentIdExpr='ParentID'
				rootValue={"t2B0SsAZelE%3d"}
				loadPanel={{ enabled: loadingData }}
				hoverStateEnabled={true}>
				<Column dataField={"ChildrenName"} caption={"Account"} fixed={"true"}></Column>
				<Column
					dataField={"ChartAccountCode"}
					caption={"Code"}
					visible={returnSelectedValue("ShowCode")}
					customizeText={(e) => {
						if (e.value === "Z" || e.value === "2") {
							return "";
						} else {
							return e.value;
						}
					}}>
				</Column>
				<Column
					dataField={"Amount"}
					alignment={"center"}
					dataType={"number"}
					cellRender={(e) => {
						if(returnSelectedValue("Level") > 1) {
							if(!e.data.IsHeader) {
								return <div className='specialValue'>{utils.numberToCurrency(e.value.toString())}</div>;
							} else {
								return '';
							}
						} else {
							return <div className='specialValue'>{utils.numberToCurrency(e.value.toString())}</div>;
						}
					}}></Column>
				<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} useNative={true} />
			</TreeList>

			<ErrorPopUpForm ref={popupMessageRef} />
		</Container>
	);
}
