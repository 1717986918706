import { useCallback, useEffect, useRef } from 'react';

export const useResponsivePopupWidth = (defaultWidth = '45%') => {
  const popupWidth = useRef(defaultWidth);

  const checkWindowSize = useCallback(() => {
    popupWidth.current = window.innerWidth < 870 ? '95%' : defaultWidth;
  }, [defaultWidth]);

  useEffect(() => {
    // Check window size initially
    checkWindowSize();

    // Add resize listener to check window size dynamically
    window.addEventListener('resize', checkWindowSize);

    return () => {
      // Clean up resize listener on component unmount
      window.removeEventListener('resize', checkWindowSize);
    };
  }, [checkWindowSize]);

  return popupWidth;
};
