import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class AccountType extends React.Component {
    // render() {
    //     return <CommonTable title="Account Type" url="/api/AccountType" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Account Type"
                    apiURL="/api/AccountType"
                    listingURL="/api/AccountType/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingAccountTypeListing"}
                    className={"maintenance-listing-datagrid"}
                />
            </div>
        </Container>
    }
}

export default AccountType;