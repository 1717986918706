import React, { useEffect, useState } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import StockTransferForm from './form';
export default function StockTransferListing() {

    const apiURL = `/api/StockTransfer`;
  
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);

    function handleEdit(e){
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="stock-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/StockTransfer/list"
            sortColumn={"DocumentNo"}
            listingTitle={"Stock Transfer"}
            storageName={"StockTransferListingColumnWidth"}
            onAddClick={setCurrentEditID}
            addButtonOnClick={() => {setLockForm(false);}}
            handleEdit={handleEdit}
            dateFilter={true}
        >
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} caption="Date" width="300px" />
            <Column dataField="DocumentNo" caption="Stock Transfer No" width="200px"/>
            <Column dataField="IssueBy" width="100px"	visible={false} />
            <Column dataField="Description" width="400px" />
            <Column dataField="ForexCode" width="140px"/>
            <Column dataField="LocalRate" format={{ type: 'fixedPoint', precision: 2 }} width="100px" />
          
        </Listing>
        </div>

        <StockTransferForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            lockedForm={lockForm}
            apiURL={apiURL}
        />
        
    </Container>;
}