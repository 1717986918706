import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  EmailRule
} from 'devextreme-react/form';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import NumberBox from 'devextreme-react/number-box';
import Validator, { RequiredRule, PatternRule, CompareRule, CustomRule, AsyncRule } from 'devextreme-react/validator';
import './RegisterForm.scss';
import Swal from 'sweetalert2';
import ValidationGroup from 'devextreme-react/validation-group';
import { Popup } from 'devextreme-react/popup';
import { SelectBox } from 'devextreme-react/select-box';
import { DateBox } from 'devextreme-react/date-box';
import { useLocation } from 'react-router-dom';
import utils from "../../utils/common";
import { handleUserProfileAndAuthorization } from '../../api/auth';

import TermsOfUse from '../popup-form/TermsOfUse';
import PrivacyPolicy from '../popup-form/PrivacyPolicy';

export default function RegisterForm() {
  const navigate = useNavigate();
  const { signIn, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const [focus, setFocus] = useState({});
  const firstFormValidatorRef = useRef(null);
  const secondFormValidatorRef = useRef(null);
  const thirdFormValidatorRef = useRef(null);
  const [passwordMode, setPasswordMode] = useState('password');
  const [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [termsDialogVisible, setTermsVisible] = useState(false);
  const [privacyDialogVisible, setPrivacyVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedPlanPromotion, setSelectedPlanPromotion] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [dropDownList, setDropDownList] = useState({
    countryList: [], planList: [], planDurationList: [], promotionList: []
  });

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isUserNameValid, setIsUserNameValid] = useState(true);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [additionalUserCost, setAdditionalUserCost] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const { setUser } = useAuth();
  const formData = useRef({
    email: '',
    userName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    countryID: '',
    fiscalStartYear: '',
    fiscalYearEnd: '',
    planID: '',
    licensedUser: 0,
    subscriptionEndDate: '',
    isFreeTrial: false
  });

  const fetchPlansAndCountries = async () => {
    try {
      const [plansResponse, countriesResponse, planDurationResponse, promotionsResponse] = await Promise.all([
        baseapi.httpget('/api/register/GetGreenPlusPlans'),
        baseapi.httpget('/api/register/GetGreenPlusCountries'),
        baseapi.httpget('/api/register/GetGreenPlusPlansDuration'),
        baseapi.httpget('/api/register/GetGreenPlusPlansPromotion')
      ]);

      setDropDownList({
        planList: plansResponse.data,
        countryList: countriesResponse.data,
        planDurationList: planDurationResponse.data,
        promotionList: promotionsResponse.data
      });

      // Set default country value to Malaysia
      const defaultCountry = countriesResponse.data.find(country => country.Name === 'Malaysia');
      setDefaultCountry(defaultCountry ? defaultCountry.id : null);

      // Fetch the plan from URL parameters
      const params = new URLSearchParams(location.search);
      const planId = params.get('plan');
      if (planId) {
        const selectedPlan = plansResponse.data.find(plan => plan.Name === planId);
        if (selectedPlan) {
          formData.current.plan = selectedPlan;
        }
      }
      setIsLoaded(true);
    } catch (error) {
      console.error('Error fetching plans or countries:', error);
    }
  };

  // Calculate additionalUserCost whenever formData.current.licensedUser or selectedPlan?.LicensedUserPrice changes
  useEffect(() => {
    const cost = (formData.current.licensedUser || 0) * (selectedPlan?.LicensedUserPrice || 0);
    setAdditionalUserCost(cost);
  }, [formData.current.licensedUser, selectedPlan?.LicensedUserPrice]);


  //this method is used to calculate the total fee
  const calculateTotalFee = () => {

    const matchingPromotion = dropDownList.promotionList.find(
      promo => promo.PlanID === selectedPlan?.id && promo.PlanDurationID === selectedDuration?.id
    );
    setSelectedPlanPromotion(matchingPromotion);

    const planPrice = matchingPromotion?.PlanPrice
    const duration = selectedDuration?.Duration || 0;

    return ((planPrice) * duration) + (additionalUserCost * duration);
  };



  useEffect(() => {
    fetchPlansAndCountries();
  }, [location.search]);

  const handlePlanChange = (e) => {
    const selectedId = e.value;
    const selectedPlan = dropDownList.planList.find(plan => plan.id === selectedId);
    if (selectedPlan) {
      setSelectedPlan(selectedPlan)
      formData.current.planID = selectedPlan.id;
      setFocus(prevFocus => ({ ...prevFocus, planID: !!e.value }));
    } else {
      console.error('Selected plan not found');
    }
  };
  useEffect(() => {
    if (isLoaded) { // Run this effect only if the dropdown list has been loaded
      const params = new URLSearchParams(location.search);
      const planId = params.get('plan');
      if (planId) {
        const selectedPlan = dropDownList.planList.find(plan => plan.Name === planId);
        if (selectedPlan) {
          setSelectedPlan(selectedPlan)
          formData.current.plan = selectedPlan.id;
        }
      }
    }
  }, [location.search, dropDownList.planList, isLoaded]);


  const onFocus = useCallback((field) => {
    setFocus(prevFocus => ({ ...prevFocus, [field]: true }));
  }, []);

  const onBlur = useCallback((field) => {
    if (!formData.current[field]) {
      setFocus(prevFocus => ({ ...prevFocus, [field]: false }));
    }
  }, []);

  const sendEmailRequest = (email) => {
    return baseapi.httppost('/oauth/verifyEmail', { email })
      .then(response => {
        const isValid = response.data;
        setIsEmailValid(isValid);
        return isValid;
      })
      .catch(error => {
        console.error('Error verifying email:', error);
        setIsEmailValid(false);
        return false;
      });
  };
  const sendUserNameRequest = (userName) => {
    return baseapi.httppost('/oauth/verifyUserName', { userName })
      .then(response => {
        const isValid = response.data;
        setIsUserNameValid(isValid);
        return isValid;
      })
      .catch(error => {
        console.error('Error verifying user name:', error);
        setIsUserNameValid(false);
        return false;
      });
  };
  const sendCompanyNameRequest = (companyName) => {
    return baseapi.httppost('/oauth/verifyCompanyName', { companyName })
      .then(response => {
        const isValid = response.data;
        setIsCompanyNameValid(isValid);
        return isValid;
      })
      .catch(error => {
        console.error('Error verifying company name:', error);
        setIsCompanyNameValid(false);
        return false;
      });
  };

  const onSubmitFirstForm = useCallback((e) => {
    e.preventDefault();

    const validation = firstFormValidatorRef.current.instance.validate();

    // Ensure form fields validation status is checked first
    if (!validation.isValid) {
      return;
    }

    // Validate both email and username asynchronously
    Promise.all([sendEmailRequest(formData.current.email), sendUserNameRequest(formData.current.userName)])
      .then(([isEmailValid, isUserNameValid]) => {
        if (!isEmailValid) {
          notify({
            message: "Email is already registered",
            type: "error",
            displayTime: 3000,
            closeOnClick: true,
          });
          return;
        }

        if (!isUserNameValid) {
          notify({
            message: "User Name is already registered",
            type: "error",
            displayTime: 3000,
            closeOnClick: true,
          });
          return;
        }

        // If both email and username are valid, move to the next step
        setCurrentStep(2);
      })
      .catch(error => {
        console.error('Error during email/username verification:', error);
      });

  }, [formData, sendEmailRequest, sendUserNameRequest]);


  const onSubmitSecondForm = useCallback(async (e) => {
    e.preventDefault();
    const validation = secondFormValidatorRef.current.instance.validate();
    if (!validation.isValid) {
      return;
    }
    setCurrentStep(3); // Move to third step

  }, [formData]);

  const onSubmitThirdForm = useCallback((e) => {
    e.preventDefault();

    const validation = thirdFormValidatorRef.current.instance.validate();

    // Ensure form fields validation status is checked first
    if (!validation.isValid) {
      return;
    }

    // Validate company name asynchronously
    sendCompanyNameRequest(formData.current.companyName)
      .then(isValidCompanyName => {
        if (!isValidCompanyName) {
          notify({
            message: "Company Name is already registered",
            type: "error",
            displayTime: 3000,
            closeOnClick: true,
          });
          return;
        }

        // If the company name is valid, move to the next step
        setCurrentStep(4);
      })
      .catch(error => {
        console.error('Error during company name verification:', error);
      });

  }, [formData, sendCompanyNameRequest]);

  useEffect(() => {
    const price = calculateTotalFee();
    setTotalPrice(price);
  }, [selectedPlan, selectedDuration, additionalUserCost]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    const price = totalPrice;

    if (user) {
      const userData = JSON.parse(localStorage.getItem('data'));
      const userID = userData.userID;
      const {
        companyName,
        countryID,
        fiscalStartYear,
        fiscalYearEnd,
        planID,
        licensedUser,
        subscriptionEndDate,
        isFreeTrial
      } = formData.current;
      setLoading(true);
      try {
        const response = await baseapi.httppost('/api/UserCompany/index', {
          userID,
          companyName,
          countryID,
          fiscalStartYear,
          fiscalYearEnd,
          planID,
          licensedUser,
          subscriptionEndDate,
          isFreeTrial,
          totalPrice: price,
          planDurationID: selectedDuration?.id
        });

        if (response.data.status) {
          setLoading(false);
          const { companyID } = response.data;
          // Handle successful response
          Swal.fire({
            title: 'Success',
            text: 'Your company has been registered. You will be redirected to the new company page!',
            icon: 'success',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // Call the switch API with the captured companyID
              try {
                const switchResponse = await baseapi.httppost('/api/UserCompany/switch', { id: companyID });
                const { data } = switchResponse;
                if (data.status) {
                  const userProfile = await handleUserProfileAndAuthorization(data.access_token);
                  // Update user context with the fetched profile data
                  setUser({ ...userProfile, email: userProfile.LoginID });
                  navigate('/home');
                } else {
                  notify(switchResponse.data.message, 'error', 2000);
                }
              } catch (switchError) {
                console.error('Error occurred while switching company:', switchError.message);
                notify('An error occurred while switching company. Please try again.', 'error', 2000);
              }
            }
          });
        } else {
          notify(response.data.message, 'error', 2000);
        }


      } catch (error) {
        console.error('Error occurred while submitting:', error.message);
        notify('An error occurred. Please try again.', 'error', 2000);
      }

    } else {
      const {
        email,
        userName,
        phoneNumber,
        password,
        companyName,
        countryID,
        fiscalStartYear,
        fiscalYearEnd,
        planID,
        licensedUser,
        isFreeTrial,
        subscriptionEndDate,
      } = formData.current;
      setLoading(true);
      try {
        const response = await baseapi.httppost('/api/register/index', {
          email,
          userName,
          phoneNumber,
          password,
          companyName,
          countryID,
          fiscalStartYear,
          fiscalYearEnd,
          planID,
          licensedUser,
          subscriptionEndDate,
          totalPrice: price,
          isFreeTrial,
          planDurationID: selectedDuration?.id
        });

        if (response.data.status) {
          setLoading(false);
          // Handle successful response
          Swal.fire({
            title: 'Success',
            text: 'Registration successful! Please check your email for verification.',
            icon: 'success',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/login');
            }
          });
        } else {
          notify(response.data.message, 'error', 2000);
        }
      
      } catch (error) {
        console.error('Error occurred while submitting:', error.message);
        notify('An error occurred. Please try again.', 'error', 2000);
        setLoading(false);
      }
    }
  }, [totalPrice, signIn, selectedDuration]);

  const passwordButton = useMemo(() => ({
    icon: 'images/eye-regular.svg',
    onClick: () => {
      setPasswordMode(prevPasswordMode => prevPasswordMode === 'text' ? 'password' : 'text');
    },
  }), []);

  const confirmPasswordButton = useMemo(() => ({
    icon: 'images/eye-regular.svg',
    onClick: () => {
      setConfirmPasswordMode(prevPasswordMode => prevPasswordMode === 'text' ? 'password' : 'text');
    },
  }), []);

  const passwordComparison = useCallback(() => formData.current.password, []);
  const handleTermsClick = () => {
    setTermsVisible(true);
  };

  const handlePrivacyClick = () => {
    setPrivacyVisible(true);
  };

  const handleTermsClose = () => {
    setTermsVisible(false);
  };

  const handlePrivacyClose = () => {
    setPrivacyVisible(false);
  };

  const asyncCompanyNameValidation = (params) => {
    return sendCompanyNameRequest(params.value);
  };

  useEffect(() => {
    if (user) {
      setCurrentStep(2);
    }
  }, [user]);

  const hasDiscountItems = dropDownList.planDurationList.some(item =>
    item.DiscountPercentage && item.DiscountPercentage > 0
  );
  return (
    <>
      {!user && (
        <img
          src={"/images/nav-logo.png"}
          alt={"Greenplus Accounting"}
          width={110}
          id='nav-logo'
          style={{ paddingTop: '15px' }}
        />
      )}

      <div className="auth-register-container">
        <div className="register-description">
          <div>
            <h1 className='title'>Register</h1>
            <p className='subtitle'>and start using Greenplus Accounting to boost your business!</p>
            <ul className="register-steps">
              <li className={currentStep === 1 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-user"></i></div>
                <div className="text">User Profile</div>
              </li>
              <li className={currentStep === 2 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-file"></i></div>
                <div className="text">Subscription Plan Details </div>
              </li>

              <li className={currentStep === 3 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-users"></i></div>
                <div className="text">Basic Information Set Up</div>
              </li>
              <li className={currentStep === 4 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-calculator"></i></div>
                <div className="text">Review before payment</div>
              </li>
            </ul>
          </div>
        </div>

        <div className='auth-section-container'>
          <div className="auth-content">
            <div className="auth-form" style={{ display: currentStep === 1 ? 'block' : 'none' }}>
              <ValidationGroup ref={firstFormValidatorRef}>
                <form onSubmit={onSubmitFirstForm}>
                  <div className={`auth-input-div ${focus.email ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-envelope"></i></div>
                    <div className="auth-div auth-placeholder">
                      <TextBox
                        value={formData.current.email}
                        onValueChanged={e => {
                          formData.current.email = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, email: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('email')}
                        onFocusOut={() => onBlur('email')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Email"
                        labelMode="outside"

                      >
                        <Validator>
                          <RequiredRule message="Email is required" />
                          <EmailRule message="Email is invalid" />

                        </Validator>
                      </TextBox>
                      <p style={{ marginTop: '10px', marginBlockStart: '5px' }}>
                        *This email will serve as the primary account for handling this subscription.
                      </p>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.userName ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-user"></i></div>
                    <div className="auth-div auth-placeholder">
                      <TextBox
                        value={formData.current.userName}
                        onValueChanged={e => {
                          formData.current.userName = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, userName: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('userName')}
                        onFocusOut={() => onBlur('userName')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="User Name"
                        labelMode="outside"
                        placeholder="e.g., JaneDoe"
                      >
                        <Validator>
                          <RequiredRule message="User Name is required" />
                          <PatternRule
                            pattern="^[^\d]+$"
                            message="User Name must not contain any digits"
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.phoneNumber ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-phone"></i></div>
                    <div className="auth-div auth-placeholder">
                      <TextBox
                        value={formData.current.phoneNumber}
                        onValueChanged={e => {
                          formData.current.phoneNumber = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, phoneNumber: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('phoneNumber')}
                        onFocusOut={() => onBlur('phoneNumber')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Phone Number"
                        labelMode="outside"
                        placeholder="e.g., 0123456789"
                      >
                        <Validator>
                          <RequiredRule message="Phone Number is required" />
                          <PatternRule
                            pattern="^\d{10,11}$"
                            message="Phone Number must be contains only contain only numbers and 10 or 11 digits"
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.password ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-lock"></i></div>
                    <div className="auth-div auth-placeholder">
                      <TextBox
                        value={formData.current.password}
                        onValueChanged={e => {
                          formData.current.password = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, password: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('password')}
                        onFocusOut={() => onBlur('password')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Password"
                        labelMode="outside"
                        mode={passwordMode}

                      >
                        <TextBoxButton
                          name="password"
                          location="after"
                          options={passwordButton}
                        />
                        <Validator>
                          <RequiredRule message="Password is required" />
                          <PatternRule
                            message="Must contain at least 8 characters, one number, and one letter"
                            pattern={/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/}
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.confirmPassword ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-lock"></i></div>
                    <div className="auth-div auth-placeholder">
                      <TextBox
                        value={formData.current.confirmPassword}
                        onValueChanged={e => {
                          formData.current.confirmPassword = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, confirmPassword: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('confirmPassword')}
                        onFocusOut={() => onBlur('confirmPassword')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Confirm Password"
                        labelMode="outside"
                        mode={confirmPasswordMode}

                      >
                        <TextBoxButton
                          name="password"
                          location="after"
                          options={confirmPasswordButton}
                        />
                        <Validator>
                          <RequiredRule message="Confirm Password is required" />
                          <CompareRule
                            message="Password and Confirm Password do not match"
                            comparisonTarget={passwordComparison}
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <input type="submit" className="auth-button" value="Next" />

                </form>
              </ValidationGroup>
            </div>

            <div className="auth-form" style={{ display: currentStep === 2 ? 'block' : 'none' }}>
              <ValidationGroup ref={secondFormValidatorRef}>
                <form onSubmit={onSubmitSecondForm}>
                  <div className={`auth-input-div custom-register  ${focus.planID ? 'focus' : ''}`} >
                    <div className="auth-i"><i class="fas fa-clipboard-list"></i></div>
                    <div className="auth-div">
                      <div className="dropdown">
                        <SelectBox
                          dataSource={dropDownList.planList}
                          onValueChanged={handlePlanChange}
                          alignment="left"
                          width={"auto"}
                          displayExpr={"Name"}
                          valueExpr={"id"}
                          label="Plan"
                          labelMode="outside"
                        >
                          <Validator>
                            <RequiredRule message="Plan is required" />
                          </Validator>
                        </SelectBox>
                      </div>

                    </div>


                    <img
                      src="images/questionicon.svg"
                      style={{
                        height: '20px', // Adjust the height to match the icon
                        width: '20px',  // Adjust the width to match the icon
                        marginLeft: '37px',
                        marginTop: '13px',
                        cursor: 'pointer'
                      }}
                      onClick={() => window.open('https://onlinestar.com.my/landing-page.php/navigate', '_blank')} // Opens the link in a new tab
                    />


                  </div>

                  <div className={`auth-input-div custom-register focus`}>
                    <div className="auth-i"><i className="fas fa-user"></i></div>
                    <div className="auth-div">

                      <TextBox
                        value={selectedPlan?.DefaultLicensedUser || 0}
                        onFocusIn={() => onFocus('licensedUser')}
                        onFocusOut={() => onBlur('licensedUser')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="No of Users(s)"
                        labelMode="outside"
                        readOnly={true}
                      >
                      </TextBox>
                    </div>

                  </div>

                  <div className={`auth-input-div custom-register ${focus.licensedUser ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-user"></i></div>
                    <div className="auth-div">

                      <NumberBox
                        value={formData.current.licensedUser || 0}
                        min={0}
                        showSpinButtons={true}
                        onValueChanged={e => {
                          formData.current.licensedUser = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, licensedUser: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('licensedUser')}
                        onFocusOut={() => onBlur('licensedUser')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Add-On User"
                        labelMode="outside"
                      />
                    </div>
                  </div>


                  <div className={`auth-input-div  ${focus.subscriptionEndDate ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-calendar-alt"></i></div>
                    <div className="auth-div">
                      <div className="dropdown">

                        <SelectBox
                          dataSource={dropDownList.planDurationList}
                          onValueChanged={e => {
                            // Find the selected item by ID
                            const selectedDurationItem = dropDownList.planDurationList.find(item => item.id === e.value);
                            if (selectedDurationItem) {
                              // Assign the Duration to formData.current.subscriptionEndDate
                              formData.current.subscriptionEndDate = selectedDurationItem.Duration;
                              formData.current.isFreeTrial = selectedDurationItem.IsFreeTrial;
                              // Update the selectedPlanDurationID with the ID
                              setSelectedDuration(selectedDurationItem);
                            }
                            // Update the focus state
                            setFocus(prevFocus => ({ ...prevFocus, subscriptionEndDate: !!e.value }));
                          }}
                          dropDownOptions={{
                            height: 'auto', // Automatically adjusts to the content height
                            minHeight: hasDiscountItems ? 170 : 'auto', // Set minHeight based on discount presence
                          }}
                          itemRender={(data, index) => {
                            const hasDiscount = data.DiscountPercentage && data.DiscountPercentage > 0;
                            const isFirstItem = index === 0;

                            return (
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span>{data.Name}</span>
                                {hasDiscount && !isFirstItem && (
                                  <span className="duration-selectbox-discount" style={{ marginLeft: '10px' }}>
                                    Up To {data.DiscountPercentage}% Off
                                  </span>
                                )}
                              </div>
                            );
                          }}
                          alignment="left"
                          width={"auto"}
                          displayExpr={"Name"}
                          valueExpr={"id"}
                          label="Duration"
                          labelMode="outside"
                        >
                          <Validator>
                            <RequiredRule message="Duration is required" />
                          </Validator>
                        </SelectBox>
                        <p style={{ marginTop: '10px' }}>
                          *Please be aware that the subscription plan duration begins after the free trial period ends.
                        </p>
                      </div>
                    </div>
                  </div>

                  <br /> <br />
                  <div className="auth-buttons-container">
                    <button type="button" className="auth-background-white-button" onClick={() => user ? navigate(-1) : setCurrentStep(1)}>Back</button>
                    <input type="submit" className="auth-background-green-button" value="Next" />
                  </div>
                </form>
              </ValidationGroup>
            </div>
            <div className="auth-form" style={{ display: currentStep === 3 ? 'block' : 'none' }}>
              <ValidationGroup ref={thirdFormValidatorRef}>
                <form onSubmit={onSubmitThirdForm}>
                  <div className={`auth-input-div custom-register ${focus.companyName ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-building"></i></div>
                    <div className="auth-div auth-placeholder">
                      <TextBox
                        value={formData.current.companyName}
                        onValueChanged={e => {
                          formData.current.companyName = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, companyName: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('companyName')}
                        onFocusOut={() => onBlur('companyName')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Company Name"
                        labelMode="outside"
                        placeholder="e.g., ABC Sdn Bhd"
                      >
                        <Validator>
                          <RequiredRule message="Company Name is required" />

                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div custom-register  ${focus.countryID ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-flag"></i></div>
                    <div className="auth-div">
                      <div className="dropdown">
                        <SelectBox
                          dataSource={dropDownList.countryList}
                          value={defaultCountry}
                          onValueChanged={e => {
                            formData.current.countryID = e.value;
                            setFocus(prevFocus => ({ ...prevFocus, countryID: !!e.value }));
                          }}
                          alignment="left"
                          width={"auto"}
                          displayExpr={"Name"}
                          valueExpr={"id"}
                          label="Country"
                          labelMode="outside"
                          readOnly="true"
                        >
                          <Validator>
                            <RequiredRule message="Country is required" />
                          </Validator>
                        </SelectBox>


                      </div>
                    </div>
                  </div>
                  <div className={`auth-input-div custom-register  ${focus.fiscalStartYear ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-calendar-alt"></i></div>
                    <div className="auth-div">
                      <DateBox
                        value={formData.current.fiscalStartYear}
                        onValueChanged={e => {
                          formData.current.fiscalStartYear = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, fiscalStartYear: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('fiscalStartYear')}
                        onFocusOut={() => onBlur('fiscalStartYear')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Fiscal Start Year"
                        labelMode="outside"
                        type="date"
                        displayFormat="dd/MM/yyyy"
                      >
                        <Validator>
                          <RequiredRule message="Fiscal Start Year is required" />
                          <CustomRule
                            message="Fiscal Start Year cannot be in the future"
                            validationCallback={e => {
                              // Ensure the value is a Date object before comparison
                              const selectedDate = new Date(e.value);
                              const today = new Date();
                              // Set the time of 'today' to 00:00:00 to only compare the date part
                              today.setHours(0, 0, 0, 0);
                              return selectedDate <= today;
                            }}
                          />  </Validator>
                      </DateBox>
                    </div>
                  </div>

                  <div className={`auth-input-div custom-register  ${focus.fiscalYearEnd ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-calendar-alt"></i></div>
                    <div className="auth-div">
                      <DateBox
                        value={formData.current.fiscalYearEnd}
                        onValueChanged={e => {
                          formData.current.fiscalYearEnd = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, fiscalYearEnd: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('fiscalYearEnd')}
                        onFocusOut={() => onBlur('fiscalYearEnd')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Fiscal End Year"
                        labelMode="outside"
                        type="date"
                        displayFormat="dd/MM/yyyy"
                      >
                        <Validator>
                          <RequiredRule message="Fiscal End Year is required" />
                          <CustomRule
                            message="Fiscal End Year must be greater than Fiscal Start Year"
                            validationCallback={e => {
                              // Ensure the value is a Date object before comparison
                              const startYear = new Date(formData.current.fiscalStartYear);
                              const endYear = new Date(e.value);
                              return endYear > startYear;
                            }}
                          />
                        </Validator>
                      </DateBox>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="auth-buttons-container">
                    <button type="button" className="auth-background-white-button" onClick={() => setCurrentStep(2)}>Back</button>
                    <input type="submit" className="auth-background-green-button" value="Next" />
                  </div>
                </form>
              </ValidationGroup>

            </div>
            <div className="auth-form" style={{ display: currentStep === 4 ? 'block' : 'none' }} >
              <form onSubmit={onSubmit}>

                <p className='review-subtitle'>
                  Please review details below before proceed to payment.
                </p>

                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Company Name
                  </div>
                  <div className="auth-input-value">
                    {formData.current?.companyName}
                  </div>
                </div>

                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    User Provided by Plan
                  </div>
                  <div className="auth-input-value">
                    {Number(selectedPlan?.DefaultLicensedUser)}
                  </div>
                </div>
                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    {formData.current?.isFreeTrial ? 'Selected Duration' : 'Duration'}
                  </div>
                  <div className="auth-input-value">
                    {selectedDuration?.Name}
                  </div>
                </div>
                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Add On User
                  </div>
                  <div className="auth-input-value">
                    {formData.current?.licensedUser}
                    <span className="price-display">
                      {!formData.current?.isFreeTrial && (
                        ` - RM ${utils.numberToCurrency(additionalUserCost)}`
                      )}
                    </span>
                  </div>
                </div>



                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Subscription Plan
                  </div>
                  <div className="auth-input-value">
                    {selectedPlan?.Name}
                    <span className="price-display">
                      {!formData.current?.isFreeTrial && (
                        ` - RM ${utils.numberToCurrency(selectedPlanPromotion?.PlanPrice)}`
                      )}
                    </span>
                  </div>
                </div>

                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  {formData.current?.isFreeTrial ? null : (
                    <>
                      <div className="auth-label">
                        Total Fee
                      </div>
                      <div className="auth-input-value">
                        RM {utils.numberToCurrency(totalPrice)} <br />
                        <div className="price-display">
                          [ RM {utils.numberToCurrency(selectedPlanPromotion?.PlanPrice || 0)} x {selectedDuration?.Duration || 0} Month{(selectedDuration?.Duration || 0) > 1 ? 's' : ''} ] +
                          [ RM {utils.numberToCurrency(additionalUserCost)} x {selectedDuration?.Duration || 0} Month{(selectedDuration?.Duration || 0) > 1 ? 's' : ''} ]
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <p className='label-sign-up' style={{ marginTop: '60px', marginBottom: '50px' }}>
                  *By signing up, you agree to our&nbsp;
                  <span style={{ color: '#46CF64', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTermsClick}>Terms of Use</span>
                  &nbsp;and acknowledge you've read our&nbsp;
                  <span style={{ color: '#46CF64', textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyClick}>Privacy Policy</span>.
                </p>

                {loading && (
                  <div className="loading-indicator-container">
                    <div className="loader"></div>
                    <br />
                  </div>
                )}
                {!loading &&
                  <div className="auth-buttons-container">
                    <button type="button" className="auth-background-white-button" onClick={() => {
                      setCurrentStep(3);

                    }}>Back</button>

                    <input
                      type="submit"
                      className="auth-background-green-button"
                      value={formData.current?.isFreeTrial ? "Start One-Month Free Trial" : "Make Payment"}
                    />

                  </div>
                }
              </form>
            </div>
          </div>

          {!user && (
            <div className="auth-footer">
              <label htmlFor="rememberMe" className="auth-footer-label">Already have an account?</label>
              <Link to="/login">Login</Link>
            </div>
          )}
        </div>
      </div>

      <img className="wave" src="images/wave.svg" alt="wave" />

      <TermsOfUse
        visible={termsDialogVisible}
        onClose={handleTermsClose}

      />
      <PrivacyPolicy
        visible={privacyDialogVisible}
        onClose={handlePrivacyClose}

      />



    </>


  );
}
