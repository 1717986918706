import PopupForm from "../../../components/popup-form/PopupFormV2";
import { useEffect, useState, useRef } from "react";
import PaymentVoucherForm from "./form-layout/PaymentVoucherForm";
import OfficialReceiptForm from "./form-layout/OfficialReceiptForm";
import utils, { loading, closeLoading, CheckChequeNoValid } from "../../../utils/common";
import baseapi from "../../../api/baseapi";
import { CheckBox } from "devextreme-react";
import { glOfficialReceipt, glPaymentVoucher } from "../../../utils/lookupstore";

export default function CashBookEntryForm(props) {
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});
	const [startValidation, setStartValidation] = useState(0);
	const formType = props.formType;
	const [defaultReportID, setDefaultReportID] = useState("");
	const [reportURL, setReportURL] = useState(null);
	const [defaultValue, setDefaultValue] = useState({});

	const tempForm = useRef({});
	const formID = useRef(null);
	const formRef = useRef(null);
	const formRead = useRef(false);
	const glFormRef = useRef(null);
	const projectLookupRef = useRef(null);
	const journalTypeLookupRef = useRef(null);
	const payToLookupRef = useRef(null);
	const bankLookupRef = useRef(null);
	const forexLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const childrenGridRef = useRef(null);
	const [reportDocumentNo, setReportDocumentNo] = useState(null);

	useEffect(() => {
		if(!utils.isNullOrEmpty(formValue["DocumentNo"])){
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	useEffect(() => {
		if (formType === "Payment Voucher") {
			setDefaultReportID("z90K27Q1rAg%3d");
			setReportURL(glPaymentVoucher.url);
		} else if (formType === "Official Receipt") {
			setDefaultReportID("qtcwdk1be3w%3d");
			setReportURL(glOfficialReceipt.url);
		}
	}, [props.formType]);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	const clearForm = () => {
		props.closePopup(null);
		glFormRef.current.setValue({});
		glFormRef.current.setChildren([]);
		formRead.current = false;
		formID.current = null;
	};

	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${formType}...`);
		else loading(`Loading Existing ${formType}...`);

		baseapi.httpget("/api/GLCashBook/Get", { id: formID.current || "new", documentType: props.documentType }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if(!utils.isNullOrEmpty(data.defaultValues)){
				setDefaultValue(data.defaultValues);
			}

			tempForm.current = data.model.Parent;
			glFormRef.current.setValue(tempForm.current);

			if (formID.current === "new") {
				utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
				glFormRef.current.editingForm(false);
			} else {
				glFormRef.current.setChildren(data.model.Children);
				glFormRef.current.editingForm(true);
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const handleSubmit = (e) => {
		if(!utils.isNullOrEmpty(e.validData)){
			const validData = e.validData;

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};
	
			loading(`Saving ${formType}...`);

			baseapi.httppost(utils.extendUrlVar(`/api/GLCashBook/save`, { id: formID.current || "", del: false, documentType: props.documentType }), submittedForm).then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
				if(data.docNo) setReportDocumentNo(data.docNo);
			});
			
		}		
	};

	const valueOnChange = (e) => {
		if (!utils.isNullOrEmpty(e.value)) {
			const dataField = e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);
		}
	};

	const customizedAsyncValidation = async () => {
		return await CheckChequeNoValid(formRef, formValue["ChequeNo"], formValue["DocumentNo"]);
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"90%"}
				height={"90%"}
				defaultSelectedReport={defaultReportID}
				reportURL={reportURL}
				ReportDocumentNo={reportDocumentNo}
				headerClassName={"gl-module-theme-color gl-module-title-font-size"}
				creditChecking={false}
				onShown={onInitialized}
				title={formType}
				apiURL={props.apiURL}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				onValidating={setStartValidation}
				startValidation={startValidation}
				customizedAsyncValidation={customizedAsyncValidation}
				requiredColumns={{
					Children: ["ChartAccountID"]
				}}
				filterRules={{
					Children:[
						{name: "ChartAccountID", rules : [{name : "Required"}]},
						{name: "GLDescription", rules : [{name : "Required"}]},
						{name: "ForexAmount", rules : [{name : "Min", value: 1}]},
					]
				}}
				copyFields={{
					Parent: formValue,
					Children : utils.childrenGridGetSource(childrenGridRef)
				}}
				onPasteForm={(e) => {
					if (!utils.isNullOrEmpty(glFormRef.current)) glFormRef.current.setValue(e.Parent);
				}}
				copyStorage={"GLCashBookCopiedData"}
				formData={formValue}
				isEditing={isEditing}
				lookupValidateRef={[projectLookupRef, payToLookupRef, bankLookupRef, forexLookupRef, salesmanLookupRef, areaLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				{formType === "Payment Voucher" && (
					<PaymentVoucherForm
						ref={glFormRef}
						formRef={formRef}
						gridRef={childrenGridRef}
						startValidation={startValidation}
						lookupRef={{
							projectLookupRef: projectLookupRef,
							payToLookupRef: payToLookupRef,
							bankLookupRef: bankLookupRef,
							forexLookupRef: forexLookupRef,
							salesmanLookupRef : salesmanLookupRef,
							areaLookupRef: areaLookupRef
						}}
						onValueChanged={(e) => {
							const parent = e.parent;
							tempForm.current = parent;
							setFormValue(tempForm.current);
						}}
						defaultValue={defaultValue}
					/>
				)}

				{formType === "Official Receipt" && (
					<OfficialReceiptForm
						ref={glFormRef}
						formRef={formRef}
						gridRef={childrenGridRef}
						startValidation={startValidation}
						lookupRef={{
							projectLookupRef: projectLookupRef,
							payToLookupRef: payToLookupRef,
							bankLookupRef: bankLookupRef,
							forexLookupRef: forexLookupRef,
							salesmanLookupRef : salesmanLookupRef,
							areaLookupRef: areaLookupRef
						}}
						onValueChanged={(e) => {
							const parent = e.parent;
							tempForm.current = parent;
							setFormValue(tempForm.current);
						}}
						defaultValue={defaultValue}
					/>
				)}
			</PopupForm>
		</div>
	);
}
