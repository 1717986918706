import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import { Button } from 'devextreme-react';
import './UserPanelV2.scss';
import CompanySelectionForm from '../popup-form/CompanySelectionForm';


export default function UserPanel(props) {
  const { user, signOut, showCompanySelection } = useAuth();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(null);
  const [companySelectionVisible, setCompanySelectionVisible] = useState(false);
  const [planSelectionVisible, setPlanSelectionVisible] = useState(false);
  function navigateToProfile() {
    navigate("/profile");
  }
  
  function navigateToCompanyProfile() {
    navigate("/companyprofile");
  }
  function navigateToSubscription() {
    navigate("/subscribe");
  }
  const handleCompanySelectionClose = () => {
    setCompanySelectionVisible(false);
  };
  const menuItems = useMemo(() => ([
    {
      text: user.email,
      icon: 'user',
      onClick: navigateToProfile
    },
    {
      text: 'Company Profile',
      icon: 'product',
      onClick: navigateToCompanyProfile
    },
    {
      text: 'Switch Company',
      icon: 'refresh',
      onClick: () => setCompanySelectionVisible(true) 
    },
    {
      text: 'Subscription Plan',
      icon: 'textdocument',
      onClick: navigateToSubscription
    },
    {
      text: 'Logout',
      icon: 'runner',
      onClick: signOut
    }
  ]), [signOut]);

  useEffect(() => {
    if (props.isMobile !== undefined) {
      setIsMobile(props.isMobile);
    }
  }, [props.isMobile]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        {/* <div className={'image-container'}>
          <div
            style={{
              background: `url(${user.avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover'
            }}
            className={'user-image'} />
        </div> */}
        {
          isMobile !== true && <Button
            text='Logout'
            stylingMode="text"
            className='logout-btn'
            onClick={signOut}
          />
        }

        {
          isMobile !== true && <span className={"user-panel-separator"}>|</span>
        }
        <Button
          className='nav-user-panel'
          stylingMode="text"
          component={() => {
            return <span>
              <i className="dx-icon-globe" style={{ marginRight: "5px", position: "relative", top: "2.5px" }}></i>
              <span className='user-panel-company-name'>{props.companyName}</span>
              <i className="dx-icon-chevrondown" style={{ marginLeft: "5px" }}></i>
            </span>
          }}
        />


      </div>

      <ContextMenu
        items={isMobile ? menuItems : menuItems.filter(c => c.text !== "Logout")}
        target={'.nav-user-panel'}
        showEvent={'dxclick'}
        // width={210}
        cssClass={'user-menu'}
      >
        <Position my={'top center'} at={'bottom center'} />
      </ContextMenu>

      <CompanySelectionForm
        visible={companySelectionVisible}
        onClose={handleCompanySelectionClose}
        backHiding={false}
        confirmAlert={true}
      />

   
    </div>
  )
}