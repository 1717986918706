import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import DataGrid, { Editing, KeyboardNavigation, ColumnChooser, Toolbar, Item as ToolbarItem
, LoadPanel } from "devextreme-react/data-grid";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import utils from "../../utils/common";
import { Button } from "devextreme-react/button";
import CustomizedGridHtmlEditor from '../datagrid-html-editor/GridHtmlEditor';

export default forwardRef(function ChidlrenDatagrid(props, ref){

    const childrenGridRef = useRef(null);
    const gridHtmlEditorRef = useRef(null);
    const currentFocusCellIndex = useRef(null);
    const moreDescriptionIndex = useRef(null);
    const [gridHeight, setGridHeight] = useState(180);
    const [parentValue, setParentValue] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [openMoreDescription, setOpenMoreDescription] = useState(0);
	const storageName = !utils.isNullOrEmpty(props.storageName) ? props.storageName : null;
    
    useImperativeHandle(ref, () => ({
            addRow(){
                addRowAction();
            },
            setCurrentFocusCellIndex(value){
                currentFocusCellIndex.current = value;
            },
            getCurrentFocusCellIndex(){
                return currentFocusCellIndex.current;
            },
            getDataSource(){
                const rows = childrenGridRef.current.instance.getVisibleRows();
                const source = [];
                if(Array.isArray(rows)){
                    rows.forEach((element) => {
                        source.push(element.data);
                    });
                }
                return [...dataSource];
            },
            length(){
                return dataSource.length;
            },
            setDataGridSource(value){
                setDataSource(value);
            },
            setParent(value){
                setParentValue(value);
            },
            getStorageName(){
                return props.storageName;
            },
            getChildrenGridName(){
                return props.name;
            }
        })
    );

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.defaultDescription) && utils.isNullOrEmpty(parentValue["Description"])){
            const copied = {...parentValue};
            copied["Description"] = props.defaultDescription;
            setParentValue(copied);
        }
    }, [props.defaultDescription]);

    useEffect(() => {
        if(dataSource.length <= 3){
            const length = (dataSource.length === 0 ? 0 : dataSource.length - 1);
            const fixedLength = 180;
            setGridHeight(fixedLength + (35 * length));
        }
        else{
            setGridHeight(240);
        }
    }, [dataSource]);

    // When initialising a new row, takes the props's default column values,
    // which is usually taken from the server response.
    const onInitNew = () => {
        if(utils.isNullOrEmpty(props.defaultColumnValues) || !utils.isObject(props.defaultColumnValues())){
            return {};
        }
        else
        {
            if(props.disabledCommonColumns !== true){
                return Object.assign(props.defaultColumnValues(), utils.CommonCalculationColumns);
            }
            else{
                return props.defaultColumnValues();
            }
        }
    };

    // Adds a new data row.
    const addRowAction = () => {
		setDataSource([...dataSource, utils.GridAddRow(childrenGridRef.current.instance.option("columns"), onInitNew())]);
	};

    const onFocusedCellChanged = (e) => {
        var columnIndex = null;
        var rowIndex = null;
        var dataField = null;
        var rowID = null;
        var rowData = null;

        if(!utils.isNullOrEmpty(e) && utils.isObject(e)){
            columnIndex = e?.columnIndex;
            rowIndex = e?.rowIndex;
            dataField = e?.column?.dataField;
            rowID = e?.row?.key;
            rowData = e?.row?.data;

            if(!utils.isNullOrEmpty(props.lookupFields) && Array.isArray(props.lookupFields)){
                const arr = props.lookupFields;
    
                if(arr.includes(dataField)){
                    //Used to prevent the bug when the user clicks another lookup when one lookup is in editing state
                    if (!utils.isNullOrEmpty(currentFocusCellIndex.current)) {
                        const previousFocusField = currentFocusCellIndex.current.dataField;
                        const previousFocusRowIndex = currentFocusCellIndex.current.rowIndex;
                        const currentFocusRowIndex = e.rowIndex;
    
                        // Closes the current cell being edited if essentially the column (data field) or row is different.
                        if (dataField !== previousFocusField || (dataField === previousFocusField && currentFocusRowIndex !== previousFocusRowIndex)) {
                            childrenGridRef.current.instance.closeEditCell();
                        }
                    }
    
                    // Set state to allow other grid lookups to be displayed if clicked on.
                    if(!utils.isNullOrEmpty(props.gridLookupOpen)){
                        props.gridLookupOpen(true);
                    }
                }
            }
        }

        // Saves information on the currently focused cell and the previously edited row index.
		currentFocusCellIndex.current = {
			columnIndex: columnIndex,
			rowIndex: rowIndex,
			dataField: dataField,
			rowID: rowID,
			rowData: rowData,
			lastEditRow: utils.isNullOrEmpty(currentFocusCellIndex.current) ? null : currentFocusCellIndex.current["lastEditRow"]
		};

        if(!utils.isNullOrEmpty(props.onFocusedCellChanged)){
            props.onFocusedCellChanged(currentFocusCellIndex.current);
        }
	};

    // Function to run when cell is clicked, this is run after the
    // first invocation of onFocusedCellChanged, and calls the 
    // onFocusedCellChange method again to reopen a lookup field.
    const onCellClick = (e) => {
        if (e.rowType === "data"){
            onFocusedCellChanged(e);
        } 
        if(!utils.isNullOrEmpty(props.onCellClick)){
            props.onCellClick();
        }
    };

    const setLastEditRow = (editIndex) =>{
        if(!utils.isNullOrEmpty(currentFocusCellIndex.current)){
            currentFocusCellIndex.current["lastEditRow"] = editIndex;
        }
    }

    const onRowUpdating = (e) => {
        const editIndex = dataSource.indexOf(e.oldData);
        setLastEditRow(editIndex);

        if(!utils.isNullOrEmpty(props.onRowUpdating)){
            props.onRowUpdating(e);

            // if (!utils.isNullOrEmpty(currentFocusCellIndex.current)) {
            //     const rowIndex = currentFocusCellIndex.current.rowIndex;
            //     const lastEditedRow = currentFocusCellIndex.current.lastEditRow;
            //     if (rowIndex === childrenGridRef.current.instance.totalCount() - 1 && rowIndex === lastEditedRow) {
            //         addRowAction();
            //     }
            // }
        }
    };

    const onRowUpdated = (e) => {
        if(!utils.isNullOrEmpty(props.onRowUpdated)){
            props.onRowUpdated(e);
        }

        // Adds a new data row if the currently focused cell is on the last row.
        if (!utils.isNullOrEmpty(currentFocusCellIndex.current)) {
            const rowIndex = currentFocusCellIndex.current.rowIndex;
            const lastEditedRow = currentFocusCellIndex.current.lastEditRow;
            if (rowIndex === childrenGridRef.current.instance.totalCount() - 1 && rowIndex === lastEditedRow) {
                if(props.autoAddRow !== false){
                    addRowAction();
                }
            }
        }
    };

    // Setting column width and save settings to local storage.
    const onRowPrepared = (e) => {
		if (e.rowType === "header" && storageName !== null) {
			const find = e.columns.find(c => c.command === "transparent");
			//Ignore the fixed columns
			if(utils.isNullOrEmpty(find)){
				const columns = e.columns;
				const previousRecord = getFromLS(storageName);

				if (previousRecord === undefined || previousRecord.length !== columns.length) {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = null;
					}
				} else {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
					}
				}

				saveToLS(storageName, columns);
			}
		}
	};

    // Function to run when there are UI changes.
    // Used to save column widths to local storage.
	const onOptionChanged = (e) => {
		if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
			//Sample format : columns[0].width
			const columns = getFromLS(storageName);
			const columnIndex = parseInt(e.fullName.substring(8, 9));
			const arrayIndex = columns.findIndex((x) => x.index === columnIndex);
			// Save the new width
			columns[arrayIndex]["columnWidth"] = e.value;
			saveToLS(storageName, columns);
		}
	};

    const onParentValueChanged = (e) => {
        const dataField = e.element.getAttribute("dataField");
        const copied = {...parentValue};
        copied[dataField] = e.value;
        setParentValue(copied);

        if(!utils.isNullOrEmpty(props.onParentValueChanged)){
            props.onParentValueChanged(e);
        }
    };

    const moreDescriptionAction = (e) => {
        const columnIndex = e.columnIndex;
        const rowIndex = e.rowIndex;
        const dataField = e.column.dataField;
        moreDescriptionIndex.current = {
            columnIndex: columnIndex,
            rowIndex: rowIndex,
            dataField: dataField,
        }
        const copiedChildren = [...dataSource];
        
        // Set value for html editor
        if(!utils.isNullOrEmpty(gridHtmlEditorRef.current)){
            if(copiedChildren.length >= rowIndex){
                gridHtmlEditorRef.current.setValue(copiedChildren[rowIndex][dataField])
            }
        }

        setOpenMoreDescription(crypto.randomUUID());
    };

    const moreDescriptionSaving = (value) => {
        const copiedChildren = [...dataSource];
        const description = moreDescriptionIndex.current;
        copiedChildren[description.rowIndex][description.dataField] = value;
        setDataSource(copiedChildren);
    };

    return(
        <div>
            <DataGrid
                ref={childrenGridRef}
                dataSource={dataSource}
                width={utils.isNullOrEmpty(props.width) ? "100%" : props.width}
                height={utils.isNullOrEmpty(props.height) ? `${gridHeight}px` : props.height}
                // height={props.height}
                keyExpr={props.keyExpr}
                showBorders={props.showBorders}
                columnAutoWidth={utils.isNullOrEmpty(props.columnAutoWidth) ? false : props.columnAutoWidth}
                allowColumnReordering={utils.isNullOrEmpty(props.allowColumnReordering) ? true : props.allowColumnReordering}
                allowColumnResizing={utils.isNullOrEmpty(props.allowColumnResizing) ? true : props.allowColumnResizing}
                columnResizingMode='widget'
                onRowPrepared={onRowPrepared}
                onOptionChanged={onOptionChanged}
                onRowUpdating={onRowUpdating}
                onRowUpdated={onRowUpdated}
                onRowRemoved={props.onRowRemoved}
                onRowRemoving={props.onRowRemoving}
                onFocusedCellChanged={onFocusedCellChanged}
                onCellClick={onCellClick}
                disabled={props.disabled}
                paging={props.paging}
                pager={props.pager}
                // scrolling={utils.isNullOrEmpty(props.scrolling) ? {mode: "standard"} : props.scrolling}
                scrolling={props.scrolling}
                onEditorPreparing={props.onEditorPreparing}
                onEditorPrepared={props.onEditorPrepared}
                onToolbarPreparing={props.onToolbarPreparing}
            >
                <LoadPanel enabled={props.loadPanel} />

                {(utils.isNullOrEmpty(props.defaultKeyboardNavigation) || props.defaultKeyboardNavigation === true ) && 
                    <KeyboardNavigation editOnKeyPress={true} enterKeyAction={"moveFocus"} enterKeyDirection={"row"} />
                }
                
                <ColumnChooser enabled={utils.isNullOrEmpty(props.enabledColumnChooser) ? true : props.enabledColumnChooser} />

                <Editing 
                    allowAdding={utils.isNullOrEmpty(props.allowAdding) ? true : props.allowAdding} 
                    allowUpdating={utils.isNullOrEmpty(props.allowUpdating) ? true : props.allowUpdating} 
                    allowDeleting={utils.isNullOrEmpty(props.allowDeleting) ? true : props.allowDeleting} 
                    mode='cell' 
                    useIcons={true} 
                    newRowPosition={"last"} 
                />

                {props.children.map((child, index) => {
                    if (!utils.isNullOrEmpty(child.props)) {
                        const propsType = child.type.OptionName;
                        // console.log("child prop", child.props)
                        if(propsType === "columns"){
                            if (child.props.type !== "buttons") {
                                const column = child.props;
                               
                                const columnWidth = !utils.isNullOrEmpty(column.width) ? column.width : 
                                (utils.getColumnWidth(column.dataField, storageName) === null ? utils.childGridDefaultSetting(column.dataField, "Width") : utils.getColumnWidth(column.dataField, storageName));
                                
                                const columnIndex = utils.getColumnVisibleIndex(column.dataField, storageName) === null ? 
                                (column.visible !== false ? index : undefined) 
                                : utils.getColumnVisibleIndex(column.dataField, storageName);

                                const columnEditorOptions = utils.isNullOrEmpty(column.editorOptions) ? {} : column.editorOptions;

                                //Default Settings for editor options
                                columnEditorOptions["maxLength"] = columnEditorOptions["maxLength"] ?? utils.childGridDefaultSetting(column.dataField, "Max Length");

                                //Default Settings for editor options
                                columnEditorOptions["readOnly"] = columnEditorOptions["readOnly"] ?? utils.childGridDefaultSetting(column.dataField, "Read Only");
                                
                                //Default Settings for editor options
                                columnEditorOptions["max"] = columnEditorOptions["max"] ?? utils.childGridDefaultSetting(column.dataField, "Max");

                                //Default Settings for editor options
                                columnEditorOptions["min"] = columnEditorOptions["min"] ?? utils.childGridDefaultSetting(column.dataField, "Min");

                                const gridProps = {
                                    width : columnWidth !== 0 ? columnWidth : 5, 
                                    visibleIndex: columnIndex,
                                    caption: utils.isNullOrEmpty(column.caption) ? utils.childGridDefaultSetting(column.dataField, "Caption") : column.caption,
                                    editorOptions : columnEditorOptions,
                                    format: utils.isNullOrEmpty(column.format) ? utils.childGridDefaultSetting(column.dataField, "Format") : column.format,
                                    customizeText: utils.isNullOrEmpty(column.customizeText) ? utils.childGridDefaultSetting(column.dataField, "Customize Text") : column.customizeText,
                                }

                                // MoreDescription customization
                                if(column.isHtmlEditor){
                                    gridProps["allowEditing"] = false;
                                    gridProps["cellRender"] = (e) => (
                                        <Button
                                            className={!utils.isNullOrEmpty(e.data.MoreDescription) ? "more-description-with-data" : ""}
                                            icon='textdocument'
                                            height={"16px"}
                                            stylingMode="text"
                                            onClick={(a) => {moreDescriptionAction(e)}}
                                        />
                                    );
                                }

                                return React.cloneElement(child, gridProps);       
                            }
                        }
                        else{
                            return child;
                        }
                    
                    }
                })}

                {(utils.isNullOrEmpty(props.defaultToolbar) || props.defaultToolbar === true ) && 
                    <Toolbar>
                        {(utils.isNullOrEmpty(props.enabledDescription) || props.enabledDescription === true ) && <ToolbarItem 
                            location='before' 
                            text="Description" 
                            cssClass={'popup-form-toolbar-section'}
                        />
                        }

                        {(utils.isNullOrEmpty(props.enabledDescription) || props.enabledDescription === true ) && <ToolbarItem 
                            location='before' 
                            widget={"dxTextBox"}
                            cssClass={'popup-form-toolbar-section popup-form-toolbar-description'}
                            options={{
                                value: parentValue["Description"],
                                onValueChanged : (e) => onParentValueChanged(e),
                                elementAttr : { dataField: "Description" },
                                width: "55vw"
                            }}                  
                        />
                        }

                        {
                            props.disabledAdd !== true && <ToolbarItem 
                                widget={"dxButton"} 
                                options={{
                                    icon: "add",
                                    onClick: addRowAction
                                }}
                            />
                        }       
                        <ToolbarItem name='columnChooserButton' />
                    </Toolbar>
                }
            </DataGrid>

            <CustomizedGridHtmlEditor
                ref={gridHtmlEditorRef}
				open={openMoreDescription}
                onSaving={moreDescriptionSaving}
			/>
        </div>

    );
});