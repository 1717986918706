import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import ARSalesForm from './form';
import SalesCashSalesForm from '../../Sales-Module/cash-sales/form';
import SalesInvoiceMainForm from '../../Sales-Module/invoice-main/form';
import WorkshopInvoiceForm from '../../Workshop-Module/Invoice/form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import baseapi from '../../../api/baseapi';

export default function ARSalesListing() {

    const apiURL = `/api/ARSales`;
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);
    const [currentEditID3, setCurrentEditID3] = useState(null);
    const [currentEditID4, setCurrentEditID4] = useState(null);
    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e) {
        const rowData = e.row.data;
        baseapi.httpget("/api/ARSales/CheckSalesCopied", { docNo: rowData.DocumentNo }).then((response) => {
            const { data } = response;
            if (!utils.isNullOrEmpty(data)) {
                if (data.isCopied) {
                    if (data.type === "Cash Sales") {
                        setCurrentEditID2(data.id);
                    }
                    else if(data.type === "Invoice") {
                        setCurrentEditID3(data.id);
                    }else{
                        setCurrentEditID4(data.id);
                    }
                }
                else {
                    setCurrentEditID(rowData.id);
                }
            }
        });
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentEditID3(id);
        setCurrentEditID4(id);
    }

    return <Container>
        <div id='normal-listing'>
            <Listing
                className="ar-listing-datagrid"
                apiURL={apiURL}
                listingURL="/api/ARSales/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"AR Invoice"}
                storageName={"ARSalesListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexID" caption={"Forex"} >
                    <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
                </Column>
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        <ARSalesForm
            formID={currentEditID}
            closePopup={closePopup}
            title={"A/R Invoice"}
            apiURL={apiURL}
        />

        <SalesCashSalesForm
            formID={currentEditID2}
            closePopup={closePopup}
            title={"Cash Sales Entry"}
            apiURL={`/api/SalesCashSales`}
        />

        <SalesInvoiceMainForm
            formID={currentEditID3}
            closePopup={closePopup}
            title={"Invoice Entry"}
            apiURL={`/api/SalesInvoiceMain`}
        />

        <WorkshopInvoiceForm
            formID={currentEditID4}
            closePopup={closePopup}
            title={"Workshop Invoice Entry"}
            apiURL={`/api/SalesInvoiceMain`}

        />
    </Container>;
}