import React, { useEffect, useState, useRef } from "react";
import { Column, Lookup } from "devextreme-react/data-grid";
import { forexOptions } from "../../../utils/lookupstore";
import Container from "../../../components/container";
import APCreditNoteForm from "./form";
import Listing from "../../../components/listing/newListing";
import EInvoiceMain from "../../e-invoice/EInvoiceMain";
export default function APCreditNoteListing() {
	const apiURL = `/api/APCreditNote`;
	const [currentDetailID, setCurrentDetailID] = useState(null);
	const [dropDownList, setDropDownList] = useState({
		forexList: []
	});
	const [invType, setInvType] = useState(null);
	const [currentEditID, setCurrentEditID] = useState(null);

	useEffect(() => {
		Promise.allSettled([forexOptions.store.load()]).then((lists) => {
			setDropDownList({
				forexList: lists[0].value.data
			});
		});
	}, []);

	function handleDetail(e) {
		setInvType('12');
		setCurrentDetailID(e.row.data.DocumentNo);
	}

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
	}

	return (
		<Container>
			<div id="normal-listing">
				<Listing
					className='ap-listing-datagrid'
					apiURL={apiURL}
					listingURL='/api/APCreditNote/list'
					sortColumn={"DocumentDate"}
					sortOrder={"desc"}
					listingTitle={"AP Credit Note"}
					storageName={"APCreditNoteListingColumnWidth"}
					onAddClick={setCurrentEditID}
					handleEdit={handleEdit}
					detailshow={true}
					handleDetail={handleDetail}
				>
					<Column dataField='DocumentDate' format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField='DocumentNo' />
					<Column dataField='SupplierCode' />
					<Column dataField='SupplierName' />
					<Column dataField='ForexID' caption={"Forex"} >
						<Lookup dataSource={dropDownList.forexList} valueExpr='id' displayExpr={"code"} allowClearing={true} searchEnabled={true} />
					</Column>
					<Column dataField='MainForexAmount' caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='MainLocalAmount' caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='OutstandingAmount' caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2 }} />
				</Listing>
			</div>

			<EInvoiceMain
				formID={currentDetailID}
				closePopup={setCurrentDetailID}
				apiURL={apiURL}
				title={"E-Invoice Main"}
				invoiceType={invType}

			/>
			<APCreditNoteForm
				formID={currentEditID}
				closePopup={setCurrentEditID}
				apiURL={apiURL}
				title={"A/P Credit Note"}
			/>
		</Container>
	);
}
