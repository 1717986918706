import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view'; // Import ScrollView
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import Swal from 'sweetalert2';

const ManageUserForm = ({ visible, onClose, decrementRemainingSeat, incrementRemainingSeat, remainingSeatSection, remainingSeat, fetchSeatData }) => {
    const [selectedIndices, setSelectedIndices] = useState([]); // Track multiple selected indices
    const [userList, setUserList] = useState([]); // State for user list
    const [loading, setLoading] = useState(true); // Loading state
    const [popupWidth, setPopupWidth] = useState("55%");
    const userRefs = useRef([]); // Ref array to track each user element
    const scrollViewRef = useRef(null); // Ref for the ScrollView

    useEffect(() => {
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else {
                setPopupWidth("55%");
            }
        };

        updatePopupWidth();
        window.addEventListener('resize', updatePopupWidth);
        return () => {
            window.removeEventListener('resize', updatePopupWidth);
        };
    }, []);

    // Fetch users when popup becomes visible
    useEffect(() => {
        if (visible) {
            const fetchUsers = async () => {
                try {
                    setLoading(true);
                    const response = await baseapi.httpget('/api/User/GetAllUsers'); // Fetch users from API

                    if (response?.data) {
                        const users = response.data;

                        // Initialize selectedIndices based on user.Active
                        const selectedIndices = users
                            .map((user, index) => (user.Active ? index : null))
                            .filter(index => index !== null);

                        setUserList(users);
                        setSelectedIndices(selectedIndices);
                    }
                } catch (error) {
                    console.error('Error fetching users:', error.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchUsers();
        }
    }, [visible]);

    const confirmAndSubmit = async () => {
        Swal.fire({
            title: 'Confirm User Action',
           text: "Are you sure you want to proceed with the selected users? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
    
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Gather selected user IDs
                const selectedUserIds = selectedIndices.map(index => userList[index].ID);
                await handleSubmit(selectedUserIds);

                // Call fetchSeatData after submission
                if (fetchSeatData) {
                    fetchSeatData();
                }

                // Close the popup
                onClose();
            }
        });
    };

    const handleSubmit = async (selectedUserIds) => {
        try {
            // Send selected user IDs to the backend
            const response = await baseapi.httppost('/api/User/ManageUserList', {
                userIds: selectedUserIds
            });

            const { data } = response;
            if (data.status) {
                notify('Users successfully updated.', 'success', 2000);
            } else {
                notify(response.data.message, 'error', 2000);
            }
        } catch (error) {
            console.error('Error occurred while submitting:', error.message);
            notify('An error occurred. Please try again later.', 'error', 2000);
        }
    };

    const handleBack = useCallback(() => {
        if (onClose) {
            if (fetchSeatData) {
                fetchSeatData();
            }
            onClose();
        }
    }, [onClose, fetchSeatData]);

    const handleClick = (index) => {
        const user = userList[index];

        // Prevent deselection if the user is an owner
        if (user.isOwner) {
            return;
        }

        setSelectedIndices((prevSelected) => {
            // Track if we're selecting or deselecting
            const isSelectingNew = !prevSelected.includes(index);

            // Handle seat limits when selecting
            if (isSelectingNew) {
                if (remainingSeat === 0) {
                    notify('The selected user exceeds the seat limit. Please deselect a user before selecting another user.', 'error', 2000);
                    return prevSelected; // Do nothing if no seats are available
                }

                // Decrement remaining seat when selecting
                decrementRemainingSeat();
                return [...prevSelected, index]; // Add to selected indices
            } else {
                // Increment remaining seat when deselecting
                incrementRemainingSeat();
                return prevSelected.filter(i => i !== index); // Remove from selected indices
            }
        });
    };

    // Scroll to the last user in the list when the userList is updated and popup is visible
    useEffect(() => {
        if (visible && userList.length > 0) {
            const lastIndex = userList.length - 1;
            const lastUserElement = userRefs.current[lastIndex];

            // Ensure the last user element exists before scrolling
            if (lastUserElement && scrollViewRef.current) {
                scrollViewRef.current.instance.scrollToElement(lastUserElement); // Scroll smoothly to the last user
            }
        }
    }, [userList, visible]);

    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={false}
                width={popupWidth}
                height='auto'
            >
                <div className="manage-user-selection-popup-content">
                    <div className="manage-user-header">
                        <h2>Manage User</h2>
                        <p>By confirming the users holding seats in your company, any extra users beyond your subscription limit will be automatically removed.</p>
                    </div>
                    <hr className="custom-hr" />
                    {loading ? (
                        <div className="loading-indicator-container">
                            <div className="loader"></div>
                            <br />
                        </div>
                    ) : (
                        <>
                            {remainingSeatSection && remainingSeatSection}
                            <ScrollView
                                ref={scrollViewRef}
                                height={420}
                                width="100%"
                                showScrollbar="always"
                            >
                                {userList.length > 0 && (
                                    <div>
                                        {userList.map((user, index) => (
                                            <div
                                                key={index}
                                                ref={el => userRefs.current[index] = el}
                                                className={`manage-user-selection-details ${selectedIndices.includes(index) ? 'selected' : ''}`}
                                                onClick={() => handleClick(index)}
                                            >
                                                <div className="manage-user-selection-name">
                                                    {user.isOwner ? (
                                                        <div>
                                                            {user.Username}
                                                            <span className={`manage-user-selection-status active`}>
                                                                Owner
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {user.Username}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="manage-user-selection-email">
                                                    <div>Email: <span className="invited-email">{user.Email}</span></div>
                                                    <div>Role: <span className="invited-email">{user.UserRoleCode}</span></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ScrollView>
                        </>
                    )}
                    <div className="manage-user-selection-buttons">
                        <button className="manage-user-selection-cancel-btn" onClick={handleBack}>Cancel</button>
                        <button className="manage-user-selection-continue-btn" onClick={confirmAndSubmit}>Continue</button>
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default ManageUserForm;
