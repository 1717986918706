import React, { useState, useEffect, useRef } from "react";
import DataGrid, { Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Popup } from "devextreme-react/popup";
import DropDownButton from "devextreme-react/drop-down-button";
import { CheckBox } from "devextreme-react/check-box";
import { Toast } from "devextreme-react/toast";
import SelectBox from "devextreme-react/select-box";
import { NumberBox, TagBox, TextBox } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { Button } from "devextreme-react/button";
import PopupForm from "../../../components/popup-form/PopupForm";
import ValidationSummary from "devextreme-react/validation-summary";
import { Validator, RequiredRule } from "devextreme-react/validator";
import Container from "../../../components/container";
import ScrollView from "devextreme-react/scroll-view";
import utils, { loading, closeLoading } from "../../../utils/common";
import baseapi from "../../../api/baseapi";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import ErrorPopupForm from "../../../components/popup-form/ErrorPopupForm";

export default function ReportDesignerListing() {
	const path = "/api/Dashboard";
	const authPath = "/api/Login";
	const exportFromRef = useRef(null);
	const exportToRef = useRef(null);
	const [currentToken, setCurrentToken] = useState(localStorage.getItem("Authorization").substring(7));
	const [roleOptions, setRoleOptions] = useState([]);
	const [exportFrom, setExportFrom] = useState("");
	const [exportTo, setExportTo] = useState("");
	const [selectedExportReports, setSelectedExportReports] = useState([]);
	const [exportReportsList, setExportReportsList] = useState([]);
	const [exportOptions, setExportOptions] = useState([]);
	const [documentViewerParameter, setDocumentViewerParameter] = useState([]);
	const [documentViewerValue, setDocumentViewerValue] = useState([]);
	const [companyFilterOptions, setCompanyFilterOptions] = useState([]);
	const [myRole, setMyRole] = useState("");
	const [isManagement, setIsManagement] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [reportAccessToken, setReportAccessToken] = useState("");
	const [connectionOptions, setConnectionOptions] = useState([]);
	const [reportDesignerParams, setReportDesignerParams] = useState([]);
	const [ViewerAccessURL, setViewerAccessURL] = useState("");
	const [companyID, setCompanyID] = useState("5oxwnlvCJSo%3d");
	const [moduleOptions, setModuleOptions] = useState([]);
	const [collapsed, setCollapsed] = useState(false);
	const [documentViewerPopup, setDocumentViewerPopup] = useState(false);
	const [editAccessRightPopup, setEditAccessRightPopup] = useState(false);
	const [exportReportPopup, setExportReportPopup] = useState(false);
	const [renameReportPopup, setRenameReportPopup] = useState(false);
	const [addModulePoupup, setAddModulePopup] = useState(false);
	const [readSubreport, setReadSubReport] = useState(false);
	const [dashboardSettings, setDashboardSettings] = useState([
		{ id: 2, text: "Rename Report", icon: "" },
		{ id: 3, text: "Mark as SubReport", icon: "" },
		{ id: 4, text: "Add Module", icon: "" }
	]);
	const [gridDataSource, setGridDataSource] = useState("");
	const [renameReportForm, setRenameReportForm] = useState(null);
	const [addModuleForm, setAddModuleForm] = useState(null);
	const [editAccessRightForm, setEditAccessRightForm] = useState(null);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [toastConfig, setToastConfig] = useState({
		isVisible: false,
		type: "info",
		message: ""
	});
	const [reportName, setReportName] = useState("");
	const pageSizes = [10, 25, 50, 100];
	const exportReportValidator = "Export Validator";
	const [scrollViewHeight, setScrollViewHeight] = useState("94%");
	const popupMessageRef = useRef(null);

	const cellRender = (col) => {
		// console.log("col", col.data)
		var isDefaultTemplate = col.data.AccessRight.includes("Default Template");

		return (
			<span className='action-row'>
				<Button
					className='action-btn'
					width={90}
					text='Designer'
					type='default'
					icon='background'
					stylingMode='outlined'
					onClick={(e) => {
						if (companyID === "5oxwnlvCJSo%3d") {
							window.open(`${col.data.DesignerAccessURL}`, "_blank");
						} else {
							window.open(`${col.data.DesignerAccessURL + `&companyID=${companyID}`}`, "_blank");
						}
					}}
				/>

				<Button
					className='action-btn'
					width={90}
					text='Viewer'
					type='default'
					icon='file'
					stylingMode='outlined'
					onClick={(e) => {
						if (companyID === "5oxwnlvCJSo%3d") {
							showDocumentViewer(col.data.ID, col.data.ViewerAccessURL);
						} else {
							showDocumentViewer(col.data.ID, col.data.ViewerAccessURL + `&companyID=${companyID}`);
						}
					}}
				/>

				{!isDefaultTemplate && myRole === "Admin" && (
					<span>
						<Button className='action-btn' width={90} text='Access' type='default' icon='lock' stylingMode='outlined' onClick={(e) => showEditAccessRightPopup(col.data.ID, col.data.AccessRight)} />

						<Button
							className='action-btn'
							width={90}
							text='Delete'
							type='default'
							icon='trash'
							stylingMode='outlined'
							onClick={(e) => {
								deleteReport(col.data.ID);
							}}
						/>
					</span>
				)}

				{isManagement && isDefaultTemplate && (
					<Button
						className='action-btn'
						width={90}
						text='Delete'
						type='default'
						icon='trash'
						stylingMode='outlined'
						onClick={(e) => {
							deleteReport(col.data.ID);
						}}
					/>
				)}

				{isManagement && (
					<DropDownButton
						icon='preferences'
						splitButton={false}
						useSelectMode={false}
						text='Settings'
						items={isDefaultTemplate ? dashboardSettings : dashboardSettings.filter((c) => c.text != "Mark as SubReport")}
						displayExpr='text'
						keyExpr='id'
						stylingMode='outlined'
						className='setting-btn action-btn'
						dropDownOptions={{ width: 200 }}
						onItemClick={(e) => {
							dropdownOption(e, col.data.ID, col.data.FormName);
						}}
					/>
				)}
			</span>
		);
	};

	const onContentReady = (e) => {
		if (collapsed) {
			e.component.expandRow(["EnviroCare"]);
			setCollapsed(true);
		}
	};

	const showDocumentViewer = (reportID, viewerLink = "") => {
		setDocumentViewerPopup(true);
		setViewerAccessURL(viewerLink);
		//Clear the previous layout
		setReportDesignerParams([]);
		setDocumentViewerParameter([]);

		const paramValueArray = [];

		baseapi.httpget(utils.ReportURL + "/api/Report/getReportDetailsByReportName?reportID=" + reportID + "&accessToken=" + currentToken).then((result) => {
			const { data } = result;
			setReportName(data[0].ReportName);
			baseapi
				.httppost(utils.ReportURL + path + "/CheckReportParameters?token=" + currentToken + "&reportID=" + data[0].ID)
				.then((result) => {
					result = result.data;
					const paramSettingArray = [];

					for (var i = 0; i < result.length; i++) {
						result[i].randomID = crypto.randomUUID();
						paramSettingArray.push(result[i]);
						paramValueArray.push({ RandomID: result[i].randomID, ParameterID: result[i].parameterID, ParameterName: result[i].parameterName, ParameterValue: "" });
					}

					setDocumentViewerParameter(paramSettingArray);

					baseapi
						.httpget(utils.ReportURL + "/api/ReportDesignerParams/GetReportParamsDetailByReportID?id=" + data[0].ID + "&token=" + currentToken)
						.then((result) => {
							result = result.data;
							const existingParamArr = [];
							for (var i = 0; i < result.length; i++) {
								const existingParam = {
									RandomID: crypto.randomUUID(),
									ID: result[i].ID,
									ParameterName: result[i].ParameterName,
									ParameterType: result[i].ParameterType,
									ParameterDescription: result[i].ParameterDescription,
									ParameterValue: result[i].GroupByValue
								};

								existingParamArr.push(existingParam);
								paramValueArray.push(existingParam);
							}
							// console.log(existingParamArr);

							setReportDesignerParams(existingParamArr);
							setDocumentViewerValue(paramValueArray);
							// console.log(result[0]);
						})
						.catch((error) => console.log("error", error));
				})
				.catch((error) => console.log("error", error));
		});
	};

	const checkSubReport = () => {
		loading();
		if (!readSubreport) {
			const dataSourceOptions = {
				store: new CustomStore({
					key: "ID",
					loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
					load: () => {
						return baseapi
							.httpget(`${utils.ReportURL}/api/Report/GetAllSubReport?accessToken=${currentToken}`)
							.then((response) => response.data)
							.catch(() => {
								throw "Network error";
							});
					}
				})
			};
			setGridDataSource(dataSourceOptions);
			setReadSubReport(true);
			setDashboardSettings([
				{ id: 2, text: "Rename Report", icon: "" },
				{ id: 3, text: "Unmark as SubReport", icon: "" },
				{ id: 4, text: "Add Module", icon: "" }
			]);
		} else {
			const dataSourceOptions = {
				store: new CustomStore({
					key: "ID",
					loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
					load: () => {
						return baseapi
							.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`)
							.then((response) => response.data)
							.catch(() => {
								throw "Network error";
							});
					}
				})
			};
			setGridDataSource(dataSourceOptions);
			setReadSubReport(false);
			setDashboardSettings([
				{ id: 2, text: "Rename Report", icon: "" },
				{ id: 3, text: "Mark as SubReport", icon: "" },
				{ id: 4, text: "Add Module", icon: "" }
			]);
		}
		closeLoading();
	};

	const DocumentViewerValueText = (id, value) => {
		// console.log(documentViewerValue);
		const copyArr = [...documentViewerValue];
		copyArr.find((c) => c.RandomID === id).ParameterValue = value;
		setDocumentViewerValue(copyArr);
	};

	const confirmReportParameters = () => {
		let documentViewerLink = ViewerAccessURL;
		let allParamsValid = true;

		documentViewerValue.forEach(({ ParameterName, ParameterValue }) => {
			if (ParameterName.toLowerCase().includes("date") || ParameterName.toLowerCase().includes("ageing")) {
				const date = new Date(ParameterValue);
				if (isNaN(date) || date.getFullYear() < 1900) {
					allParamsValid = false;
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: `Invalid date(s) selected`,
						type: "Danger"
					});
				} else {
					documentViewerLink += `&${ParameterName}=${ParameterValue}`;
				}
			} else {
				documentViewerLink += `&${ParameterName}=${ParameterValue}`;
			}
		});

		if (allParamsValid) {
			window.open(documentViewerLink, "_blank");
		}
	};

	const dropdownOption = (e, reportID = "", moduleAssigned) => {
		// console.log("dropdown clicked", e.itemData)
		if (e.itemData.text === "Rename Report") {
			showRenameReportPopup(reportID);
			// setRenameReportPopup(true);
		} else if (e.itemData.text === "Mark as SubReport") {
			markSubReport(reportID, true);
		} else if (e.itemData.text === "Unmark as SubReport") {
			markSubReport(reportID, false);
		} else if (e.itemData.text === "Add Module") {
			showAddModulePopUp(reportID, moduleAssigned);
		}
	};

	const companyOptionsOnChange = (selectedOption) => {
		loading();
		const companiesArray = [];

		for (var i = 0; i < selectedOption.length; i++) {
			companiesArray.push(selectedOption[i]);
		}

		var data = new Object();
		data.companiesID = companiesArray;

		const params = "?token=" + currentToken;

		const dataSourceOptions = {
			store: new CustomStore({
				key: "ID",
				loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
				load: () => {
					return baseapi
						.httppost(utils.ReportURL + "/api/Report" + "/getReportsByCompanyIDs" + params, companiesArray)
						.then((response) => response.data)
						.catch(() => {
							throw "Network error";
						});
				}
			})
		};
		setGridDataSource(dataSourceOptions);
		setCompanyFilterOptions(selectedOption);
		closeLoading();
	};

	const checkAvailableCompanies = () => {
		const params = "?token=" + currentToken;

		baseapi
			.httppost(utils.ReportURL + path + "/getAvailableCompanies" + params)
			.then((companiesReceived) => {
				companiesReceived = companiesReceived.data;
				const companies = [];

				for (const [key, value] of Object.entries(companiesReceived)) {
					companies.push({ value: key, label: value });
				}

				setCompanyOptions(companies);
			})
			.catch((error) => console.log("error", error));
	};

	const authentication = () => {
		// console.log("Current Token", currentToken);

		baseapi
			.httppost(utils.ReportURL + authPath + "/LoginToken?token=" + currentToken)
			.then((result) => {
				result = result.data;
				// console.log(result);

				if (result === "Invalid") {
					setToastConfig({
						type: "error",
						isVisible: true,
						message: "Your token is invalid or expired, Please Refresh or Login again!"
					});
					setIsAuthenticated(true);
				} else {
					setMyRole(result.userRole);
					setIsManagement(result.Management);
					setIsAuthenticated(true);
					if (result) {
						result.Management && checkAvailableCompanies();
						getAvailableConnection();
					}
				}
			})
			.catch((error) => console.log("error", error));

		getCurrentReportAccessToken();
	};

	const getAvailableConnection = () => {
		var data = new Object();
		data.token = currentToken;

		baseapi
			.httppost(utils.ReportURL + path + "/getAvailableConnection")
			.then((result) => {
				result = result.data;
				const connectionArr = [];

				for (const [key, value] of Object.entries(result)) {
					connectionArr.push({ label: value, value: key });
				}

				setConnectionOptions(connectionArr);
			})
			.catch((error) => console.log("error", error));
	};

	const getCurrentReportAccessToken = () => {
		const params = "?token=" + currentToken;
		// console.log(params)

		baseapi
			.httppost(utils.ReportURL + path + "/getCurrentReportAccessToken" + params)
			.then((result) => {
				result = result.data;
				// console.log(result);
				setReportAccessToken(result);
			})
			.catch((error) => console.log("error", error));
	};

	const deleteReport = (reportID) => {
		if (window.confirm("Are you sure you want to delete this report? This action is irreversible!") === true) {
			loading();
			const params = "?token=" + currentToken + "&reportID=" + reportID;

			baseapi.httppost(utils.ReportURL + path + "/DeleteReport" + params).then((deletedResult) => {
				deletedResult = deletedResult.data;

				closeLoading();
				if (deletedResult === "Valid") {
					if (isManagement) {
						reloadDataGrid(readSubreport);
					} else {
						setIsAuthenticated(false);
						reloadDataGrid(readSubreport);
					}

					setToastConfig({
						type: "success",
						isVisible: true,
						message: "The report is successfully deleted!"
					});
				} else {
					setToastConfig({
						type: "error",
						isVisible: true,
						message: "Error occurs, the report cannot be deleted!"
					});
				}
			});
		}
	};

	const showExportReportPopup = (e) => {
		setExportReportPopup(true);
		setExportFrom("");
		setExportTo("");
		setSelectedExportReports([]);
		exportFromRef.current.instance.reset(); //Clear previous value;
		exportToRef.current.instance.reset(); //Clear previous value;

		baseapi.httpget(utils.ReportURL + "/api/Company/GetCompanyIDsWithoutManagement?token=" + currentToken).then((companiesReceived) => {
			companiesReceived = companiesReceived.data;
			setExportOptions(
				companiesReceived.map((companyDetails) => ({
					label: `ID : ${companyDetails.ID} - Company : ${companyDetails.Name}`,
					value: companyDetails.ID
				}))
			);
		});
	};

	const handleExportFromUserChange = (selectedOption) => {
		// console.log("selected", selectedOption)
		if (selectedOption !== null) {
			setExportFrom(selectedOption.value);

			baseapi
				.httpget(utils.ReportURL + "/api/Report/GetReportByCompanyID?companyID=" + selectedOption.value + "&accessToken=" + currentToken)
				.then((result) => {
					result = result.data;
					setExportReportsList(
						result.map((reportDetails) => ({
							label: `${reportDetails.ReportName}`,
							value: reportDetails.ID
						}))
					);
				})
				.catch((error) => console.log("error", error));
		}
	};

	const handleExportToUserChange = (selectedOption) => {
		// console.log("selected", selectedOption)
		if (selectedOption !== null) setExportTo(selectedOption.value);
	};

	const handleReportChange = (selectedOption) => {
		// console.log(selectedOption)
		const reports = [];

		for (var i = 0; i < selectedOption.length; i++) {
			reports.push(selectedOption[i]);
		}
		setSelectedExportReports(reports);
	};

	const handleCopyReports = (e) => {
		const res = e.validationGroup.validate();

		if (res.status === "valid" && res.isValid) {
			if (exportFrom !== exportTo) {
				var data = new Object();
				data.ExportFrom = exportFrom;
				data.ExportTo = exportTo;
				data.ExportID = selectedExportReports;
				data.Token = currentToken;
				loading();

				baseapi
					.httppost(utils.ReportURL + path + "/ExportReports", data)
					.then((result) => {
						// console.log("Export result", result)
						result = result.data;
						closeLoading();

						if (result === "Successful") {
							setToastConfig({
								type: "success",
								isVisible: true,
								message: "Successfully Exported!"
							});

							setExportReportPopup(false);
						} else {
							setToastConfig({
								type: "error",
								isVisible: true,
								message: "Something went wrong!"
							});
						}
					})
					.catch((error) => console.log("error", error));
			} else {
				setToastConfig({
					type: "error",
					isVisible: true,
					message: "Both Users Cannot Be The Same!"
				});
			}
		}
	};

	const showRenameReportPopup = (reportID) => {
		setRenameReportPopup(true);

		baseapi.httpget(utils.ReportURL + "/api/Report/GetReportByID?id=" + reportID).then((result) => {
			var report = result.data;
			setRenameReportForm({ oldName: report.ReportName, newName: "", renameID: reportID });
		});
	};

	const renameReport = (e) => {
		// console.log("rename report", e)
		const newName = e.newName;
		const renameID = e.renameID;

		const params = `?newReportURL=${newName}&reportID=${renameID}&accessToken=${currentToken}`;

		//Display Load Panel
		loading();

		baseapi
			.httppost(utils.ReportURL + path + "/RenameReport" + params)
			.then((result) => {
				result = result.data;

				closeLoading();
				if (result === "Success") {
					reloadDataGrid(readSubreport);
					//Display alert
					setToastConfig({
						type: "success",
						isVisible: true,
						message: "Succesfully renamed report!"
					});
				} else if (result === "Duplicate Name") {
					//Display alert
					setToastConfig({
						type: "error",
						isVisible: true,
						message: "Duplicate report name found! please choose a new one."
					});
				} else {
					//Display alert
					setToastConfig({
						type: "error",
						isVisible: true,
						message: "Something went wrong"
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	const markSubReport = (reportID, marked) => {
		const params = "?accessToken=" + currentToken + "&id=" + reportID + "&marked=" + marked;
		loading();

		baseapi
			.httppost(utils.ReportURL + "/api/Report/MarkSubReport" + params)
			.then((result) => {
				closeLoading();

				if (marked) {
					const dataSourceOptions = {
						store: new CustomStore({
							key: "ID",
							loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
							load: () => {
								return baseapi
									.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`)
									.then((response) => response.data)
									.catch(() => {
										throw "Network error";
									});
							}
						})
					};
					setGridDataSource(dataSourceOptions);
					setToastConfig({
						isVisible: true,
						type: "success",
						message: "Marked as Subreport successfully"
					});
				} else {
					const dataSourceOptions = {
						store: new CustomStore({
							key: "ID",
							loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
							load: () => {
								return baseapi
									.httpget(`${utils.ReportURL}/api/Report/GetAllSubReport?accessToken=${currentToken}`)
									.then((response) => response.data)
									.catch(() => {
										throw "Network error";
									});
							}
						})
					};
					setGridDataSource(dataSourceOptions);
					setToastConfig({
						isVisible: true,
						type: "success",
						message: "Unmarked as Subreport successfully"
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	const showEditAccessRightPopup = (editID, accessRightAssigned) => {
		setEditAccessRightForm({
			currentAccessRight: accessRightAssigned,
			newAccessRight: "",
			editID: editID
		});

		const params = "?token=" + currentToken;

		baseapi.httppost(utils.ReportURL + path + "/getUserRoles" + params).then((userRoleReceived) => {
			userRoleReceived = userRoleReceived.data;
			const roles = [];

			for (const [key, value] of Object.entries(userRoleReceived)) {
				roles.push({ value: key, label: value });
			}

			setRoleOptions(roles);
			setEditAccessRightPopup(true);
		});
	};

	const handleEditAccessRight = (e) => {
		// console.log(e)
		const editID = e.editID;
		const selectedRoles = e.newAccessRight;
		loading();
		const params = "?token=" + currentToken + "&reportID=" + editID;
		baseapi.httppost(utils.ReportURL + path + "/EditReportAccessRight" + params, selectedRoles).then((result) => {
			result = result.data;
			closeLoading();

			if (result === "Valid") {
				if (isManagement) {
					companyOptionsOnChange(companyFilterOptions);
				} else {
					reloadDataGrid();
				}
				setToastConfig({
					type: "success",
					isVisible: true,
					message: "Access right is successfully edited!"
				});
			} else {
				setToastConfig({
					type: "error",
					isVisible: true,
					message: "something went wrong!"
				});
			}
		});
	};

	const reloadDataGrid = (isSubReport = false) => {
		loading();
		if (isSubReport) {
			const dataSourceOptions = {
				store: new CustomStore({
					key: "ID",
					loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
					load: () => {
						return baseapi
							.httpget(`${utils.ReportURL}/api/Report/GetAllSubReport?accessToken=${currentToken}`)
							.then((response) => response.data)
							.catch(() => {
								throw "Network error";
							});
					}
				})
			};
			setGridDataSource(dataSourceOptions);
		} else if (isManagement) {
			companyOptionsOnChange(companyFilterOptions);
		} else {
			setGridDataSource({
				store: new CustomStore({
					key: "ID",
					loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
					load: () => {
						return baseapi
							.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`)
							.then((response) => response.data)
							.catch(() => {
								throw "Network error";
							});
					}
				})
			});
		}
		closeLoading();
	};

	const showAddModulePopUp = (reportID, moduleAssigned = "") => {
		const params = "?token=" + currentToken;
		setAddModulePopup(true);
		setAddModuleForm({
			currentModuleAssigned: moduleAssigned,
			newModuleAssigned: "",
			reportID: reportID
		});

		baseapi.httppost(utils.ReportURL + path + `/getModuleOptions${params}`).then((result) => {
			console.log(result);
			result = result.data;
			setModuleOptions(
				result.map((moduleDetails) => ({
					label: `${moduleDetails.Code}`,
					value: moduleDetails.ID
				}))
			);
		});
	};

	const handleSubmitAddModule = (e) => {
		// console.log(e)
		const reportID = e.reportID;
		const selectedModule = e.newModuleAssigned;
		loading();
		const params = `?token=${currentToken}&reportID=${reportID}&moduleID=${selectedModule}`;
		baseapi.httppost(utils.ReportURL + path + "/confirmAddModule" + params).then((result) => {
			result = result.data;

			if (result === "Successful") {
				setToastConfig({
					type: "success",
					isVisible: true,
					message: "Module is successfully assigned to the report!"
				});
				reloadDataGrid(readSubreport);
			} else {
				setToastConfig({
					type: "error",
					isVisible: true,
					message: result
				});
			}

			closeLoading();
		});
	};

	const renderTitle = () => {
		return (
			<div className={"titleContainer"}>
				<h3 className={"title"}>{reportName}</h3>
				<Button icon='close' stylingMode='text' onClick={() => setDocumentViewerPopup(false)} className={"closeButton"} />
			</div>
		);
	};

	useEffect(() => {
		loading();
		if (!isAuthenticated) {
			authentication();

			if (gridDataSource === "") {
				setGridDataSource({
					store: new CustomStore({
						key: "ID",
						loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
						load: () => {
							// console.log(currentToken);
							return baseapi
								.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`)
								.then((response) => response.data)
								.catch(() => {
									throw "Network error";
								});
						}
					})
				});
			}
		}
		closeLoading();
	}, []);

	return (
		<div>
			<div className={"content-block"}>
				<div>
					<div className={"dx-card responsive-paddings dashboard-content-section"}>
						<div className='listing-page-title-container'>
							<span className='datagrid-customized-title'>
								<PathIndicator />
							</span>
						</div>

						{isManagement && (
							<div className='row dashboard-filter-row'>
								<div className='dashboard-filter-col'>
									<label className='mr-2 pt-2 ml-2'>Company: </label>
									<TagBox items={companyOptions} displayExpr={"label"} valueExpr={"value"} searchEnabled={true} width={200} hint={"Company"} onValueChanged={(e) => companyOptionsOnChange(e.value)} />
								</div>

								<div className='dashboard-filter-col'>
									<label className='mr-2 pt-2 ml-2'>Connection: </label>
									<SelectBox
										displayExpr={"label"}
										showClearButton={true}
										valueExpr={"value"}
										items={connectionOptions}
										searchEnabled={true}
										onSelectionChanged={(e) => {
											// console.log(e.selectedItem);
											if (e.selectedItem !== null) {
												setCompanyID(e.selectedItem.value);
											} else {
												setCompanyID("5oxwnlvCJSo%3d");
											}
										}}
									/>
								</div>

								<div className='dashboard-filter-col'>
									<label className='pt-2 ml-2'>SubReport:</label>
									<CheckBox defaultValue={false} elementAttr={{ class: "col pl-2" }} value={readSubreport} onValueChanged={checkSubReport} />
								</div>

								<div className='action-btn-container'>
									<div className='action-btn-inner-container'>
										<Button
											className='action-btn'
											text='Create Report'
											type='default'
											stylingMode='outlined'
											onClick={() => {
												window.open(`/ReportDesigner?token=${reportAccessToken}&report=blank`, "_blank");
											}}
										/>

										<Button className='action-btn' text='Export Report(s)' type='default' stylingMode='outlined' onClick={showExportReportPopup} />

										<Button
											className='action-btn'
											text='Restore Report(s)'
											type='default'
											stylingMode='outlined'
											onClick={() => {
												window.open(`/RestoreReport`, "_blank");
											}}
										/>
									</div>
								</div>
							</div>
						)}

						<DataGrid id='gridContainer' dataSource={gridDataSource} loadPanel={{ enabled: false }} allowColumnReordering={true} rowAlternationEnabled={true} showBorders={true} onContentReady={onContentReady} columnAutoWidth={true}>
							<GroupPanel visible={true} />
							<SearchPanel visible={true} highlightCaseSensitive={true} width='750px' />
							<Grouping autoExpandAll={false} />

							<Column dataField='ReportName' caption='Report Name' alignment='left' width={"auto"} />
							<Column dataField='AccessRight' caption='Accessible By' alignment='left' />
							<Column dataField='FormName' caption='Module' dataType='string' alignment='left' allowGrouping={false} cssclassName='bullet' />
							<Column dataField='CompanyName' caption='Belongs To' dataType='string' alignment='left' allowGrouping={false} cssclassName='bullet' />
							<Column dataField='CreatedDate' caption='Created Date' dataType='datetime' alignment='right' format='dd/MM/yyyy' />
							<Column dataField='ID' type='buttons' caption='Action' cellRender={cellRender} width={"auto"}></Column>

							<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />
							<Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} visible={true} />
							<Paging defaultPageSize={10} />
						</DataGrid>
					</div>
				</div>

				{/* Document Viewer Popup */}
				<Popup
					visible={documentViewerPopup}
					onHiding={(e) => setDocumentViewerPopup(false)}
					dragEnabled={false}
					hideOnOutsideClick={false}
					showCloseButton={true}
					showTitle={true}
					titleRender={renderTitle}
					// title={"Document Viewer"}
					container='.dx-viewport'
					height={"auto"}
					width={800}>
					<ScrollView width='100%' height={scrollViewHeight} onUpdated={(e) => setScrollViewHeight(utils.GetScrollViewHeight())}>
						<div className='popup-scroll'>
							{documentViewerParameter.length !== 0 && <div className='text-left mb-3 document-viewer-title'>SQL Parameters</div>}

							{documentViewerParameter.length === 0 && reportDesignerParams.length === 0 ? (
								<div className='mb-3 ml-2 text-danger error-text'>No Parameter is added to the report yet</div>
							) : (
								reportDesignerParams.map((designerParam, index) => {
									return (
										<div className='form-group row' key={designerParam.randomID}>
											<label className='col-sm-2 col-form-label'>{designerParam.ParameterDescription}: </label>
											<div className='col-sm-10'>
												{(designerParam.ParameterType === "Text" || designerParam.ParameterType === "String") && (
													<span>
														<TextBox
															elementAttr={{ name: "GroupByValue" }}
															onValueChanged={(e) => {
																DocumentViewerValueText(designerParam.RandomID, e.value);
															}}
														/>
													</span>
												)}

												{(designerParam.ParameterType === "Int32" || designerParam.ParameterType === "Decimal") && (
													<span>
														<NumberBox
															elementAttr={{ name: "GroupByValue" }}
															onValueChanged={(e) => {
																DocumentViewerValueText(designerParam.RandomID, e.value);
															}}
														/>
													</span>
												)}

												{/* {(designerParam.ParameterType === "Text" || designerParam.ParameterType === "String") && (
													<span>
														<TextBox
															elementAttr={{ name: "GroupByValue" }}
															onValueChanged={(e) => {
																DocumentViewerValueText(designerParam.RandomID, e.value);
															}}
														/>
													</span>
												)} */}

												{designerParam.ParameterType === "DateTime" && (
													<span>
														<DateBox
															min={"1/1/1900"}
															// defaultValue={new Date()}
															elementAttr={{ name: "GroupByValue" }}
															onValueChanged={(e) => {
																DocumentViewerValueText(designerParam.RandomID, utils.dateboxFormat(e.value));
															}}
															displayFormat='dd/MM/yyyy'
															dateSerializationFormat='yyyy-MM-dd'
															useMaskBehavior={true}
														/>
													</span>
												)}
											</div>
										</div>
									);
								})
							)}

							{/* {reportDesignerParams.length !== 0 && <div className='text-left mb-3 document-viewer-title'>Report Designer Parameters</div>} */}
						</div>
					</ScrollView>

					<div className='modal-footer'>
						{/* {JSON.stringify(documentViewerValue)} */}
						{/* {JSON.stringify(reportDesignerParams)} */}
						<input type='hidden' id='document-viewer-report-id' />
						<input type='hidden' id='document-viewer-link' />
						{/* <Button width={"auto"} type='default' text='View Without Parameters' stylingMode='contained' className='document-viewer-btn' onClick={DocumentViewerWithoutParameter} /> */}
						<Button width={"auto"} type='danger' text='Cancel' stylingMode='contained' className='document-viewer-btn' onClick={(e) => setDocumentViewerPopup(false)} />
						<Button width={"auto"} type='success' text='Apply' stylingMode='contained' onClick={confirmReportParameters} />
					</div>
				</Popup>

				{/* Edit Access Right Popup */}
				<PopupForm
					visible={editAccessRightPopup}
					form={editAccessRightForm}
					colums={[
						{ dataField: "currentAccessRight", caption: "Current Access Rights", disabled: true, inputType: "TextBox" },
						{
							dataField: "newAccessRight",
							caption: "Assign Access Rights",
							disabled: false,
							inputType: "TagBox",
							dataSource: roleOptions,
							displayExpr: "label",
							valueExpr: "value",
							validation: [{ validationMessage: "Access right is required!", validationType: "required" }]
						}
					]}
					width={800}
					onHiding={setEditAccessRightPopup}
					closeButtonClick={setEditAccessRightPopup}
					onSubmit={handleEditAccessRight}
					title={"Edit Access Right"}
				/>

				{/* Export Reports Popup */}
				<Popup
					visible={exportReportPopup}
					onHiding={(e) => setExportReportPopup(false)}
					dragEnabled={false}
					hideOnOutsideClick={false}
					showCloseButton={true}
					showTitle={true}
					title='Export Report(s)'
					container='.dx-viewport'
					height={"auto"}
					width={800}>
					<div className='form-group row'>
						<label htmlFor='accessible-userFrom' className='col-sm-3 col-form-label'>
							{" "}
							Export From :{" "}
						</label>

						<div className='col-sm-9'>
							<SelectBox
								ref={exportFromRef}
								displayExpr={"label"}
								valueExpr={"value"}
								items={exportOptions}
								searchEnabled={true}
								onSelectionChanged={(e) => {
									handleExportFromUserChange(e.selectedItem);
								}}>
								<Validator validationGroup={exportReportValidator}>
									<RequiredRule message={`Export From is required`} />
								</Validator>
							</SelectBox>
						</div>
					</div>

					<div className='form-group row'>
						<label htmlFor='accessible-userTo' className='col-sm-3 col-form-label'>
							{" "}
							Export To :{" "}
						</label>

						<div className='col-sm-9'>
							<SelectBox
								ref={exportToRef}
								displayExpr={"label"}
								valueExpr={"value"}
								items={exportOptions.filter((fromUser) => fromUser.value !== exportFrom)}
								searchEnabled={true}
								onSelectionChanged={(e) => {
									handleExportToUserChange(e.selectedItem);
								}}>
								<Validator validationGroup={exportReportValidator}>
									<RequiredRule message={`Export To is required`} />
								</Validator>
							</SelectBox>
						</div>
					</div>

					{exportFrom !== "" && (
						<div className='form-group row'>
							<label htmlFor='accessible-reports' className='col-sm-3 col-form-label'>
								{" "}
								Reports :{" "}
							</label>
							<div className='col-sm-9'>
								<TagBox items={exportReportsList} displayExpr={"label"} valueExpr={"value"} searchEnabled={true} hint={"Select Reports"} onValueChanged={(e) => handleReportChange(e.value)}>
									<Validator validationGroup={exportReportValidator}>
										<RequiredRule message={`Report is required`} />
									</Validator>
								</TagBox>
							</div>
						</div>
					)}

					<div>
						<ValidationSummary className='validator-summary' validationGroup={exportReportValidator} />
					</div>

					<div className='modal-footer'>
						<Button width={"auto"} className='export-btn' text='Close' type='danger' stylingMode='contained' onClick={(e) => setExportReportPopup(false)} />
						<Button width={"auto"} className='export-btn' text='Save changes' type='success' stylingMode='contained' useSubmitBehavior={false} validationGroup={exportReportValidator} onClick={handleCopyReports} />
					</div>
				</Popup>

				{/* Rename Report Popup */}
				<PopupForm
					visible={renameReportPopup}
					form={renameReportForm}
					colums={[
						{ dataField: "oldName", caption: "Old Name", disabled: true, inputType: "TextBox" },
						{ dataField: "newName", caption: "New Name", disabled: false, inputType: "TextBox", validation: [{ validationMessage: "Module is required!", validationType: "required" }] }
					]}
					width={800}
					onHiding={setRenameReportPopup}
					closeButtonClick={setRenameReportPopup}
					onSubmit={renameReport}
					title={"Rename Report"}
				/>

				{/* Add Module Popup */}
				<PopupForm
					visible={addModulePoupup}
					form={addModuleForm}
					colums={[
						{ dataField: "currentModuleAssigned", caption: "Current Module Assigned", disabled: true, inputType: "TextBox" },
						{
							dataField: "newModuleAssigned",
							caption: "Assign Module",
							disabled: false,
							inputType: "SelectBox",
							dataSource: moduleOptions,
							displayExpr: "label",
							valueExpr: "value",
							validation: [{ validationMessage: "Module is required!", validationType: "required" }]
						}
					]}
					width={500}
					onHiding={setAddModulePopup}
					closeButtonClick={setAddModulePopup}
					onSubmit={handleSubmitAddModule}
					title={"Add Module"}
					colSpan={1}
				/>
			</div>

			<Toast
				visible={toastConfig.isVisible}
				message={toastConfig.message}
				type={toastConfig.type}
				onHiding={(e) => {
					setToastConfig({
						...toastConfig,
						isVisible: false
					});
				}}
				displayTime={3000}
			/>

			<ErrorPopupForm ref={popupMessageRef} />
		</div>
	);
}
