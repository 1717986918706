import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import ResetPasswordOTP from './ResetPasswordOTP';
import { useResponsivePopupWidth } from '../../utils/windowUtils';
const ResetPasswordSuccess = ({ onClose, visible }) => {

    const [showResetPasswordOTP, setShowResetPasswordOTP] = useState(false);
    const popupWidth = useResponsivePopupWidth();

    const handleGotIt = () => {
        setShowResetPasswordOTP(true);
    };

    const handleClose = useCallback(() => {
        setShowResetPasswordOTP(false);
        onClose();
    }, [onClose]);

    return (
        <>
            <Popup
                width={popupWidth.current}
                height={'auto'}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={false}
                visible={visible}
            >

                <div className="auth-popup-content">
                <div className="icon-flex">
                    <div className="icon-container">
                        <i className="fas fa-thumbs-up"></i>
                    </div>
                    </div>
                    <p className="auth-popup-title">Reset Password Successful!</p>
                    <p className="auth-popup-subtitle">You can now login with your new password.</p>

                    <div className="auth-buttons">
                        <button className="auth-button" onClick={onClose}>login</button>
                    </div>


                </div>
            </Popup>



        </>
    );
};

export default ResetPasswordSuccess;