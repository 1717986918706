import { forwardRef, useImperativeHandle, useRef } from "react";
import { ValidationGroup } from "devextreme-react";

export default forwardRef(function CustomizedValidationGroup(props, ref){
    const validatorRef = useRef(null);

    useImperativeHandle(ref, () => ({
        // Runs validation for validators contained in the validation group.
        // Additionally runs validation for any lookup fields passed in via ref.
        validateForm(lookupRef = null){
            return new Promise((resolve, reject) => {
                const res = validatorRef.current.instance.validate(); 
                var validForm = true;
    
                // Focuses on the first field that failed validation.
                if(!res.isValid || res.status === "invalid"){
                    validForm = false;
                    res.brokenRules[0].validator.focus();
                }
    
                if(res.isValid && res.status === "pending"){
                    res.status === "pending" && res.complete.then((r) => {
                        // Focuses on the first field that failed validation.
                        if(r.status !== "valid"){
                            validForm = false;
                            r.brokenRules[0].validator.focus();
                        }

                        if(lookupRef !== null){
                            if(Array.isArray(lookupRef)){
                                for(var i = 0; i < lookupRef.length; i++){
                                    if (lookupRef[i]?.current?.instance) {
                                        const result = lookupRef[i].current.instance.validate();

                                        if(result.status !== "valid" || !result.isValid){
                                            validForm = false;
                                            result.brokenRules[0].validator.focus();
                                        }
                                    }
                                }
                            }
                            else{
                                const result = lookupRef.current.instance.validate();
                                
                                if(result.status !== "valid" || !result.isValid){
                                    validForm = false;
                                    result.brokenRules[0].validator.focus();
                                }
                            }
                        }

                        resolve(validForm);
                    })
                }
                else {
                    if(lookupRef !== null){
                        if(Array.isArray(lookupRef)){
                            for(var i = 0; i < lookupRef.length; i++){
                              
                                if (lookupRef[i]?.current?.instance) {
                                    const result = lookupRef[i].current.instance.validate();
                                
                                    if(result.status !== "valid" || !result.isValid){
                                        validForm = false;
                                        result.brokenRules[0].validator.focus();
                                    }
                                }
                            }
                        }
                        else{
                            const result = lookupRef.current.instance.validate();
        
                            if(result.status !== "valid" || !result.isValid){
                                validForm = false;
                                result.brokenRules[0].validator.focus();
                            }
                        }
                        // console.log();
                    }
        
                    resolve(validForm);
                }
            });
        }
    }));

    return(
        <ValidationGroup ref={validatorRef}>
            {props.children}
        </ValidationGroup>
    )
});