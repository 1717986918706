import React from 'react';
import { Popup } from 'devextreme-react/popup'; // Adjust the import based on your setup

const PrivacyPolicy = ({ visible, onClose, popupWidth }) => {
  return (
    <Popup
    maxWidth={'100%'}  // Ensures the popup can shrink to full width on smaller screens
    width={'55%'}   
      height={'52%'}
      visible={visible}
      onHiding={onClose}
      hideOnOutsideClick={true}
      showCloseButton={true}
      showTitle={true}
      title='Privacy Policy'
    >
      <div style={{ padding: '10px', overflowY: 'auto' }}>
        <p>
          At Greenplus Accounting, we are committed to protecting your privacy. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you use our services.
        </p>
        <h3 style={{ paddingTop: '10px' }}>1. Information We Collect</h3>
        <p>
          We may collect personal identification information from Users in a variety of ways, including, but not limited to,
          when Users visit our site, register on the site, and in connection with other activities, services, features, or
          resources we make available on our site.
        </p>
        <h3 style={{ paddingTop: '10px' }}>2. How We Use Collected Information</h3>
        <p>
          Greenplus Accounting may collect and use Users' personal information for the following purposes:
        </p>
        <ul>
          {/* You can add list items here as needed */}
        </ul>
      </div>
    </Popup>
  );
};

export default PrivacyPolicy;
