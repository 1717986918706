import React, { useState, useEffect } from "react";
import { Column, TotalItem, Summary } from "devextreme-react/data-grid";
import Listing from "../../components/listing/report-component/reportsListing";
import utils from "../../utils/common";
import { customerBalance, supplierBalance } from "../../utils/lookupstore";

export default function BalanceListing() {
	const [listingTitle, setListingTitle] = useState("");
	const [isAR, setIsAR] = useState(false);
	const [reportName, setReportName] = useState("");
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});

	const columns = [
		{ dataField: "DocNo", caption: "Doc No" },
		{ dataField: "Date", caption: "Date", dataType: "date", format: "dd/MM/yyyy" },
		{ dataField: "Description", caption: "Description" },
		{ dataField: "MainLocalAmount", caption: "Local Amount", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "GainLossAmt", caption: "GainLoss Amount", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "LocalOffsetAmount", caption: "Local Offset Amount", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "LocalUnappliedAmount", caption: "Local Unapplied", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "OutstandingAmount", caption: "Outstanding", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "TransactionTypeCode", caption: "Type", width: "50" }
	];

	const summaryItems = [
		{
			key: "detail",
			column: "Debit",
			summaryType: "sum",
			showInColumn: "DocNo",
			customizeText: (e) => {
				return "Total";
			}
		},
		// {
		// 	key: "detailMainLocalAmount",
		// 	column: "MainLocalAmount",
		// 	summaryType: "sum",
		// 	customizeText: (e) => {
		// 		return utils.numberToCurrency(e.value);
		// 	}
		// },
		// {
		// 	key: "detailOffsetAmount",
		// 	column: "OffsetAmount",
		// 	summaryType: "sum",
		// 	customizeText: (e) => {
		// 		return utils.numberToCurrency(e.value);
		// 	}
		// },
		// {
		// 	key: "detailUnappliedAmount",
		// 	column: "UnappliedAmount",
		// 	summaryType: "sum",
		// 	customizeText: (e) => {
		// 		return utils.numberToCurrency(e.value);
		// 	}
		// },
		{
			key: "detailOutstandingAmount",
			column: "OutstandingAmount",
			summaryType: "sum",
			customizeText: (e) => {
				return utils.numberToCurrency(e.value);
			}
		}
	];

	useEffect(() => {
		const pathname = window.location.pathname;

		const lastIndex = pathname.lastIndexOf("/");
		const lastSegment = lastIndex !== -1 ? pathname.substring(lastIndex + 1) : pathname;
		// console.log(lastSegment);
		if (lastSegment === "ARBalanceListing") {
			setIsAR(true);
			setListingTitle("Customer Balance Report");
		} else if (lastSegment === "APBalanceListing") {
			setIsAR(false);
			setListingTitle("Supplier Balance Report");
		}
	}, []);

	useEffect(() => {
		Promise.allSettled([supplierBalance.store.load(), customerBalance.store.load()]).then((lists) => {
			setDropDownList({
				reportList: !isAR ? lists[0].value.data : lists[1].value.data
			});
		});
		if (isAR) {
			setReportName("Customer Balance Summary");
		} else {
			setReportName("Supplier Balance Summary");
		}
	}, [isAR]);

	// useEffect(() => {
	// 	console.log(dropDownList.reportList);
	// 	console.log(reportName);
	// }, [dropDownList]);

	return (
		<div>
			<Listing
				disableDetails={true}
				className='ar-listing-datagrid'
				isBalance={true}
				isAR={isAR}
				keyDetail={"Code"}
				reportName={reportName}
				listingTitle={listingTitle}
				reportList={dropDownList.reportList}
				detailColumns={columns}
				summaryDetail={summaryItems}>
				{/* <Paging defaultPageSize={15} /> */}
				<Column dataField='canExpand' visible={false}/>
				<Column dataField='Code' />
				<Column dataField='Type' caption={isAR ? "Debtor" : "Creditor"} />
				<Column dataField='Area' />
				<Column dataField='Salesman' />
				<Column dataField='Forex' />
				<Column dataField='LocalOpenCredit' caption={"Balance B/F"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='LocalBalance' caption={"Current Balance"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='BalanceCF' caption={"Balance C/F"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Summary>
					<TotalItem
						key='detailCode'
						column='Code'
						summaryType='count'
						customizeText={(e) => {
							return "Count: " + e.value;
						}}
					/>
					<TotalItem
						column='LocalOpenCredit'
						summaryType='sum'
						customizeText={(e) => {
							return "Total: " + utils.numberToCurrency(e.value);
						}}
					/>
					<TotalItem
						column='LocalBalance'
						summaryType='sum'
						customizeText={(e) => {
							return "Total: " + utils.numberToCurrency(e.value);
						}}
					/>
					<TotalItem
						column='BalanceCF'
						summaryType='sum'
						customizeText={(e) => {
							return "Total: " + utils.numberToCurrency(e.value);
						}}
					/>
				</Summary>
			</Listing>
		</div>
	);
}
