import React, { useState } from "react";
import { Column } from "devextreme-react/data-grid";
import APRefundForm from "./form";
import Container from "../../../components/container";
import Listing from "../../../components/listing/newListing";

export default function APRefundListing() {
	const apiURL = `/api/APRefund`;

	// const [lockForm, setLockForm] = useState(false);
	const [currentEditID, setCurrentEditID] = useState(null);

	function handleEdit(e) {
		const id = e.row.key;
		const data = e.row.data;
		const TotalAmount = data.MainTotalAmount;
		const OutstandingAmount = data.OutstandingAmount;

		// if (TotalAmount !== OutstandingAmount) {
		// 	setLockForm(true);
		// } else {
		// 	setLockForm(false);
		// }

		setCurrentEditID(id);
	}

	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					className='ap-listing-datagrid'
					apiURL={apiURL}
					listingURL='/api/APRefund/list'
					sortColumn={"DocumentDate"}
                	sortOrder={"desc"}
					listingTitle={"AP Refund"}
					storageName={"APRefundListingColumnWidth"}
					onAddClick={setCurrentEditID}
					handleEdit={handleEdit}>
					<Column dataField='DocumentDate' format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField='DocumentNo' />
					<Column dataField='SupplierCode' />
					<Column dataField='SupplierName' />
					<Column dataField='ForexCode' caption={"Forex"} />
					<Column dataField='MainForexAmount' caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='MainLocalAmount' caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='OutstandingAmount' caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2 }} />
				</Listing>
			</div>

			<APRefundForm 
				formID={currentEditID} 
				closePopup={setCurrentEditID} 
				title={"A/P Refund"}
				apiURL={apiURL}
			/>
		</Container>
	);
}
